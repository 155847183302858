import { IChanges, IRow } from '@models/Forms/IForms';
import React from 'react';
import { IField, IFieldElem, IFormData } from '@models/IFormData';
import Moment from 'moment';
import { FormulaManager } from './FormulaManager';
import { FieldValues } from 'react-hook-form';
import { ISelectedFiles } from '@/components/atoms/AttachFilesBlock/AttachFilesBlock';
import { IRequiredValidation } from '@/models/actions/IRequiredValidation';

export const generateMask = (mask: string) => {
    let defaultFormatChars: Record<string, RegExp> = {
        '9': /[0-9]/,
        '0': /^$|[0-9]/,
        a: /[A-Za-z]/,
        '*': /[A-Za-z0-9]/,
    };
    let permanents: any[] = [];
    let isPermanent = false;
    let parsedMaskString = '';
    mask.split('').forEach((character) => {
        if (!isPermanent && character === '\\') {
            isPermanent = true;
        } else {
            if (isPermanent || !defaultFormatChars[character]) {
                permanents.push(parsedMaskString.length);
            }
            parsedMaskString += character;
            isPermanent = false;
        }
    });

    let maskPlaceholder = parsedMaskString.split('').map((character, index) => {
        if (permanents.indexOf(index) === -1) {
            if (character !== '0') return '_';
        }
        return character;
    });

    let maskResult = parsedMaskString.split('').map((character, index) => {
        if (permanents.indexOf(index) === -1) {
            return defaultFormatChars[character];
        }
        return character;
    });

    return maskResult;
};

export const generatePlaceholder = (mask: string) => {
    let defaultFormatChars: Record<string, RegExp> = {
        '9': /[0-9]/,
        '0': /^$|[0-9]/,
        a: /[A-Za-z]/,
        '*': /[A-Za-z0-9]/,
    };
    let permanents: any[] = [];
    let isPermanent = false;
    let parsedMaskString = '';
    mask.split('').forEach((character) => {
        if (!isPermanent && character === '\\') {
            isPermanent = true;
        } else {
            if (isPermanent || !defaultFormatChars[character]) {
                permanents.push(parsedMaskString.length);
            }
            parsedMaskString += character;
            isPermanent = false;
        }
    });

    let maskPlaceholder = parsedMaskString.split('').map((character, index) => {
        if (permanents.indexOf(index) === -1) {
            if (character === '0') {
                return '';
            } else {
                return '_';
            }
        }
        return character;
    });

    return maskPlaceholder.join('');
};

// TODO: Duplicated from FormulaManager
export const WrapSpecialChars = (value: string) => {
    return value.replace(/\\n/g, '\\n');
};

// TODO: Duplicated from FormulaManager
export const FormatFieldValueByType = (field: any) => {
    switch (typeof field) {
        case 'string':
            return "'" + WrapSpecialChars(field) + "'";
        case 'number':
            return '' + field + '';
        default:
            return '' + field + '';
    }
};

export const containsValue = (fieldPath: string, data?: Record<string, IFieldElem>) => {
    return data && data[fieldPath];
};

export const getValueString = (fieldPath: string, data?: Record<string, IFieldElem>) => {
    if (data) {
        const field = data[fieldPath];
        if (field?.value !== undefined && field?.value != null) {
            if (isIsoDateString(field?.value)) {
                let val = new Date(field?.value as any); // Moment(value).toDate();
                return Moment(val).format('DD.MM.YYYY HH:mm');
            } else {
                return field?.value.toString();
            }
        } else {
            return '';
        }
    }
    return '';
};

export const convertListToMap = (list: IField[], pref: string = '') => {
    const map = {} as Record<string, IFieldElem>;
    let index = 0;
    if (list) {
        for (const ele of list) {
            if (ele?.name) {
                map[pref + ele.name] = {
                    index: index,
                    name: `fields.[${index}].value`,
                    value: ele.value,
                };
                index++;
            }
        }
    }
    return map;
};

export const convertObjToMap = (object: any) => {
    const map = {} as Record<string, IFieldElem>;

    if (object) {
        const keysObj = Object.keys(object);
        for (let index = 0; index < keysObj.length; index++) {
            let keyName = keysObj[index];
            map[keyName] = {
                index: index,
                name: `fields.[${index}].value`,
                value: object[keyName],
            };
        }
    }
    return map;
};

// Выбор перечня ключей по схеме
export const getFieldKeysByScheme = (rows: IRow[]): string[] => {
    let result: string[] = [];
    if (!rows) return result;
    rows.forEach((r) => {
        r.columns.forEach((c) => {
            result = result
                .concat(c.abooks?.map((x) => x.key))
                .concat(c.abooks?.flatMap((x) => x.setValues?.sets.map((y) => y.key)))
                .concat(c.calcs?.map((x) => x.key))
                .concat(c.calcs?.flatMap((x) => getFieldKeysByChanges(x.changes)))
                .concat(c.dicts?.map((x) => x.key))
                .concat(c.dicts?.flatMap((x) => x.setValues?.sets.map((y) => y.key)))
                .concat(c.dicts?.flatMap((x) => x.formValues?.formValue.map((y) => y.attr)))
                .concat(c.dicts?.flatMap((x) => getFieldKeysByChanges(x.changes)))
                .concat(c.fields?.map((x) => x.key))
                .concat(c.fields?.flatMap((x) => getFieldKeysByChanges(x.changes)))
                .concat(c.tables?.map((x) => x.key))
                .concat(c.tables?.flatMap((x) => getFieldKeysByChanges(x.changes)))
                .concat(
                    c.tables?.flatMap((x) =>
                        x.tableColumn
                            .flatMap((y) => getFieldKeysByChanges(y.changes))
                            .concat(x.tableColumnDict.flatMap((y) => getFieldKeysByChanges(y.changes)))
                            .concat(x.tableColumnCalc.flatMap((y) => getFieldKeysByChanges(y.changes)))
                            .concat(x.tableColumnExDataSource.flatMap((y) => getFieldKeysByChanges(y.changes))),
                    ),
                )
                .concat(getFieldKeysByScheme(c.rows))
                .concat(c.blocks?.flatMap((x) => getFieldKeysByScheme(x.rows.row)))
                .concat(c.editInModals?.flatMap((x) => getFieldKeysByScheme(x.rows.row)));
        });
    });

    return result.filter((val) => val !== undefined);
};

// Выбор перечня ключей внутри changes
export const getFieldKeysByChanges = (changes: IChanges): string[] => {
    let result: string[] = [];
    if (!changes) return result;
    result = changes.change.flatMap((x) =>
        x.setValues?.sets
            .map((y) => y.key)
            .concat(getFieldKeysByChanges(x.changes))
            .concat(getFieldKeysByChanges(x.elseChanges?.changes))
            .concat(x.setValues.addRows.map((y) => y.table)),
    );
    return result;
};

// Выбор данных только для полей из схемы
export const getFormDataByScheme = (rows: IRow[], data: FieldValues): IFormData => {
    // Набор полей из схемы
    const keys = getFieldKeysByScheme(rows);
    // Возможно не оптимально по производительности
    const map = {} as Record<string, IField>;
    let index = 0;
    let list = data as IField[];
    for (const ele of list) {
        map[ele.name] = {
            name: ele.name,
            value: ele.value,
        };
        index++;
    }

    return {
        fields: keys.map((k) => map[k]).filter((x) => x != undefined),
    };
};

// Заполняем пустыми значениями поля на основе схемы
export const getEmptyFormDataByScheme = (rows: IRow[]): IFormData => {
    // Набор полей из схемы
    const keys = getFieldKeysByScheme(rows);

    return {
        fields: keys.map((k) => ({
            name: k,
            value: null,
        })),
    };
};

export const canVisibility = (rules?: string, data?: Record<string, IFieldElem>) => {
    const regex = /\{.*?\}/g;
    if (rules) {
        let result = rules; // or use regex without global flag gor replacing in original var

        if (rules && data) {
            let m;

            while ((m = regex.exec(rules)) !== null) {
                // eslint-disable-next-line no-loop-func
                m.forEach((match) => {
                    let fName = match.replace('{', '').replace('}', '');
                    let fValue = data[fName];
                    let fieldVal = fValue ? FormatFieldValueByType(fValue.value) : "''";
                    result = result?.replaceAll(match, fieldVal!);
                });
            }
        }
        return eval(result!);
    }
    return true;
};

export const parseTableDisplayFormat = (
    format?: string,
    data?: Record<string, IFieldElem>,
    joinSeparator: string = ', ',
) => {
    const regex = /\[.*?\]/g;
    if (format) {
        if (format && data) {
            const matches = Array.from(format.matchAll(regex)).map((match) => match[0]);
            if (matches.length > 0) {
                let match = matches[0];
                let rows: string[] = [];
                let columnName = match.replace('[', '').replace(']', '');
                let tableFieldName = format.replaceAll(match, '');
                let tableField = data[tableFieldName];
                if (tableField) {
                    let table = data[tableFieldName].value as any[];
                    if (!Array.isArray(table)) return '';
                    table.forEach((row) => {
                        rows.push(row[columnName]);
                    });
                    return rows.join(joinSeparator);
                }
            }
        }
    }
    return format;
};

export const parseDisplayFormat = (format?: string, data?: Record<string, IFieldElem>, joinSeparator: string = ',') => {
    const regex = /\{.*?\}/g;
    if (format) {
        if (format && data) {
            const matches = Array.from(format.matchAll(regex)).map((match) => match[0]);
            if (matches.length > 0) {
                let values: string[] = [];
                matches.forEach((match) => {
                    let fName = match.replace('{', '').replace('}', '');
                    if (fName.indexOf('[') != -1) {
                        // TODO Rework this
                        format = parseTableDisplayFormat(fName, data, joinSeparator);
                        if (format) {
                            values.push(format);
                        }
                    } else {
                        let fValue = data[fName];
                        let fieldVal =
                            fValue && fValue.value && fValue.value != null && fValue.value !== ''
                                ? fValue.value?.toString()
                                : '';
                        values.push(fieldVal);
                        format = format?.replaceAll(match, fieldVal!);
                    }
                });
                if (values.every((v) => v === null || v === undefined || v === '')) {
                    return { text: '', values: [] } as { text: string; values: string[] };
                }
            }
        }
        return {
            text: format.replace(/\|/g, joinSeparator),
            values: format.split('|').sort((a, b) => {
                const textA = a.toUpperCase();
                const textB = b.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
            }),
        };
    }

    return { text: '', values: [] } as { text: string; values: string[] };
};
export const parseDisplayFormatCell = (format?: string, data?: any) => {
    const regex = /\{.*?\}/g;
    if (format) {
        if (format && data) {
            const matches = Array.from(format.matchAll(regex)).map((match) => match[0]);
            let values: string[] = [];
            matches.forEach((match) => {
                let fName = match.replace('{', '').replace('}', '');
                let fValue = data[fName];
                let fieldVal = fValue ? fValue.toString() : '';
                values.push(fieldVal);
                format = format?.replaceAll(match, fieldVal!);
            });
            if (values.every((v) => v === null || v === undefined || v === '')) {
                return '';
            }
        }
        return format.replace(/\|/g, ',');
    }
    return '';
};

export const getValue = (fieldPath: string, data?: any) => {
    const fdata = data as IFormData;
    const field = fdata?.fields.find((obj) => {
        return obj.name === fieldPath;
    });
    if (field?.value !== undefined) {
        return field?.value;
    } else {
        return undefined;
    }
};
// export const getValueBoolean = (fieldPath: string, data?: any) => {
//   var fdata = data as IFormData;
//   const field = fdata?.fields.find((obj) => {
//     return obj.name === fieldPath;
//   });
//   if (field?.value !== undefined && field?.value !== null) {
//     return field?.value === 1 || field?.value === "1" ? true : false;
//   } else {
//     return undefined;
//   }
// };

export const getValueBoolean = (value?: any) => {
    if (value !== undefined && value !== null) {
        return value === 1 || value === '1' ? true : false;
    } else {
        return undefined;
    }
};

const isoDateFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-|\+(\d{2}):(\d{2})|Z)?)$/;
const isoDateNoTimeFormat = /^(\d{4})-(\d{2})-(\d{2})$/;

export const isIsoDateString = (value: any) => {
    return value && typeof value === 'string' && isoDateFormat.test(value);
};

export const isIsoDateNoTimeString = (value: any) => {
    return value && typeof value === 'string' && isoDateNoTimeFormat.test(value);
};

export const getValueDates = (value: any) => {
    if (isIsoDateString(value) || isIsoDateNoTimeString(value)) {
        let val = new Date(value); // Moment(value).toDate();
        return val;
    } else {
        return value;
    }
};

export const getValueTimes = (value: any) => {
    if (isIsoDateString(value)) {
        let val = new Date(value); // Moment(value).toDate();
        return val.toTimeString();
    } else {
        if (value instanceof Date) {
            let val = value as Date; // Moment(value).toDate();
            return val.toTimeString();
        } else {
            return value;
        }
    }
};

export const getFieldIndex = (fieldPath: string, data?: any) => {
    const fdata = data as IFormData;
    return fdata?.fields.findIndex((obj) => {
        return obj.name === fieldPath;
    });
};

export const getDisplayValue = (value: any) => {
    if (value) {
        let str = value.trim();
        if (str === '' || str === ',') {
            return '';
        } else {
            return value;
        }
    } else {
        return value;
    }
};

export const handlerFieldWatch = async (
    watch: any[],
    isEdit: boolean,
    isNew: boolean,
    manager: FormulaManager,
    setMethod: (value: React.SetStateAction<boolean>) => void,
    isActivatedComponent: React.MutableRefObject<boolean>,
) => {
    if (watch.length != 0) {
        if (!watch.every((element) => element === undefined)) {
            let result = await manager.EvalFormulaValues(isEdit, isNew, watch);
            if (isActivatedComponent.current) setMethod(result);
        }
    } else {
        let result = await manager.EvalFormulaValues(isEdit, isNew);
        if (isActivatedComponent.current) setMethod(result);
    }
};
export const handlerFieldReplaceWatch = async (
    watch: any[],
    isEdit: boolean,
    isNew: boolean,
    manager: FormulaManager,
    setMethod: (value: React.SetStateAction<any | undefined>) => void,
) => {
    if (watch.length != 0) {
        if (!watch.every((element) => element === undefined)) {
            let coll: any[] = [];
            watch.forEach((x) => {
                if (x === null) {
                    coll.push('');
                } else {
                    coll.push(x);
                }
            });
            let d = await manager.ReplaceFormulaValues(isEdit, isNew, coll, false);

            if (d) {
                setMethod(d);
            }
        }
    }
};

export interface IWatchHandlerResult {
    watch: any[];
    result: any;
}

export const handlerFieldDisplayWatch = async (
    watch: any[],
    isEdit: boolean,
    isNew: boolean,
    manager: FormulaManager,
) => {
    if (watch.length != 0) {
        if (!watch.every((element) => element === undefined)) {
            let result = await manager.ReplaceFormulaValues(isEdit, isNew, watch, true);

            return {
                watch: watch,
                result: result ? result : '',
            } as IWatchHandlerResult;
        }
    }
    return null;
};

export const checkAttachValidation = (conditions: IRequiredValidation[], files?: ISelectedFiles): string[] => {
    let result: string[] = [];

    if (files?.files === undefined || files?.files.length === 0) return result;

    for (let index = 0; index < conditions.length; index++) {
        let condition = conditions[index];
        let all = condition.type === undefined || condition.type === 'all';

        for (let index = 0; index < files?.attachProperties.length; index++) {
            const element = files?.attachProperties[index];
            let map = convertObjToMap(element);
            let checkResult = canVisibility(condition.condition, map);
            if (!checkResult && all) {
                result.push(condition.message);
                break;
            }
            if (checkResult && !all) {
                break;
            }
            if (!checkResult && !all && index === files?.attachProperties.length - 1) {
                result.push(condition.message);
            }
        }
    }
    return result;
};
