import React, { RefObject, useEffect, useImperativeHandle, useState } from 'react';
import { MdOutlineExpandMore } from 'react-icons/md';
import { IBaseAction } from '@models/actions/IBaseAction';
import { IActionService } from '@services/actions/IActionService';
import { IListElement } from '@/types';
import { IActionExecutor } from '@utils/actions/IActionExecutor';
import Button from '@atoms/Button';
import Menu from '@atoms/Menu';
import Preloader from '@atoms/Preloader';

import './GroupActionButton.scss';
import { IDocBasedService } from '@services/actions/IDocBasedService';
import { useLocation, useNavigate } from 'react-router';
import DataGrid from 'devextreme-react/data-grid';
import { TreeList } from 'devextreme-react/tree-list';
import { convertActions } from '@utils/actions/helper';

export interface IGroupActionButtonRefActions {
    setObjData: (objIds: string, objData: any) => void;
}

export interface IGroupActionButtonProps {
    service?: IActionService<IBaseAction>;
    onModifyData?: () => void;
    controlRef: React.Ref<IGroupActionButtonRefActions>;
    gridRef: RefObject<DataGrid | null> | RefObject<TreeList | null>;
}

const GroupActionButton: React.FC<IGroupActionButtonProps> = (props: IGroupActionButtonProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const keys = props.gridRef.current?.instance.getSelectedRowKeys();
        const data = props.gridRef.current?.instance.getSelectedRowsData();
        setObjIds(keys?.join(',') || '');
        setObjData(data);
    }, [props.gridRef]);

    useImperativeHandle(props.controlRef, () => ({
        setObjData: (objIds: string, objData: any) => {
            setObjIds(objIds);
            setObjData(objData);
        },
    }));

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [objIds, setObjIds] = useState<string>('');
    const [objData, setObjData] = useState<any>();
    const [actItems, setActItems] = useState<IListElement[] | undefined>();
    const [actionExecutor, setActionExecutor] = useState<IActionExecutor>();

    useEffect(() => {
        setActItems(undefined);
    }, [objIds]);

    const onActionClick = (action: IBaseAction) => {
        let executor = props.service?.getActionExecutor(action);
        if (executor) {
            executor.navigate = navigate;
            executor.location = location;
        }

        let docsBasedService = props.service as unknown as IDocBasedService;
        let parentId: string | undefined = undefined;
        if (docsBasedService) {
            parentId = docsBasedService.documentId;
        }
        executor?.run(
            objIds,
            parentId,
            action,
            objData,
            (isSucceed) => {
                setActionExecutor(undefined);

                if (isSucceed && props.onModifyData) {
                    props.onModifyData();
                }
            },
            () => {
                setActionExecutor(undefined);
                setActionExecutor(executor);
            },
        );
        setActionExecutor(executor);
    };

    return objIds.length > 0 ? (
        <>
            <Menu list={actItems} position="bottom-start">
                <div>
                    <Button
                        buttonType="secondary"
                        textColor="neutral"
                        size="s"
                        aria-label="Действия"
                        startAdornment={
                            isLoading ? (
                                <div className="busy-loader">
                                    <Preloader size="s2" position="inline" />
                                </div>
                            ) : (
                                <MdOutlineExpandMore size="20" />
                            )
                        }
                        onClick={(e) => {
                            if (!actItems && !isLoading) {
                                setIsLoading(true);

                                props.service
                                    ?.fetchGroupActions(objIds)
                                    .then((res) => {
                                        let acts = convertActions(res.data, onActionClick);
                                        setActItems(acts);
                                    })
                                    .finally(() => {
                                        setIsLoading(false);
                                    });
                            }
                        }}
                    >
                        Действия
                    </Button>
                </div>
            </Menu>

            {actionExecutor?.visualElement && actionExecutor?.visualElement()}
        </>
    ) : (
        <></>
    );
};

export default GroupActionButton;
