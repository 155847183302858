import './OfferApproveToStageTwo.scss';
import React, { useEffect, useMemo, useState } from 'react';
import Button from '@atoms/Button';
import classNames from 'classnames';
import { MdClose } from 'react-icons/md';
import Modal from '@atoms/Modal';
import { IOffer } from '@models/winnerSelection/ITableDemand';
import { Controller, useForm } from 'react-hook-form';
import { FormProviderControl, HookFormProvider, SelectControl } from '@controls/index';
import { IOption } from '@/types';
import { WinnerSelectionService } from '@services/WinnerSelectionService';
import { sendNotification } from '@molecules/Notifications';
import Hint from '@atoms/Hint';

export interface IOfferApproveToStageTwoProps {
    tableKey: string;
    isEdit: boolean;
    value: IOffer;
    demandId: string;
    onSubmit?: (value: IOfferApproveToStageTwoForm) => void;
    onClose?: () => void;
}

export interface IOfferApproveToStageTwoForm {
    approveToStageTwo: number | null;
}

const OfferApproveToStageTwo = ({
    tableKey,
    isEdit,
    value,
    demandId,
    onSubmit,
    onClose,
    ...props
}: IOfferApproveToStageTwoProps) => {
    const [error, setError] = useState<string>();
    const methods = useForm<IOfferApproveToStageTwoForm>({
        mode: 'onBlur',
    });

    const { isSubmitting, isDirty } = methods.formState;

    useEffect(() => {
        const defaultValue: IOfferApproveToStageTwoForm = {
            approveToStageTwo: value.approveToStageTwo,
        };
        methods.reset(defaultValue);
    }, [value]);

    const onClickClose = (e: any) => {
        onClose && onClose();
    };

    const onFormSubmit = async (form: IOfferApproveToStageTwoForm) => {
        setError(undefined);
        return WinnerSelectionService.saveTableDemandOfferApproveToStageTwo(value.key, demandId, tableKey, form)
            .then((response) => {
                sendNotification({
                    message: 'Данные успешно сохранены',
                    variant: 'green',
                });
                onSubmit && onSubmit(form);
            })
            .catch((reason) => {
                setError(reason);
                console.log(reason);
            });
    };

    const results = useMemo(() => {
        return [
            { label: 'Допущен', value: 1 },
            { label: 'Не допущен', value: 0 },
        ] as IOption[];
    }, []);

    const errorLabel = error && (
        <div className="offer-st2__error">
            <Hint title={error} variant="red" />
        </div>
    );

    return (
        <Modal
            size="m"
            header={
                <div className={classNames('offer-st2-modal__header')}>
                    <div className={classNames('offer-st2-modal__header-text')}>
                        <span>{`Допуск ко второму этапу предложения №${value.number || ''} (${value.name})`}</span>
                    </div>
                    <div className={classNames('offer-st2-modal__header-close')}>
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="xs"
                            aria-label="Закрыть окно"
                            onClick={onClickClose}
                            startAdornment={<MdClose size="24" />}
                        />
                    </div>
                </div>
            }
        >
            {errorLabel}
            <FormProviderControl formMethods={methods} className="offer-st2__form" onSubmit={onFormSubmit}>
                <HookFormProvider controller={Controller}>
                    <div className="offer-st2__content">
                        <div className="offer-st2__row">
                            <SelectControl
                                label="Допуск ко второму этапу"
                                name="approveToStageTwo"
                                readOnly={true}
                                disabled={!isEdit}
                                highlightMenuItems={false}
                                options={results}
                                formState={methods.formState}
                                control={methods.control}
                                required={true}
                                rules={{ required: 'Обязательное поле' }}
                                setValue={(options) => {
                                    return options[0].value;
                                }}
                                getValue={(options: IOption[], value: any) => {
                                    return options.filter((x) => x.value === value);
                                }}
                            />
                        </div>
                    </div>
                    {isEdit ? (
                        <div className="offer-st2__footer">
                            <div className={classNames('offer-st2__row', 'offer-st2__submit-row')}>
                                <Button
                                    type="submit"
                                    preloader={isSubmitting}
                                    className="offer-st2__button offer-st2__save-button"
                                    disabled={isSubmitting || !isDirty}
                                >
                                    Сохранить
                                </Button>
                            </div>
                        </div>
                    ) : null}
                </HookFormProvider>
            </FormProviderControl>
        </Modal>
    );
};

export default OfferApproveToStageTwo;
