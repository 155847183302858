import { ActivityActionTypes } from '@models/actions/ActivityActionTypes';
import { IBaseAction } from '@models/actions/IBaseAction';
import { IActionExecutor } from './IActionExecutor';
import { TakeToWorkExecutor } from '@utils/actions/activityExecutors/TakeToWorkExecutor';
import { ApproveWithSignExecutor } from '@utils/actions/activityExecutors/ApproveWithSignExecutor';
import { ReworkWithSignExecutor } from '@utils/actions/activityExecutors/ReworkWithSignExecutor';
import { RejectWithSignExecutor } from '@utils/actions/activityExecutors/RejectWithSignExecutor';
import { ApproveExecutor } from '@utils/actions/activityExecutors/ApproveExecutor';
import { ReworkExecutor } from '@utils/actions/activityExecutors/ReworkExecutor';
import { RejectExecutor } from '@utils/actions/activityExecutors/RejectExecutor';
import { CompleteExecutor } from '@utils/actions/activityExecutors/CompleteExecutor';
import { RequestAddApprovalExecutor } from '@utils/actions/activityExecutors/RequestAddApprovalExecutor';
import { ActivityBatchEditingExecutor } from '@utils/actions/activityExecutors/ActivityBatchEditingExecutor';
import { ReplacePerformerExecutor } from '@utils/actions/activityExecutors/ReplacePerformerExecutor';
import { RollbackPerformerExecutor } from '@utils/actions/activityExecutors/RollbackPerformerExecutor';
import { DelegateExecutor } from '@utils/actions/activityExecutors/DelegateExecutor';
import { RollbackDelegateExecutor } from '@utils/actions/activityExecutors/RollbackDelegateExecutor';
import { ResolveExceptionExecutor } from '@utils/actions/activityExecutors/ResolveExceptionExecutor';
import { ChangePerformerExecutor } from '@utils/actions/ChangePerformerExecutor';

export class ActivityActionsExecFactory {
    public static allExecutors: { [actType: number]: () => IActionExecutor } = {
        [ActivityActionTypes[ActivityActionTypes.TakeToWork]]: () => new TakeToWorkExecutor(),
        [ActivityActionTypes[ActivityActionTypes.ApproveWithSign]]: () => new ApproveWithSignExecutor(),
        [ActivityActionTypes[ActivityActionTypes.ReworkWithSign]]: () => new ReworkWithSignExecutor(),
        [ActivityActionTypes[ActivityActionTypes.RejectWithSign]]: () => new RejectWithSignExecutor(),
        [ActivityActionTypes[ActivityActionTypes.Approve]]: () => new ApproveExecutor(),
        [ActivityActionTypes[ActivityActionTypes.Rework]]: () => new ReworkExecutor(),
        [ActivityActionTypes[ActivityActionTypes.Reject]]: () => new RejectExecutor(),
        [ActivityActionTypes[ActivityActionTypes.Complete]]: () => new CompleteExecutor(),
        [ActivityActionTypes[ActivityActionTypes.RequestAdditionalApproval]]: () => new RequestAddApprovalExecutor(),
        [ActivityActionTypes[ActivityActionTypes.ActivityBatchEditing]]: () => new ActivityBatchEditingExecutor(),
        [ActivityActionTypes[ActivityActionTypes.ReplacePerformer]]: () => new ReplacePerformerExecutor(),
        [ActivityActionTypes[ActivityActionTypes.RollbackPerformer]]: () => new RollbackPerformerExecutor(),
        [ActivityActionTypes[ActivityActionTypes.Delegate]]: () => new DelegateExecutor(),
        [ActivityActionTypes[ActivityActionTypes.RollbackDelegate]]: () => new RollbackDelegateExecutor(),
        [ActivityActionTypes[ActivityActionTypes.ResolveException]]: () => new ResolveExceptionExecutor(),
        [ActivityActionTypes[ActivityActionTypes.ChangePerformer]]: () => new ChangePerformerExecutor(),
    };

    static GetActionExecutor(act: IBaseAction, throwError: boolean = true): IActionExecutor | undefined {
        let createExecutor = this.allExecutors[act.type];
        if (!createExecutor) {
            if (!throwError) return undefined;

            throw new Error(`Не обнаружен ActionExecutor для типа ${act.type}`);
        }

        return createExecutor();
    }
}
