import React, { ReactNode, RefObject, useEffect, useImperativeHandle, useRef, useState } from 'react';
import './Abookpicker.scss';
import { MdClose, MdMenu } from 'react-icons/md';
import Input from '../Input';
import classNames from 'classnames';
import AbookpickerModal from './AbookpickerModal/AbookpickerModal';
import { IAbookSource, IAbookTab, IExternalSearchAbook, IGridModalAttributes, ISetValues } from '@models/Forms/IForms';
import { IAddressBookData } from '@models/addressbook/IAddressBookData';

export interface IAbookpickerRefActions {
    reset: () => void;
    unSelect: (item: IAddressBookData) => void;
}

export interface IAbookpickerProps<T extends HTMLElement = HTMLDivElement> {
    /** Имя поля */
    name?: string;
    /** */
    isMultiple: boolean;
    /** */
    title: string;
    /** зависимые поля*/
    setValues?: ISetValues;
    /** колонки для грида */
    gridAttribute?: IGridModalAttributes;
    /** Текст Placeholder */
    placeholder?: string;
    /** Значение по умолчанию */
    defaultValue?: any;
    /** отображаемое значение */
    displayValue: string;
    /** Скрыть поле */
    disabled?: boolean;
    /** Только для просмотра */
    readOnly?: boolean;
    /** Функция изменения значения */
    onChange?: (value: IAddressBookData[]) => void;
    /** Кастомная кнопка */
    children?: ReactNode | ReactNode[];
    /** Переводит инпут в невалидный статус */
    invalid?: boolean;
    /**
     * Добавляет инпуту белый фон
     * @default true
     */
    filled?: boolean;
    /** Ссылка на контейнер портала */
    containerRef?: RefObject<T>;
    /** Реф для пробрасывания действий по кастомным кнопкам */
    controlRef?: React.Ref<IAbookpickerRefActions>;
    /**
     *  Добавлять фокус при выборе дат
     * @default false
     *  */
    isFocusBorder?: boolean;
    formValues: string;

    /**
     * Метод получения formValues
     * */
    getFormValues?: () => string;

    /**
     * Вкладки адресной книги
     * */
    tabsSettings?: IAbookTab[];

    /**
     * Дополнительная настройка источника данных (выбор данных из реквизитов-таблиц и т.д.)
     * */
    source?: IAbookSource;

    externalSearch?: IExternalSearchAbook;
    showChips: boolean;
    /**
     *  Отображение иконки сброса значения
     * @default true
     *  */
    showClearIcon?: boolean;

    insideGrid?: boolean;
}

// FIXME: Добавить управление с клавиатуры
const Abookpicker: React.FC<IAbookpickerProps> = ({
    name = 'Abookpicker',
    placeholder = 'Выберите значение',
    defaultValue,
    displayValue,
    gridAttribute,
    invalid = false,
    filled = true,
    disabled = false,
    readOnly = false,
    insideGrid = false,
    onChange,
    children,
    title,
    isMultiple,
    containerRef,
    controlRef,
    formValues,
    isFocusBorder = false,
    tabsSettings,
    source,
    externalSearch,
    showChips,
    showClearIcon = false,
    getFormValues = undefined,
}: IAbookpickerProps) => {
    useImperativeHandle(controlRef, () => ({
        reset: () => {},
        unSelect: (item: IAddressBookData) => {},
    }));

    // -------------------------------------------------------------------------------------------------------------------

    const AbookpickerRef = useRef<HTMLDivElement>(null);

    const [showAbook, toggleAbook] = useState<boolean>(!readOnly && insideGrid);

    // -------------------------------------------------------------------------------------------------------------------

    const [inputValue, setInputValue] = useState<number[]>();

    useEffect(() => {
        if (!defaultValue) {
            return;
        }

        if (source?.table) {
            let keys = (defaultValue as any[])?.map((row: any) => parseInt(row[source.key]));
            if (keys) {
                setInputValue(keys);
            }
        } else {
            if (defaultValue.toString().indexOf('|') > 0) {
                let arr = [] as number[];
                defaultValue
                    .toString()
                    .split('|')
                    .forEach((item: string) => {
                        arr.push(Number(item));
                    });

                setInputValue(arr);
            } else {
                let arr = [] as number[];
                arr.push(Number(defaultValue));
                setInputValue(arr);
            }
        }
    }, [defaultValue]);

    const clearHandler = () => {
        setInputValue(undefined);
        onChange && onChange([]);
    };

    return (
        <div
            className={classNames(
                'rf-abookpicker',
                isFocusBorder && 'rf-abookpicker__focus-border',
                disabled && 'rf-abookpicker--disabled',
            )}
            ref={AbookpickerRef}
        >
            <div
                className={classNames('rf-abookpicker__input-wrapper', {
                    'rf-abookpicker__input-wrapper--disabled': disabled,
                    'rf-abookpicker__input-wrapper--readonly': readOnly,
                })}
                onClick={!readOnly ? (e: any) => toggleAbook((prev) => !prev) : () => {}}
            >
                {children || (
                    <Input
                        name={name}
                        value={displayValue}
                        placeholder={placeholder}
                        disabled={disabled}
                        readOnly={true}
                        withoutClasses={!readOnly}
                        invalid={invalid}
                        filled={filled}
                        endAdornment={
                            !readOnly && !disabled ? (
                                <>
                                    {showClearIcon && (
                                        <div className={'rf-abookpicker__calendar-chevron'}>
                                            <MdClose
                                                size="20"
                                                onClick={(e) => {
                                                    clearHandler();
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}
                                            />
                                        </div>
                                    )}
                                    {!readOnly && !disabled ? (
                                        <div className={'rf-abookpicker__calendar-chevron'}>
                                            <MdMenu />
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            ) : undefined
                        }
                    />
                )}
            </div>

            {showAbook && (
                <AbookpickerModal
                    selected={inputValue!}
                    isMultiple={isMultiple}
                    title={title!}
                    externalSearch={externalSearch}
                    formValues={formValues}
                    getFormValues={getFormValues}
                    gridAttribute={gridAttribute}
                    onSubmitModal={onChange!}
                    onCloseModal={() => toggleAbook((prev) => !prev)}
                    tabsSettings={tabsSettings!}
                    showChips={showChips}
                />
            )}
        </div>
    );
};

export default Abookpicker;
