import './../actions.scss';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Button from '@atoms/Button';
import Hint from '@atoms/Hint';
import Modal from '@atoms/Modal';
import Preloader from '@atoms/Preloader';
import Select from '@atoms/Select';
import {
    AbookpickerControl,
    DatepickerControl,
    FormControl,
    InputNumberControl,
    SelectControl,
    TextareaControl,
} from '@controls/index';
import FormProviderControl from '@controls/FormProviderControl';
import HookFormProvider from '@controls/HookFormProvider';
import { MdClose } from 'react-icons/md';
import { IActivityAction, IBaseAction } from '@models/actions/IBaseAction';
import { IAddressBookData } from '@models/addressbook/IAddressBookData';
import { ActivityBaseActionExecutor } from '@utils/actions/ActivityBaseActionExecutor';
import { IOption } from '@/types';
import DueTimeModeTypeOption, { DueTimeModeType } from '@/types/DueTimeType';
import DurationSpanTypeOption from '@/types/DurationSpanType';
import classNames from 'classnames';
import { getActivityIdFromCompositeId } from '@utils/helpers';
import {
    IRequestAdditionalApprovalModel,
    IRequestAdditionalApprovalSend,
} from '@models/document/activityExecutors/IRequestAdditionalApprovalModel';
import { getABookFormValues } from '@utils/abookUtils';
import { IFormValues } from '@models/Forms/IForms';
import { ModalSize } from '@atoms/Modal/Modal';
import { ActivityExecutionService } from '@services/actions/ActivityExecutionService';

export class RequestAddApprovalExecutor extends ActivityBaseActionExecutor {
    private _modalProps?: IRequestAddApprovalProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        let activityAction = action as IActivityAction;
        this._modalProps = {
            activityId: getActivityIdFromCompositeId(objId),
            docId: activityAction?.docId ?? '-1',
            actionKey: action.key,
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'l',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <RequestAddApprovalModal {...this._modalProps} /> : <></>;
    };
}

interface IRequestAddApprovalProps {
    activityId: string;
    docId: string;
    actionKey: string;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    completeHandler?: (isSucceed: boolean) => void;
}

const RequestAddApprovalModal: React.FC<IRequestAddApprovalProps> = (props: IRequestAddApprovalProps) => {
    const [formValues, setFormValues] = useState<string>('{}');
    const activated = React.useRef(false);

    const getFormValues = async (fValues?: IFormValues) => {
        const vals = await getABookFormValues(props.docId, fValues);
        if (activated.current && vals) {
            setFormValues(vals);
        }
    };

    const [periodType, setPeriodType] = useState<DueTimeModeType>(DueTimeModeType.Indefinite);
    const [executors, setExecutors] = useState<IAddressBookData[]>();

    const [model, setModel] = useState<IRequestAdditionalApprovalModel>();
    const [errorText, setErrorText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();

    const serv = new ActivityExecutionService();

    const onSubmit = (form: IRequestAddApprovalFormData) => {
        if (!form.taskText) {
            setErrorText('Не указан текст задачи');
            return;
        }
        if ((form?.executors?.length ?? 0) == 0) {
            setErrorText('Не указаны исполнители');
            return;
        }

        let data: IRequestAdditionalApprovalSend = {
            taskText: form.taskText,
            dueTimeMode: DueTimeModeType[periodType],
            executorsIds: executors?.map((x) => x.key) ?? [],
            periodDate: form.periodDate,
            termCount: form.termCount,
            termType: form?.termType ? form?.termType[0].value : undefined,
            activityKeys: [Number(props.activityId)],
            finishedNotificationText: model?.finishedNotificationText ?? '',
        };

        setLoading(true);
        serv.sendRequestAdditionalApproval(props.actionKey, props.activityId, data)
            .then((res) => {
                if (props.completeHandler) props.completeHandler(true);
            })
            .catch((err) => {
                setErrorText(err);
            })
            .finally(() => setLoading(false));
    };

    const formState = useForm<IRequestAddApprovalFormData>({
        mode: 'onBlur', // "onChange"
    });

    const onPeriodTypeChange = (option: IOption[]) => {
        if (option.length == 0) return;
        let type = DueTimeModeType[option[0].value as keyof typeof DueTimeModeType];
        setPeriodType(type);
    };

    useEffect(() => {
        setLoading(true);

        serv.getRequestAdditionalApprovalModel(props.actionKey, props.activityId).then((res) => {
            setModel(res.data);
            if (res.data.defaultDueTime) {
                setPeriodType(DueTimeModeType[res.data.defaultDueTime.type]);
                formState.reset({
                    termType: DurationSpanTypeOption.filter((x) => x.value === res.data.defaultDueTime.timeSpanType),
                });
            }
            getFormValues(res.data?.formValues);
            setLoading(false);
        });
        return () => {
            activated.current = false;
        };
    }, []);

    return (
        <Modal
            className={classNames('modal-dlg-container')}
            size={props.modalSize}
            header={
                <>
                    <div className={classNames('box')}>
                        <div>
                            <span>Запрос дополнительного согласования</span>
                        </div>
                        <div className={classNames('left')}>
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xs"
                                aria-label="Закрыть окно"
                                onClick={() => {
                                    if (props?.completeHandler) props?.completeHandler(false);
                                }}
                                startAdornment={<MdClose size="24" />}
                            />
                        </div>
                    </div>
                </>
            }
        >
            {loading ? (
                <Preloader size="m" position="static" />
            ) : (
                <div>
                    {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" />}

                    <FormProviderControl formMethods={formState} onSubmit={onSubmit}>
                        <HookFormProvider controller={Controller}>
                            <AbookpickerControl
                                label="Исполнители"
                                placeholder="Исполнители"
                                isMultiple={true}
                                title="Исполнители"
                                displayValue={executors?.map((x) => x.fullName)?.join(',') ?? ''}
                                formValues={formValues}
                                name="executors"
                                tabsSettings={model?.tabs}
                                externalSearch={model?.externalSearch}
                                required
                                formState={formState.formState}
                                showChips={false}
                                control={formState.control}
                                onSetValues={(value: IAddressBookData[]) => {
                                    setExecutors(value);
                                }}
                            />

                            <FormControl
                                name="periodType"
                                label="Срок исполнения"
                                formState={formState.formState}
                                required
                            >
                                <Select
                                    placeholder="Укажите срок исполнения"
                                    values={DueTimeModeTypeOption.filter(
                                        (x) => x.value === DueTimeModeType[periodType],
                                    )}
                                    multiselect={false}
                                    options={DueTimeModeTypeOption}
                                    preloader={loading}
                                    readOnly
                                    onChange={onPeriodTypeChange}
                                />
                            </FormControl>

                            {periodType == DueTimeModeType.TimeSpan && (
                                <>
                                    <InputNumberControl
                                        name="termCount"
                                        defaultValue={model?.defaultDueTime?.timeSpanValue ?? 1}
                                        min={1}
                                        max={9999}
                                        floatPoints={0}
                                        formState={formState.formState}
                                        control={formState.control}
                                    />

                                    <SelectControl
                                        multiselect={false}
                                        name="termType"
                                        readOnly
                                        options={DurationSpanTypeOption}
                                        /*defaultValue={[
                                            DurationSpanTypeOption.find(
                                                (option) => option.value === model?.defaultDueTime?.timeSpanType,
                                            ) ?? DurationSpanTypeOption[2],
                                        ]}*/
                                        preloader={loading}
                                        formState={formState.formState}
                                        control={formState.control}
                                    />
                                </>
                            )}

                            {periodType == DueTimeModeType.Date && (
                                <DatepickerControl
                                    name="periodDate"
                                    formState={formState.formState}
                                    control={formState.control}
                                />
                            )}

                            <TextareaControl
                                label="Текст задачи"
                                placeholder={'Текст задачи'}
                                readOnly={false}
                                required={true}
                                rules={{ required: 'Обязательное поле' }}
                                name="taskText"
                                defaultValue={model?.prefilledComment}
                                formState={formState.formState}
                                control={formState.control}
                                onValueChange={(val: any) => {}}
                            />

                            <div className="modal-dlg-buttons">
                                <div className={'left'}>
                                    <Button
                                        size="s"
                                        aria-label={props.okButtonText}
                                        //type='submit'
                                        onClick={(e) => {
                                            let formData = formState.getValues() as IRequestAddApprovalFormData;
                                            onSubmit(formData);
                                        }}
                                    >
                                        {props.okButtonText}
                                    </Button>

                                    <Button
                                        buttonType="light"
                                        size="s"
                                        aria-label={props.cancelButtonText}
                                        onClick={() => {
                                            if (props.completeHandler) props.completeHandler(false);
                                        }}
                                    >
                                        {props.cancelButtonText}
                                    </Button>
                                </div>
                            </div>
                        </HookFormProvider>
                    </FormProviderControl>
                </div>
            )}
        </Modal>
    );
};

interface IRequestAddApprovalFormData {
    executors: IAddressBookData[];
    //periodType: IOption[],// PeriodTypes,
    termCount?: number;
    termType?: IOption[]; // TermTypes,
    periodDate?: Date;
    taskText: string;
}
