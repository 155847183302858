import React, { FC, ReactNode } from 'react';
import { Controller, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import classNames from 'classnames';
import Button from '@atoms/Button';
import Modal from '@atoms/Modal';
import Preloader from '@atoms/Preloader';
import HookFormProvider from '@controls/HookFormProvider';
import './ModalContainer.scss';
import Hint from '@atoms/Hint';
import { ModalSize } from '@atoms/Modal/Modal';
import FormProviderControl from '@controls/FormProviderControl/FormProviderControl';
import { v4 as uuidv4 } from 'uuid';

export interface IModalContainerProps {
    header: string;
    size?: ModalSize;
    showOkButton: boolean;
    okButtonText?: string;
    showCancelButton: boolean;
    cancelButtonText?: string;
    cancelClick: () => void;
    isBusy?: boolean;
    errorText?: string;
    successText?: string;
    children?: ReactNode;
    className?: string;
    formMethods: UseFormReturn<any>;
    onSubmit: SubmitHandler<any>;
    ignoreEnterSubmit?: boolean;
    okButtonDisabled?: boolean;
}

const ModalContainer: FC<IModalContainerProps> = ({
    size = 'm',
    okButtonText = 'ОК',
    cancelButtonText = 'Отмена',
    okButtonDisabled,
    formMethods,
    ...props
}: IModalContainerProps) => {
    const idForm = uuidv4();

    return (
        <Modal
            className={classNames('modal-dlg-container')}
            size={size}
            header={
                <>
                    <div className={classNames('box')}>
                        <div>
                            <span>{props.header}</span>
                        </div>
                        <div className={classNames('left')}>
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xs"
                                aria-label="Закрыть окно"
                                onClick={() => {
                                    if (props?.cancelClick) props?.cancelClick();
                                }}
                                startAdornment={<MdClose size="24" />}
                            />
                        </div>
                    </div>
                </>
            }
        >
            {props.isBusy ? (
                <Preloader size="m" position="static" />
            ) : (
                <div>
                    {props.errorText && (
                        <Hint icon="info" title={`Ошибка: ${props.errorText}`} variant="red" maxWidth="100%" />
                    )}
                    {props.successText && (
                        <Hint icon="info" title={`${props.successText}`} variant="green" maxWidth="100%" />
                    )}

                    <FormProviderControl
                        formMethods={formMethods}
                        className="modal-form"
                        onSubmit={props.onSubmit}
                        id={idForm}
                    >
                        <HookFormProvider controller={Controller}>
                            {props.children}

                            <div className="modal-dlg-buttons">
                                <div className={'left'}>
                                    {props.showOkButton && (
                                        <Button
                                            size="s"
                                            aria-label={okButtonText}
                                            type="submit"
                                            form={idForm}
                                            disabled={okButtonDisabled || formMethods.formState.isSubmitting}
                                        >
                                            {okButtonText}
                                        </Button>
                                    )}
                                    {props.showCancelButton && (
                                        <Button
                                            buttonType="light"
                                            size="s"
                                            aria-label={cancelButtonText}
                                            onClick={() => {
                                                if (props?.cancelClick) props?.cancelClick();
                                            }}
                                        >
                                            {cancelButtonText}
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </HookFormProvider>
                    </FormProviderControl>
                </div>
            )}
        </Modal>
    );
};

export default ModalContainer;
