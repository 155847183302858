import './FileViewer.scss';
import React from 'react';
import classNames from 'classnames';
import { IDocument } from '@cyntler/react-doc-viewer/dist/models';
import DocViewer, {
    BMPRenderer,
    CSVRenderer,
    GIFRenderer,
    HTMLRenderer,
    JPGRenderer,
    PNGRenderer,
    TIFFRenderer,
    TXTRenderer,
    VideoRenderer,
} from '@cyntler/react-doc-viewer';
import FatalError from '@molecules/FatalError';
import FatalErrorIcon from '@molecules/FatalError/FatalErrorIcon';
import Preloader from '@atoms/Preloader';
import PDFIFrameRenderer from './customRenderers/PDFIFrameRenderer/PDFIFrameRenderer';

export interface IFileViewerProps {
    /** Файлы */
    docs: IDocument[];
    /** Класс */
    className?: string;
    /** Номер первоначального активного документа */
    initialActiveDocumentNumber?: number;
    /** Колбэк при изменении документа */
    onDocumentChange?: (document: IDocument) => void;
}

const FileViewer = ({
    docs,
    className,
    initialActiveDocumentNumber = 0,
    onDocumentChange,
    ...props
}: IFileViewerProps) => {
    const NoRenderer = ({ document, fileName }: { document: IDocument | undefined; fileName: string }) => {
        return (
            <FatalError
                icon={<FatalErrorIcon variant="server" />}
                title="Просмотр данного типа файлов не поддерживается"
                description={''}
            />
        );
    };

    const LoadingRenderer = ({ document, fileName }: { document: IDocument | undefined; fileName: string }) => {
        return (
            <div className={'rf-file-viewer__loading'}>
                <Preloader size="l" />
            </div>
        );
    };

    return (
        <div className={classNames('rf-file-viewer', className)}>
            <DocViewer
                documents={docs}
                initialActiveDocument={docs[initialActiveDocumentNumber]}
                pluginRenderers={[
                    BMPRenderer,
                    HTMLRenderer,
                    JPGRenderer,
                    PDFIFrameRenderer,
                    PNGRenderer,
                    TIFFRenderer,
                    TXTRenderer,
                    CSVRenderer,
                    GIFRenderer,
                    VideoRenderer,
                ]}
                language={'ru'}
                config={{
                    noRenderer: {
                        overrideComponent: NoRenderer,
                    },
                    loadingRenderer: {
                        overrideComponent: LoadingRenderer,
                    },
                }}
                onDocumentChange={(document) => {
                    onDocumentChange && onDocumentChange(document);
                }}
            />
        </div>
    );
};

export default FileViewer;
