import React, { ReactNode } from 'react';

import '../styles/iproc-scheme.css';

import TreeList, { ITreeListOptions } from 'devextreme-react/tree-list';
import { initMessages } from '@utils/devextreme-react/dataGridUtils';

export interface IDevExpressTreeListOptions extends ITreeListOptions {
    ref?: React.Ref<TreeList>;
    children?: ReactNode;
}
const DevExpressTreeList: React.FC<IDevExpressTreeListOptions> = ({
    ref,
    children,
    rowAlternationEnabled = true,
    ...props
}) => {
    initMessages();

    return (
        <TreeList rowAlternationEnabled={rowAlternationEnabled} {...props} ref={ref}>
            {children}
        </TreeList>
    );
};

export default DevExpressTreeList;
