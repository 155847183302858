import './WinnerSelectionTablePositions.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ICustomComponentProps } from '@molecules/formbuilder/controls/CustomComponent/CustomComponent';
import { DataGrid } from 'devextreme-react/data-grid';
import Preloader from '@atoms/Preloader';
import Hint from '@atoms/Hint';
import WinnerSelectionTableDemand from '../WinnerSelectionTableDemand/WinnerSelectionTableDemand';
import { WinnerSelectionService } from '@services/WinnerSelectionService';
import { IPosition, ITablePositionsSettings } from '@models/winnerSelection/ITablePositions';
import Button from '@atoms/Button';
import { MdBusinessCenter, MdClose, MdFullscreen, MdPayments, MdTrolley } from 'react-icons/md';
import { createPortal } from 'react-dom';
import Tile from '@atoms/Tile';
import Tag from '@atoms/Tag';
import Tooltip from '@atoms/Tooltip';
import { formatPrice } from '@utils/helpers';

export interface IWinnerSelectionTablePositionsProps<TFieldValues extends object = object>
    extends ICustomComponentProps<TFieldValues> {}

const WinnerSelectionTablePositions = <TFieldValues extends object = object>({
    component,
    formMethods,
    isEdit,
    isNew,
    fields,
    docId,
    ...props
}: IWinnerSelectionTablePositionsProps<TFieldValues>) => {
    const activated = React.useRef(false);
    const gridRef = useRef<DataGrid>(null);

    const [positions, setPositions] = useState<IPosition[]>();
    const [settings, setSettings] = useState<ITablePositionsSettings>();
    const [errorText, setErrorText] = useState<string>();

    const [currentPosition, setCurrentPosition] = useState<IPosition>();
    const [showFullScreen, setShowFullscreen] = useState<boolean>(false);

    const tableKey = component.params?.tableKey;

    const loadData = useCallback(() => {
        gridRef.current?.instance.beginCustomLoading('Загрузка');

        if (!tableKey) {
            console.error('WinnerSelectionTablePositions. Ошибка загрузки данных (tableKey пустой)');
            return;
        }

        WinnerSelectionService.fetchTablePositionsData(docId, tableKey)
            .then((response) => {
                setPositions(response.data.positions);
                setSettings(response.data.settings);
            })
            .catch((reason) => {
                setErrorText(reason);
            })
            .finally(() => {
                gridRef.current?.instance.endCustomLoading();
            });
    }, [docId, tableKey]);

    useEffect(() => {
        activated.current = true;

        loadData();

        return () => {
            activated.current = false;
        };
    }, []);

    useEffect(() => {
        if (positions && positions.length !== 0) {
            setCurrentPosition(positions[0]);
        }
    }, [positions]);

    const loaderElement = (
        <div className={'winnerSelectionTablePositions__loading'}>
            <Preloader position="inline" size="l" />
        </div>
    );

    const getPositionHeader = useCallback((position: IPosition) => {
        return (
            <div className="winnerSelectionTablePositions__position-header-content">
                <div className="winnerSelectionTablePositions__position-header-text">{position.name || ''}</div>
                <div className="winnerSelectionTablePositions__position-header-details">
                    <div className="winnerSelectionTablePositions__position-header-branch">
                        <Tooltip openDelay={100} background="black" position="bottom">
                            <Tag
                                icon={<MdBusinessCenter size="24px" />}
                                style={{ height: 'initial', padding: '0.25rem 0.75rem' }}
                                maxLength={1000}
                            >
                                {`${position.branch || ''}(${position.subdivision || ''})`}
                            </Tag>
                            {`Филиал: ${position.branch || ''}\nПлощадка: ${position.subdivision || ''}`}
                        </Tooltip>
                    </div>
                    <div className="winnerSelectionTablePositions__position-header-price">
                        <Tooltip openDelay={100} background="black" position="bottom">
                            <Tag
                                icon={<MdPayments size="24px" />}
                                style={{ height: 'initial', padding: '0.25rem 0.75rem' }}
                            >
                                {formatPrice(position.price, position.currencyCode)}
                            </Tag>
                            {`Стоимость\nВалюта: ${position.currencyText || ''} (${position.currencyCode})`}
                        </Tooltip>
                    </div>
                    <div className="winnerSelectionTablePositions__position-header-quantity">
                        <Tooltip openDelay={100} background="black" position="bottom">
                            <Tag
                                icon={<MdTrolley size="24px" />}
                                style={{ height: 'initial', padding: '0.25rem 0.75rem' }}
                            >
                                {position.quantity !== null && position.quantity !== undefined ? position.quantity : ''}
                            </Tag>
                            {`Количество\nЕдиницы измерения: ${position.units || ''}`}
                        </Tooltip>
                    </div>
                </div>
            </div>
        );
        /*return `${position.name || ''}.
        Количество: ${position.quantity !== null && position.quantity !== undefined ? position.quantity : ''}. 
        Единица измерения: ${position.units || ''}. 
        Стоимость: ${formatPrice(position.price, position.currencyCode)} (${position.currencyText || ''})`;*/
    }, []);

    const positionContentElement = (
        <div className="winnerSelectionTablePositions__position-content">
            <Tile>
                {currentPosition ? (
                    <>
                        <div className="winnerSelectionTablePositions__position-header">
                            {getPositionHeader(currentPosition)}
                            <Button
                                className="winnerSelectionTablePositions__fullscreen-btn"
                                buttonType="secondary"
                                size="s"
                                startAdornment={<MdFullscreen size="16" />}
                                onClick={() => {
                                    setShowFullscreen(true);
                                }}
                            >
                                На весь экран
                            </Button>
                        </div>
                        <div className="winnerSelectionTablePositions__position-table">
                            <WinnerSelectionTableDemand
                                key={currentPosition?.key}
                                isEdit={isEdit}
                                isNew={isNew}
                                fields={fields}
                                component={component}
                                formMethods={formMethods}
                                docId={currentPosition.key}
                            />
                        </div>
                    </>
                ) : (
                    loaderElement
                )}
            </Tile>
        </div>
    );

    /** Боковая навигация для секций */
    const asideItemsElement = positions?.map((pos: IPosition, idx: number) => {
        const activeClass = currentPosition?.key === pos.key ? 'winnerSelectionTablePositions__aside-link--active' : '';
        return (
            <div
                key={idx}
                className={`winnerSelectionTablePositions__aside-link ${activeClass}`}
                onClick={() => {
                    setCurrentPosition(pos);
                }}
            >
                {pos.name}
            </div>
        );
    });

    const asideElement = (
        <aside className="winnerSelectionTablePositions__content-aside">
            <div className="winnerSelectionTablePositions__aside-header">Позиции</div>
            <div className="winnerSelectionTablePositions__aside-inner">
                <nav className="winnerSelectionTablePositions__aside-nav" aria-label="Секции">
                    {asideItemsElement}
                </nav>
            </div>
        </aside>
    );

    return (
        <div className="winnerSelectionTablePositions-wrapper">
            {errorText ? (
                <Hint icon="info" title={`Ошибка загрузки: ${errorText}`} variant="red" maxWidth="100%" />
            ) : null}
            {positions !== undefined ? (
                positions.length === 0 ? (
                    <div className="winnerSelectionTablePositions__empty">
                        <h1>{settings?.emptyTextHeader}</h1>
                        <p>{settings?.emptyText}</p>
                    </div>
                ) : (
                    <>
                        <div className="winnerSelectionTablePositions__content">{positionContentElement}</div>
                        <div className="winnerSelectionTablePositions__aside">{asideElement}</div>
                    </>
                )
            ) : (
                loaderElement
            )}
            {showFullScreen && currentPosition
                ? createPortal(
                      <div className={'winnerSelectionTablePositions__fullScreenItem'}>
                          <div className="winnerSelectionTablePositions__position-header">
                              <div className="winnerSelectionTablePositions__position-header-text">
                                  {getPositionHeader(currentPosition)}
                              </div>
                              <Button
                                  className="winnerSelectionTablePositions__exit-fullscreen-btn"
                                  buttonType="secondary"
                                  size="s"
                                  startAdornment={<MdClose size="16" />}
                                  onClick={() => {
                                      setShowFullscreen(false);
                                  }}
                              >
                                  Закрыть
                              </Button>
                          </div>
                          <WinnerSelectionTableDemand
                              isEdit={isEdit}
                              isNew={isNew}
                              fields={fields}
                              component={component}
                              formMethods={formMethods}
                              docId={currentPosition.key}
                          />
                      </div>,
                      document.body,
                  )
                : null}
        </div>
    );
};

export default WinnerSelectionTablePositions;
