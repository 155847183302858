import 'focus-visible';
import './Accordion.scss';

import React, { KeyboardEventHandler, ReactNode, useState } from 'react';
import classNames from 'classnames';
import AccordionItem from './AccordionItem';

export type IAccordion = {
    /** Заголовок */
    title: ReactNode;
    /** Контент */
    content: ReactNode;
    /** Открыт по умолчанию
     * @default false
     */
    defaultOpened?: boolean;
    /** Заблокирован выбор или нет
     * @default false
     */
    disabled?: boolean;
};

export interface IAccordionProps {
    /** Массив данных */
    data: IAccordion[];
    /** Позволяет разворачивать сразу несколько элементов
     * @default false
     */
    expanded?: boolean;
    /** Эффект наведения на элемент
     * @default true
     */
    hoverable?: boolean;

    handleAccordOpen?: (index: number, isOpened: boolean) => void;
}

// TODO: Проверить правильность использования role='treeitem'
const Accordion: React.FC<IAccordionProps> = ({
    data = [],
    expanded = false,
    hoverable = true,
    handleAccordOpen = undefined,
}: IAccordionProps) => {
    const getDefaultOpenedIndexes = () =>
        data.reduce((acc: number[], curr: IAccordion, index: number) => {
            if (curr.defaultOpened) {
                if (expanded || !acc.length) {
                    acc.push(index);
                }

                return acc;
            }

            return acc;
        }, []);

    const [openedIndexes, setOpenedIndexes] = useState<number[]>(getDefaultOpenedIndexes);

    const isOpened = (index: number) => openedIndexes.findIndex((i) => i === index) !== -1;

    const handleOpen = (index: number) => () => {
        const opened = isOpened(index);

        if (expanded) {
            if (opened) {
                setOpenedIndexes((prevIndex) => prevIndex.filter((i: number) => i !== index));
            } else {
                setOpenedIndexes((prevIndex) => [...prevIndex, index]);
            }
        } else {
            setOpenedIndexes(opened ? [] : [index]);
        }

        if (handleAccordOpen) {
            handleAccordOpen(index, !opened);
        }
    };

    const handleKeyDown = (index: number): KeyboardEventHandler => {
        return (e) => {
            //if (e.code === 'Enter' || e.code === 'Space') {
            //  handleOpen(index)();
            //}
        };
    };

    return (
        <div className="rf-accordion" role="tree">
            {data.map((item: IAccordion, index: number) => {
                const opened = isOpened(index);

                return (
                    <div
                        key={index}
                        className={classNames(
                            'rf-accordion__item-wrap',
                            opened && 'rf-accordion__item-wrap--opened',
                            hoverable && 'rf-accordion__item-wrap--hoverable',
                            item.disabled && 'rf-accordion__item-wrap--disabled',
                        )}
                        tabIndex={item.disabled ? -1 : 0}
                        onKeyDown={handleKeyDown(index)}
                    >
                        <AccordionItem opened={opened} disabled={item.disabled} onClick={handleOpen(index)}>
                            {item.title}
                        </AccordionItem>

                        <div className="rf-accordion__panel">{item.content}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default Accordion;
