import React, { FC, MouseEvent, useEffect, useState } from 'react';
import './DocumentTabLabel.scss';
import { IDocumentData } from '@models/document/IDocumentData';
import { useTypedSelector } from '@hooks/useTypedSelector';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { IFieldElem } from '@models/IFormData';
import { FormulaManager } from '@utils/FormulaManager';
import classNames from 'classnames';
import { ITab } from '@/types';
import { handlerFieldWatch } from '@utils/documentUtils';

export interface IDocumentTab extends ITab {
    visibilityRules?: string;
    formMethods: UseFormReturn<IDocumentData>;
    fields: Record<string, IFieldElem>;
    includedFields: number[];
}

export interface IDocumentTabLabel {
    tab: IDocumentTab;
    index: number;
    selected: number;
    lastVisibleIndex: number;
    size: 'sm' | 'md';
    refTab: React.Ref<HTMLDivElement>;
    mouseHandler?: (e: MouseEvent) => void;
}

const DocumentTabLabel: FC<IDocumentTabLabel> = ({
    tab,
    index,
    selected,
    lastVisibleIndex,
    size,
    refTab,
    mouseHandler,
}: IDocumentTabLabel) => {
    const document = useTypedSelector((state) => state.document);
    let visibilityRules = tab.visibilityRules;
    const visibilityMng = new FormulaManager(visibilityRules!);
    visibilityMng.Init(tab.fields);
    const activated = React.useRef(false);
    const [visibility, setVisibility] = useState<boolean>(false);
    const [invalid, setInvalid] = useState<boolean>(false);
    const InitFormulas = async () => {
        let result = await visibilityMng.EvalFormulaValues(document.isEdit, document.isNew);
        if (activated.current) {
            setVisibility(result);
        }
    };
    useEffect(() => {
        activated.current = true;
        InitFormulas();
        return () => {
            activated.current = false;
        };
    }, []);
    const watchVisibility = useWatch({
        name: visibilityMng.GetWatchFields(),
    });

    useEffect(() => {
        handlerFieldWatch(watchVisibility, document.isEdit, document.isNew, visibilityMng, setVisibility, activated);
    }, [watchVisibility, document.isEdit, document.isNew, tab, tab.fields]);

    useEffect(() => {
        if (!document.isEdit && !document.isNew) {
            if (activated.current) {
                setInvalid(false);
            }
        } else {
            if (
                tab?.formMethods?.formState.errors &&
                tab.formMethods.formState.errors.fields &&
                tab.formMethods.formState.errors.fields.length &&
                tab.formMethods.formState.errors.fields.length > 0
            ) {
                let errorFields = Object.keys(tab.formMethods.formState.errors.fields);
                const found = errorFields.some((r) => tab.includedFields.indexOf(+r) >= 0);
                if (activated.current) {
                    setInvalid(found);
                }
            }
        }
    }, [tab?.formMethods?.formState, document.isEdit, document.isNew]);

    return visibility ? (
        <div
            key={index}
            className={classNames('rf-tabs__link', index > lastVisibleIndex && 'rf-tabs__link--hidden')}
            ref={refTab}
        >
            <button
                type="button"
                className={classNames(
                    'rf-tabs__button',
                    index === selected && !invalid && 'rf-tabs__button--active',
                    invalid && 'rf-tabs__button--invalid',
                    `rf-tabs__button--${size}`,
                )}
                disabled={tab.disabled}
                onClick={mouseHandler}
            >
                {!!tab.icon && <div className="rf-tabs__icon">{tab.icon}</div>}
                {!!tab.label && <div>{tab.label}</div>}
            </button>
        </div>
    ) : (
        <></>
    );
};

export default DocumentTabLabel;
