import { UseFormReturn, useWatch } from 'react-hook-form';
import { IDocumentUrl } from '@models/Forms/IForms';
import { handlerFieldDisplayWatch, handlerFieldWatch } from '@utils/documentUtils';
import './Url.scss';
import { IFieldElem } from '@models/IFormData';
import { FormulaManager } from '@utils/FormulaManager';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router';
import FieldWrapper from '../FieldWrapper/FieldWrapper';

export interface IUrlProps {
    url?: IDocumentUrl;
    formMethods: UseFormReturn<any>;
    isEdit: boolean;
    isNew: boolean;
    fields: Record<string, IFieldElem>;
}

const Url = ({ url, formMethods, isEdit, isNew, fields, ...props }: IUrlProps) => {
    let visibilityRules = url?.visibilityRules;

    const visibilityMng = new FormulaManager(visibilityRules!);
    const displayFormatMng = new FormulaManager(url?.displayFormat!);
    const hrefMng = new FormulaManager(url?.href!);
    displayFormatMng.Init(fields, formMethods);
    hrefMng.Init(fields, formMethods);
    visibilityMng.Init(fields, formMethods);

    const activated = React.useRef(false);
    const [visibility, setVisibility] = useState<boolean>(false);
    const [displayVal, setDisplayVal] = useState<string>();
    const [hrefVal, setHrefVal] = useState<string>();
    const InitFormulas = async () => {
        let result = await visibilityMng.EvalFormulaValues(isEdit, isNew);
        if (activated.current) {
            setVisibility(result);
        }
        let dataDisplay = await displayFormatMng.ReplaceFormulaValues(isEdit, isNew, undefined, true);
        if (activated.current) {
            setDisplayVal(dataDisplay ? dataDisplay : undefined);
        }
        let dataHref = await hrefMng.ReplaceFormulaValues(isEdit, isNew, undefined, true);
        if (activated.current) {
            setHrefVal(dataHref ? dataHref : undefined);
        }
    };

    useEffect(() => {
        activated.current = true;
        InitFormulas();
        return () => {
            activated.current = false;
        };
    }, []);

    const watchVisibility = useWatch({
        name: visibilityMng.GetWatchFields(),
    });

    useEffect(() => {
        handlerFieldWatch(watchVisibility, isEdit, isNew, visibilityMng, setVisibility, activated);
    }, [watchVisibility, isEdit, isNew]);

    const watchDisplayFormat = useWatch({
        name: displayFormatMng.GetWatchFields(),
    });

    const handlerDisplayVal = async (watch: any[]) => {
        let dataDisplay = await handlerFieldDisplayWatch(watch, isEdit, isNew, displayFormatMng);
        if (activated.current) {
            setDisplayVal(dataDisplay?.result);
        }
    };

    useEffect(() => {
        handlerDisplayVal(watchDisplayFormat);
    }, [watchDisplayFormat]);

    const watchHref = useWatch({
        name: hrefMng.GetWatchFields(),
    });

    const handlerHrefVal = async (watch: any[]) => {
        let dataHref = await handlerFieldDisplayWatch(watch, isEdit, isNew, hrefMng);
        if (activated.current) {
            setHrefVal(dataHref?.result);
        }
    };

    useEffect(() => {
        handlerHrefVal(watchHref);
    }, [watchHref]);

    return hrefVal && displayVal && visibility && url ? (
        <div className="form-field" data-testid={url.id ? `url-${url.id}` : undefined}>
            <FieldWrapper inLineFormat={url?.isValue} label={url?.name} title={url.viewTitle}>
                {url?.hrefIsDocId ? (
                    <Link target={url?.isBlank ? '_blank' : '_self'} to={`/document/${hrefVal}`}>
                        {displayVal}
                    </Link>
                ) : (
                    <a target={url?.isBlank ? '_blank' : '_self'} href={hrefVal}>
                        {displayVal}
                    </a>
                )}
            </FieldWrapper>
        </div>
    ) : (
        <></>
    );
};

export default Url;
