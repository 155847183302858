import './OfferTechAnalysis.scss';
import React, { useEffect, useMemo, useState } from 'react';
import Button from '@atoms/Button';
import classNames from 'classnames';
import { MdClose } from 'react-icons/md';
import Modal from '@atoms/Modal';
import { IOffer } from '@models/winnerSelection/ITableDemand';
import { Controller, useForm } from 'react-hook-form';
import { FormProviderControl, HookFormProvider, SelectControl, TextareaControl } from '@controls/index';
import { IOption } from '@/types';
import { WinnerSelectionService } from '@services/WinnerSelectionService';
import { sendNotification } from '@molecules/Notifications';
import ToolTipTitle from '@atoms/ToolTipTitle/ToolTipTitle';
import Hint from '@atoms/Hint';

export interface IOfferTechAnalysisProps {
    tableKey: string;
    isEdit: boolean;
    value: IOffer;
    demandId: string;
    onSubmit?: (value: IOfferTechAnalysisForm) => void;
    onClose?: () => void;
}

export interface IOfferTechAnalysisForm {
    acceptanceResult: number;
    acceptanceComment: string | null;
}

const OfferTechAnalysis = ({
    tableKey,
    isEdit,
    value,
    demandId,
    onSubmit,
    onClose,
    ...props
}: IOfferTechAnalysisProps) => {
    const [error, setError] = useState<string>();
    const methods = useForm<IOfferTechAnalysisForm>({
        mode: 'onBlur',
    });

    const { isSubmitting, isDirty } = methods.formState;

    useEffect(() => {
        const defaultValue: IOfferTechAnalysisForm = {
            acceptanceResult: value.acceptanceResult,
            acceptanceComment: value.acceptanceComment,
        };
        methods.reset(defaultValue);
    }, [value]);

    const onClickClose = (e: any) => {
        onClose && onClose();
    };

    const onFormSubmit = async (form: IOfferTechAnalysisForm) => {
        setError(undefined);
        return WinnerSelectionService.saveTableDemandOfferTechAnalysis(value.key, demandId, tableKey, form)
            .then((response) => {
                sendNotification({
                    message: 'Данные успешно сохранены',
                    variant: 'green',
                });
                onSubmit && onSubmit(form);
            })
            .catch((reason) => {
                setError(reason);
                console.log(reason);
            });
    };

    const results = useMemo(() => {
        return [
            { label: 'Соответствует', value: 1 },
            { label: 'Не соответствует', value: 0 },
        ] as IOption[];
    }, []);

    const errorLabel = error && (
        <div className="offer-ta__error">
            <Hint title={error} variant="red" />
        </div>
    );

    return (
        <Modal
            size="xl"
            header={
                <div className={classNames('offer-ta-modal__header')}>
                    <div className={classNames('offer-ta-modal__header-text')}>
                        <span>{`Технический анализ предложения №${value.number || ''} (${value.name})`}</span>
                    </div>
                    <div className={classNames('offer-ta-modal__header-close')}>
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="xs"
                            aria-label="Закрыть окно"
                            onClick={onClickClose}
                            startAdornment={<MdClose size="24" />}
                        />
                    </div>
                </div>
            }
        >
            {errorLabel}
            <FormProviderControl formMethods={methods} className="offer-ta__form" onSubmit={onFormSubmit}>
                <HookFormProvider controller={Controller}>
                    <div className="offer-ta__content">
                        <div className="offer-ta__row">
                            <SelectControl
                                label="Результат допуска"
                                name="acceptanceResult"
                                readOnly={true}
                                disabled={!isEdit}
                                highlightMenuItems={false}
                                options={results}
                                formState={methods.formState}
                                control={methods.control}
                                required={true}
                                rules={{ required: 'Обязательное поле' }}
                                setValue={(options) => {
                                    return options[0].value;
                                }}
                                getValue={(options: IOption[], value: any) => {
                                    return options.filter((x) => x.value === value);
                                }}
                            />
                        </div>
                        <div className="offer-ta__row">
                            <TextareaControl
                                label="Комментарий по соответствию"
                                name="acceptanceComment"
                                tooltip={
                                    <ToolTipTitle title="В случаях 'Не соответсвует' необходимо указать конкретные пункты ТЗ, комментарий будет внесен в обоснования критерия по соответствию ТЗ при наличии." />
                                }
                                readOnly={!isEdit}
                                disabled={!isEdit}
                                required={false}
                                autoResize={true}
                                //rules={{ required: 'Обязательное поле' }}
                                formState={methods.formState}
                                control={methods.control}
                            />
                        </div>
                    </div>
                    {isEdit ? (
                        <div className="offer-ta__footer">
                            <div className={classNames('offer-ta__row', 'offer-ta__submit-row')}>
                                <Button
                                    type="submit"
                                    preloader={isSubmitting}
                                    className="offer-ta__button offer-ta__save-button"
                                    disabled={isSubmitting || !isDirty}
                                >
                                    Сохранить
                                </Button>
                            </div>
                        </div>
                    ) : null}
                </HookFormProvider>
            </FormProviderControl>
        </Modal>
    );
};

export default OfferTechAnalysis;
