import { NavigateFunction } from 'react-router';
import { IBaseAction } from '@models/actions/IBaseAction';
import { Location } from 'history';
import React from 'react';
import { ModalSize } from '@atoms/Modal/Modal';

export interface IActionExecutor {
    run: (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        raiseVisualElement?: () => void,
        modalSize?: ModalSize | null,
    ) => void;
    visualElement?: () => React.ReactElement;

    navigate?: NavigateFunction;
    location?: Location;
}

export abstract class BaseActionExecutor implements IActionExecutor {
    abstract run: (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        raiseVisualElement?: () => void,
    ) => void;

    abstract runInternal: (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
    ) => void;
    abstract visualElement?: () => React.ReactElement;
    navigate?: NavigateFunction;
    location?: Location;
}
