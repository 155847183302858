import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AbookpickerControl } from '@controls/index';
import { IActivityAction, IBaseAction } from '@models/actions/IBaseAction';
import { IAddressBookData } from '@models/addressbook/IAddressBookData';
import { IActionExecutor } from '@utils/actions/IActionExecutor';
import ModalContainer, { IModalContainerProps } from '@molecules/ModalContainer/ModalContainer';
import { IAbookTab } from '@models/Forms/IForms';
import { ModalSize } from '@atoms/Modal/Modal';
import { ProcessExecutionService } from '@services/actions/ProcessExecutionService';

export class ChangeControllerExecutor implements IActionExecutor {
    private _modalProps?: IChangeControllerProps;

    run = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        raiseVisualElement?: () => void,
        modalSize?: ModalSize | null,
    ) => {
        let activityAction = action as IActivityAction;
        this._modalProps = {
            processId: objId,
            docId: activityAction?.docId ?? '-1',
            actionKey: action.key,
            title: action.options?.title ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'l',
            completeHandler: completeHandler,
        };
    };

    visualElement = () => {
        return this._modalProps ? <ChangeControllerModal {...this._modalProps} /> : <></>;
    };
}

interface IChangeControllerProps {
    processId: string;
    docId: string;
    actionKey: string;
    title: string;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    completeHandler?: (isSucceed: boolean) => void;
}

const ChangeControllerModal: React.FC<IChangeControllerProps> = (props: IChangeControllerProps) => {
    const [controllerDisplayName, setControllerDisplayName] = useState<string>('');
    const [errorText, setErrorText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();
    const activated = React.useRef(false);

    const serv = new ProcessExecutionService();

    let tabSettings = {
        name: 'Сотрудники',
        mode: 'list',
        child: 'Person',
        columns: [
            { key: 'fullName', name: 'Наименование', width: '', allowSort: true },
            { key: 'email', name: 'Email', width: '' },
        ],
        cache: JSON.stringify({
            name: 'Сотрудники',
            mode: 'list',
            child: 'Person',
        }),
    } as IAbookTab;

    const onSubmit = (form: IChangeControllerFormData) => {
        if (!form?.controller) {
            setErrorText('Не указан контролирующий');
            return;
        }

        setLoading(true);
        return serv
            .saveChangeController(props.processId, form?.controller)
            .then((res) => {
                setLoading(false);
                props.completeHandler && props.completeHandler(true);
            })
            .catch((err) => {
                setErrorText(err);
            })
            .finally(() => setLoading(false));
    };

    const formState = useForm<IChangeControllerFormData>({
        mode: 'onBlur', // "onChange"
    });

    useEffect(() => {
        setLoading(true);
        activated.current = true;

        serv.getChangeControllerModel(props.processId)
            .then((res) => {
                setControllerDisplayName(res.data.controllerFullName);
                formState.reset({ controller: res.data.controllerKey } as IChangeControllerFormData);
                setLoading(false);
            })
            .catch((err) => {
                setErrorText('Ошибка получения данных от сервера.');
            })
            .finally(() => setLoading(false));
        return () => {
            activated.current = false;
        };
    }, []);

    const modalContainerProps: IModalContainerProps = {
        header: props.title,
        size: props.modalSize,
        showOkButton: true,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
        errorText: errorText,
        formMethods: formState,
        onSubmit: onSubmit,
        okButtonDisabled: false,
    };

    return (
        <ModalContainer {...modalContainerProps}>
            <AbookpickerControl
                label="Контролирующий по маршруту"
                placeholder="Выберите пользователя из адресной книги"
                isMultiple={false}
                title="Контролирующий по маршруту"
                displayValue={controllerDisplayName}
                formValues={'{}'}
                name="controller"
                tabsSettings={[tabSettings]}
                required
                formState={formState.formState}
                showChips={false}
                control={formState.control}
                onSetValues={(value: IAddressBookData[]) => {
                    setControllerDisplayName(value[0].fullName);
                }}
            />
        </ModalContainer>
    );
};

interface IChangeControllerFormData {
    controller: string;
}
