import './FileViewerModal.scss';
import React, { useState } from 'react';
import classNames from 'classnames';
import { IDocument } from '@cyntler/react-doc-viewer/dist/models';
import Button from '@atoms/Button';
import { MdClose } from 'react-icons/md';
import Modal from '@atoms/Modal';
import { ModalSize } from '@atoms/Modal/Modal';
import FileViewer from '@molecules/FileViewer';
import DOMPurify from 'dompurify';

export interface IFileViewerModalProps {
    /** Файлы */
    docs: IDocument[];
    /** Размер модального окна */
    modalSize: ModalSize;
    /** Колбэк закрытия окна */
    onClose: () => void;
}

const InitialActiveDocumentNumber = 0;

const FileViewerModal = ({ docs, ...props }: IFileViewerModalProps) => {
    const [activeDocument, setActiveDocument] = useState<IDocument>(docs[InitialActiveDocumentNumber]);

    const modalHeader = (
        <div className={classNames('file-viewer-modal__header')}>
            <div className={classNames('file-viewer-modal__header-text')}>
                <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activeDocument.fileName ?? '') }}></span>
            </div>
            <div className={classNames('file-viewer-modal__header-close')}>
                <Button
                    buttonType="text"
                    textColor="neutral"
                    size="xs"
                    aria-label="Закрыть окно"
                    onClick={() => {
                        props.onClose();
                    }}
                    startAdornment={<MdClose size="24" />}
                />
            </div>
        </div>
    );

    return (
        <Modal className={classNames('file-viewer-modal')} size={props.modalSize} header={modalHeader}>
            <FileViewer
                docs={docs}
                initialActiveDocumentNumber={InitialActiveDocumentNumber}
                onDocumentChange={(document) => {
                    setActiveDocument(document);
                }}
            />
        </Modal>
    );
};

export default FileViewerModal;
