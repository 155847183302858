import React, { useEffect, useState } from 'react';
import Button from '@atoms/Button';
import Modal from '@atoms/Modal';
import { MdClose } from 'react-icons/md';
import { IBaseAction } from '@models/actions/IBaseAction';
import classNames from 'classnames';
import { IActionExecutor } from '@utils/actions/IActionExecutor';
import Preloader from '@atoms/Preloader';
import { AttachActionsService } from '@services/actions/AttachActionsService';
import InputFile from '@atoms/InputFile';
import { IFileData } from '@/types';
import './CreateAttachVersionExecutor.scss';
import { ModalSize } from '@atoms/Modal/Modal';
import { MAX_FILE_SIZE } from '@utils/constants';

export class CreateAttachVersionExecutor implements IActionExecutor {
    private _modalProps?: ICreateAttachVersionModalProps;

    run = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        raiseVisualElement?: () => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            docId: parentId ?? '',
            attachId: objId,
            rowData: rowData,
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'xl',
            completeHandler: completeHandler,
        };
    };

    visualElement = () => {
        return this._modalProps ? <CreateAttachVersionModal {...this._modalProps} /> : <div></div>;
    };
}

interface ICreateAttachVersionModalProps {
    docId: string;
    attachId: string;
    rowData: any;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    completeHandler?: (isSucceed: boolean) => void;
}

const CreateAttachVersionModal: React.FC<ICreateAttachVersionModalProps> = (props: ICreateAttachVersionModalProps) => {
    const [isBusy, setIsBusy] = useState<boolean>();
    const [selectedFiles, setSelectedFiles] = useState<IFileData[]>();
    const [fileName, setFileName] = useState<string>('');
    const [acceptExtension, setAcceptExtension] = useState<string>('*');

    useEffect(() => {
        let rowData = Array.isArray(props.rowData) ? props.rowData[0] : props.rowData;
        let fileName = rowData.fields['AttachmentFileName'] ?? rowData.fields['AttachmentTitle'] ?? '';
        let splitFileName = fileName.split('.');
        let ext: string;
        if (!splitFileName[splitFileName.length - 1]) {
            ext = '*';
        } else {
            ext = `.${splitFileName[splitFileName.length - 1]}`;
        }

        setFileName(fileName);
        setAcceptExtension(ext);
    }, [props.rowData]);

    const setFile = (file: IFileData[]) => {
        setSelectedFiles(file);
    };

    return (
        <Modal
            className={classNames('modal-dlg-container')}
            size={props.modalSize}
            header={
                <>
                    <div className={classNames('box')}>
                        <div>Создать версию</div>
                        <div className={classNames('left')}>
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xs"
                                aria-label="Закрыть окно"
                                onClick={() => {
                                    if (props.completeHandler) props.completeHandler(false);
                                }}
                                startAdornment={<MdClose size="24" />}
                            />
                        </div>
                    </div>
                </>
            }
        >
            {isBusy ? (
                <Preloader size="m" position="static" />
            ) : (
                <div>
                    <div>
                        <InputFile
                            className="CreateAttachVersion__SelectFilesInput"
                            setFile={setFile}
                            placeholder={`Выберите версию файла ${fileName}`}
                            maxSize={MAX_FILE_SIZE}
                            multiple={false}
                            accept={acceptExtension}
                        />
                    </div>

                    <div className="modal-dlg-buttons">
                        <div className={'left'}>
                            <Button
                                size="s"
                                aria-label={props.okButtonText}
                                disabled={!selectedFiles || selectedFiles.length === 0}
                                onClick={() => {
                                    setIsBusy(true);
                                    let service = new AttachActionsService(props.docId, '-1');
                                    service.createAttachVersion(selectedFiles?.[0].file, props.attachId).then(() => {
                                        setIsBusy(false);
                                        if (props.completeHandler) props.completeHandler(true);
                                    });
                                }}
                            >
                                {props.okButtonText}
                            </Button>

                            <Button
                                buttonType="light"
                                size="s"
                                aria-label={props.cancelButtonText}
                                onClick={() => {
                                    if (props.completeHandler) props.completeHandler(false);
                                }}
                            >
                                {props.cancelButtonText}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};
