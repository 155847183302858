import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AbookpickerControl, SelectControl, TextareaControl } from '@controls/index';
import { IActivityAction, IBaseAction } from '@models/actions/IBaseAction';
import { IAddressBookData } from '@models/addressbook/IAddressBookData';
import ModalContainer, { IModalContainerProps } from '@molecules/ModalContainer/ModalContainer';
import { IAbookOptions } from '@models/Forms/IAbookOptions';
import { getActivityIdFromCompositeId } from '@utils/helpers';
import { getABookFormValues } from '@utils/abookUtils';
import { IFormValues } from '@models/Forms/IForms';
import { ModalSize } from '@atoms/Modal/Modal';
import { ActivityBaseActionExecutor } from '@utils/actions/ActivityBaseActionExecutor';
import { IOption } from '@/types';
import { ActivityExecutionService } from '@services/actions/ActivityExecutionService';

export class ChangePerformerExecutor extends ActivityBaseActionExecutor {
    private _modalProps?: IChangePerformerProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        let activityAction = action as IActivityAction;
        this._modalProps = {
            activityId: getActivityIdFromCompositeId(objId),
            docId: activityAction?.docId ?? '-1',
            actionKey: action.key,
            title: action.options?.title ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'l',
            reasons: action.options?.reasons ?? [],
            addCustomReason: action.options?.addCustomReason ?? false,
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <ChangePerformerModal {...this._modalProps} /> : <></>;
    };
}

interface IChangePerformerProps {
    activityId: string;
    docId: string;
    actionKey: string;
    title: string;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    reasons: string[];
    addCustomReason: boolean;
    completeHandler?: (isSucceed: boolean) => void;
}

const ChangePerformerModal: React.FC<IChangePerformerProps> = (props: IChangePerformerProps) => {
    const [comment, setComment] = useState<string>();
    const [executor, setExecutor] = useState<IAddressBookData>();
    const [abookOptions, setAbookOptions] = useState<IAbookOptions>();
    const [errorText, setErrorText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();
    const [formValues, setFormValues] = useState<string>('{}');
    const activated = React.useRef(false);

    const serv = new ActivityExecutionService();

    const getFormValues = async (fValues?: IFormValues) => {
        const vals = await getABookFormValues(props.docId, fValues);
        if (activated.current && vals) {
            setFormValues(vals);
        }
    };

    const onSubmit = (form: IChangePerformerFormData) => {
        if (!form?.executor) {
            setErrorText('Не указан исполнитель');
            return;
        }

        setLoading(true);

        let reason = '';
        if (props.reasons) {
            const selectedItem = form.reason[0].value;
            if (selectedItem === -1) {
                reason = form.customReason || 'Прочее';
            } else {
                reason = props.reasons[selectedItem];
            }
        } else if (props.addCustomReason) {
            reason = form.customReason;
        }

        return serv
            .saveChangePerformer(props.actionKey, props.activityId, form.executor, reason)
            .then((res) => {
                setLoading(false);

                props.completeHandler && props.completeHandler(true);
            })
            .catch((err) => {
                setErrorText(err);
            })
            .finally(() => setLoading(false));
    };

    const formState = useForm<IChangePerformerFormData>({
        mode: 'onBlur', // "onChange"
    });

    useEffect(() => {
        setLoading(true);

        serv.getChangePerformerModel(props.actionKey, props.activityId)
            .then(async (res) => {
                setAbookOptions(res.data);
                await getFormValues(res.data?.formValues);
                setLoading(false);
            })
            .catch((err) => {
                setErrorText('Ошибка получения данных от сервера.');
            })
            .finally(() => setLoading(false));
        return () => {
            activated.current = false;
        };
    }, []);

    // -------------------------------------------------------------------------------------------------------------------

    const [showCustomReason, setShowCustomReason] = useState<boolean>(
        props.reasons.length === 0 && props.addCustomReason,
    );

    const reasonsOptions = useMemo(() => {
        const options = props.reasons.map((reason, index) => {
            return { label: reason, value: index } as IOption;
        });

        if (props.reasons.length > 0 && props.addCustomReason) {
            options.push({ label: 'Прочее', value: -1 });
        }

        return options;
    }, [props.reasons, props.addCustomReason]);

    // -------------------------------------------------------------------------------------------------------------------

    const modalContainerProps: IModalContainerProps = {
        header: props.title,
        size: props.modalSize,
        showOkButton: true,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        isBusy: loading,
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
        errorText: errorText,
        formMethods: formState,
        onSubmit: onSubmit,
        okButtonDisabled: false,
    };

    return (
        <ModalContainer {...modalContainerProps}>
            <AbookpickerControl
                label="Исполнитель"
                placeholder="Исполнитель"
                isMultiple={false}
                title="Исполнитель"
                displayValue={executor?.fullName ?? ''}
                formValues={formValues}
                name="executor"
                tabsSettings={abookOptions?.tabs}
                externalSearch={abookOptions?.externalSearch}
                required
                rules={{ required: 'Обязательное поле' }}
                formState={formState.formState}
                control={formState.control}
                showChips={false}
                onSetValues={(value: IAddressBookData[]) => {
                    setExecutor(value[0]);
                }}
            />
            {reasonsOptions.length > 0 ? (
                <SelectControl
                    label="Причина"
                    multiselect={false}
                    readOnly
                    required={true}
                    rules={{ required: 'Обязательное поле' }}
                    name="reason"
                    options={reasonsOptions}
                    preloader={loading}
                    formState={formState.formState}
                    control={formState.control}
                    defaultValue={[reasonsOptions[0]]}
                    onChangeVal={(value) => {
                        setShowCustomReason(value[0].value === -1);
                    }}
                />
            ) : null}
            {showCustomReason ? (
                <TextareaControl
                    label="Причина"
                    rows={6}
                    readOnly={false}
                    name="customReason"
                    formState={formState.formState}
                    control={formState.control}
                    onValueChange={(val: any) => {
                        setComment(val);
                    }}
                />
            ) : null}
        </ModalContainer>
    );
};

interface IChangePerformerFormData {
    executor: string;
    reason: IOption[];
    customReason: string;
}
