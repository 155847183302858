import React, { useEffect, useState } from 'react';
import Button from '@atoms/Button';
import Modal from '@atoms/Modal';
import { MdClose } from 'react-icons/md';
import { IBaseAction } from '@models/actions/IBaseAction';
import classNames from 'classnames';
import { DocBaseActionExecutor } from '@utils/actions/DocBaseActionExecutor';
import Preloader from '@atoms/Preloader';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import Hint from '@atoms/Hint';
import { FilesService } from '@services/FilesService';
import { ModalSize } from '@atoms/Modal/Modal';
import { ICreateReportModel } from '@models/document/actionsExecution/ICreateReportModel';
import { IOption } from '@/types';
import Select from '@atoms/Select';
import { FormControl } from '@controls/index';

export class CreateReportExecutor extends DocBaseActionExecutor {
    private _modalProps?: ICreateReportExecutorProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            docId: objId ?? '',
            actionKey: action.key ?? '',
            title: action.options?.title ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'xl',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <CreateReportExecutorModal {...this._modalProps} /> : <div></div>;
    };
}

interface ICreateReportExecutorProps {
    docId: string;
    actionKey: string;
    title: string;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    completeHandler?: (isSucceed: boolean) => void;
}

const CreateReportExecutorModal: React.FC<ICreateReportExecutorProps> = (props: ICreateReportExecutorProps) => {
    const [errorText, setErrorText] = useState<string>();
    const [isBusy, setIsBusy] = useState<boolean>();
    const [model, setModel] = useState<ICreateReportModel>();
    const [selectedFormatOption, setSelectedFormatOption] = useState<IOption>();
    const [formatOptions, setFormatOptions] = useState<IOption[]>();

    const service = new DocumentExecutionService(props.docId);
    const filesService = new FilesService();

    useEffect(() => {
        setIsBusy(true);
        service
            .getCreateReportModel(props.actionKey)
            .then((res) => {
                setModel(res.data);
                const reportFormats = res.data.reportFormats;
                if (reportFormats) {
                    const options = Object.keys(reportFormats).map((key) => {
                        return {
                            value: key,
                            label: reportFormats[key],
                        } as IOption;
                    });
                    if (options) {
                        setFormatOptions(options);
                        setSelectedFormatOption(options[0]);
                    }
                }
            })
            .catch((error) => setErrorText(error))
            .finally(() => {
                setIsBusy(false);
            });
    }, []);

    const onOkClick = (event: React.MouseEvent) => {
        setIsBusy(true);
        setErrorText(undefined);

        if (model) {
            filesService
                .downloadFile(
                    `documentExecution/downloadReportDoc/${props.docId}/${props.actionKey}/${
                        selectedFormatOption?.value ?? ''
                    }`,
                )
                .then(() => {
                    props.completeHandler && props.completeHandler(false);
                })
                .catch((reason) => {
                    setErrorText(reason);
                })
                .finally(() => {
                    setIsBusy(false);
                });
        } else {
            setErrorText('Не удалось получить модель данных.');
            setIsBusy(false);
        }
    };

    return (
        <Modal
            className={classNames('modal-dlg-container')}
            size={props.modalSize}
            header={
                <>
                    <div className={classNames('box')}>
                        <div>{props.title}</div>
                        <div className={classNames('left')}>
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xs"
                                aria-label="Закрыть окно"
                                onClick={() => {
                                    if (props.completeHandler) props.completeHandler(false);
                                }}
                                startAdornment={<MdClose size="24" />}
                            />
                        </div>
                    </div>
                </>
            }
        >
            {isBusy ? (
                <Preloader size="m" position="static" />
            ) : (
                <div>
                    {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" maxWidth={'100%'} />}

                    {model?.isReportFormatSelectable ? (
                        <FormControl name="format" label="Формат отчёта">
                            <Select
                                placeholder="Формат отчёта"
                                values={selectedFormatOption ? [selectedFormatOption] : []}
                                multiselect={false}
                                options={formatOptions ?? []}
                                readOnly
                                onChange={(option) => {
                                    setSelectedFormatOption(option[0]);
                                }}
                            />
                        </FormControl>
                    ) : null}

                    <div className="modal-dlg-buttons">
                        <div className={'left'}>
                            <Button size="s" aria-label={props.okButtonText} onClick={onOkClick}>
                                {props.okButtonText}
                            </Button>

                            <Button
                                buttonType="light"
                                size="s"
                                aria-label={props.cancelButtonText}
                                onClick={() => {
                                    if (props.completeHandler) props.completeHandler(false);
                                }}
                            >
                                {props.cancelButtonText}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};
