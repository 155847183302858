import React, { FC, ReactNode } from 'react';
import { FloatingPortal, Placement } from '@floating-ui/react';
import './Tooltip.scss';

export interface ITooltipContentProps {
    ref?: React.Ref<HTMLDivElement>;
    /** Содержимое tooltip */
    children: ReactNode | ReactNode[];
    /** Дополнительный класс */
    className?: string;
    /** Цвет тултипа */
    background?: 'black' | 'white' | 'transparent';
    /** Позиция и стили тултипа */
    style?: React.CSSProperties;
    /** Позиция тултипа */
    position?: Placement;
    /** Скрыть отступы */
    hidePadding?: boolean;
    /** Дополнительные свойства для тултипа */
    getFloatingProps: (userProps?: React.HTMLProps<HTMLElement>) => Record<string, unknown>;
}

// Using forwardRef to handle the ref provided by Floating UI
const TooltipContent: FC<ITooltipContentProps> = ({
    ref,
    children,
    className = '',
    background = 'white',
    style,
    position = 'top',
    hidePadding,
    getFloatingProps,
}) => {
    return (
        <FloatingPortal>
            <div
                ref={ref}
                style={style} // Apply Floating UI's calculated styles
                className={`rf-tooltip__content-wrapper ${className}`}
                data-position={position}
                {...getFloatingProps()} // Add Floating UI's event listeners and props
            >
                <div className={`rf-tooltip__content rf-tooltip__content--${background}`}>
                    <div className={`rf-tooltip__inner ${hidePadding ? 'rf-tooltip__inner--no-pudding' : ''}`}>
                        {children}
                    </div>
                </div>
            </div>
        </FloatingPortal>
    );
};

export default TooltipContent;
