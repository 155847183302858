import './OfferCritDetails.scss';
import React, { useEffect, useState } from 'react';
import Button from '@atoms/Button';
import classNames from 'classnames';
import { MdClose } from 'react-icons/md';
import Modal from '@atoms/Modal';
import { IOfferCrit, ITableDemandItem } from '@models/winnerSelection/ITableDemand';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { FormProviderControl, HookFormProvider, InputNumberControl, TextareaControl } from '@controls/index';
import { WinnerSelectionService } from '@services/WinnerSelectionService';
import { sendNotification } from '@molecules/Notifications';
import Hint from '@atoms/Hint';
import { round } from 'mathjs';

export interface IOfferCritDetailsProps {
    tableKey: string;
    isEdit: boolean;
    demandId: string;
    offerId: string;
    disabledHint?: string;
    editButtonName?: string | null;
    value: IOfferCrit;
    rowData: ITableDemandItem;
    onSubmit?: (value: IOfferCritDetailsForm) => void;
}

export interface IOfferCritDetailsForm {
    critId: string;
    weight: number | null;
    offerCrit: IOfferCrit;
}

const OfferCritDetails = ({
    tableKey,
    isEdit,
    disabledHint,
    editButtonName,
    demandId,
    offerId,
    value,
    rowData,
    onSubmit,
    ...props
}: IOfferCritDetailsProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const methods = useForm<IOfferCritDetailsForm>({
        mode: 'onBlur',
    });

    const { isSubmitting, isDirty } = methods.formState;

    const watchCalcValues = useWatch({ name: ['offerCrit.score', 'weight'], control: methods.control });

    useEffect(() => {
        let values = methods.getValues();
        if (values.offerCrit && values.offerCrit.score !== null && values.weight !== null) {
            // Считаем новое значение рейтинга
            let newRating = round(values.offerCrit.score * values.weight, 4);
            methods.setValue('offerCrit.rating', newRating, { shouldDirty: true });
        }
    }, [watchCalcValues]);

    useEffect(() => {
        const defaultValue: IOfferCritDetailsForm = {
            offerCrit: value,
            critId: rowData.critId,
            weight: rowData.critWeight,
        };
        methods.reset(defaultValue);
    }, [value, showModal]);

    const onClick = (e: any) => {
        setShowModal(!showModal);
    };

    const onFormSubmit = async (form: IOfferCritDetailsForm) => {
        setError(undefined);
        return WinnerSelectionService.saveTableDemandOfferCrit(offerId, demandId, tableKey, form)
            .then((response) => {
                sendNotification({
                    message: 'Данные успешно сохранены',
                    variant: 'green',
                });
                setShowModal(!showModal);
                onSubmit && onSubmit(form);
            })
            .catch((reason) => {
                setError(reason);
                console.log(reason);
            });
    };

    const errorLabel = error && (
        <div className="offer-crit-details__error">
            <Hint title={error} variant="red" />
        </div>
    );

    return (
        <>
            <div className={classNames('offer-crit-details')} onClick={onClick}>
                <Button type="button" aria-label="Показать детали" buttonType={'secondary'} onClick={onClick}>
                    {editButtonName ?? 'Показать детали'}
                </Button>
            </div>
            {showModal ? (
                <Modal
                    size="xl"
                    header={
                        <div className={classNames('offer-crit-details-modal__header')}>
                            <div className={classNames('offer-crit-details-modal__header-text')}>
                                <span>{`${rowData.critName}`}</span>
                            </div>
                            <div className={classNames('offer-crit-details-modal__header-close')}>
                                <Button
                                    buttonType="text"
                                    textColor="neutral"
                                    size="xs"
                                    aria-label="Закрыть окно"
                                    onClick={() => {
                                        setShowModal(false);
                                    }}
                                    startAdornment={<MdClose size="24" />}
                                />
                            </div>
                        </div>
                    }
                >
                    {errorLabel}
                    {!isEdit && disabledHint && (
                        <div className={'offer-crit-details__hint'}>
                            <Hint icon="info" title={disabledHint} variant="yellow" maxWidth="100%" />
                        </div>
                    )}

                    <FormProviderControl
                        formMethods={methods}
                        className="offer-crit-details__form"
                        onSubmit={onFormSubmit}
                    >
                        <HookFormProvider controller={Controller}>
                            <div className="offer-crit-details__content">
                                {!rowData.isInfoCrit ? (
                                    <div className="offer-crit-details__row">
                                        <div className="offer-crit-details__column">
                                            <InputNumberControl
                                                label="Балл"
                                                name="offerCrit.score"
                                                required={true}
                                                readOnly={!isEdit}
                                                disabled={!isEdit}
                                                floatPoints={4}
                                                min={0}
                                                formState={methods.formState}
                                                control={methods.control}
                                                rules={{ required: 'Обязательное поле' }}
                                            />
                                            <span className={'offer-crit-details__formula-text'}>×</span>
                                            <InputNumberControl
                                                label="Вес"
                                                name="weight"
                                                required={true}
                                                readOnly={true}
                                                disabled={!isEdit}
                                                floatPoints={4}
                                                formState={methods.formState}
                                                control={methods.control}
                                                rules={{ required: 'Обязательное поле' }}
                                            />
                                            <span className={'offer-crit-details__formula-text'}>=</span>
                                            <InputNumberControl
                                                label="Рейтинг"
                                                name="offerCrit.rating"
                                                required={true}
                                                readOnly={true}
                                                disabled={!isEdit}
                                                floatPoints={4}
                                                formState={methods.formState}
                                                control={methods.control}
                                                rules={{ required: 'Обязательное поле' }}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                                <div className="offer-crit-details__row">
                                    <TextareaControl
                                        label={rowData.isInfoCrit ? 'Комментарий' : 'Обоснование'}
                                        name="offerCrit.description"
                                        readOnly={!isEdit}
                                        disabled={!isEdit}
                                        required={false}
                                        autoResize={true}
                                        //rules={{ required: 'Обязательное поле' }}
                                        formState={methods.formState}
                                        control={methods.control}
                                    />
                                </div>
                            </div>

                            {isEdit ? (
                                <div className="offer-crit-details__footer">
                                    <div
                                        className={classNames(
                                            'offer-crit-details__row',
                                            'offer-crit-details__submit-row',
                                        )}
                                    >
                                        <Button
                                            type="submit"
                                            preloader={isSubmitting}
                                            className="offer-crit-details__button offer-crit-details__save-button"
                                            disabled={isSubmitting || !isDirty}
                                        >
                                            Сохранить
                                        </Button>
                                    </div>
                                </div>
                            ) : null}
                        </HookFormProvider>
                    </FormProviderControl>
                </Modal>
            ) : null}
        </>
    );
};

export default OfferCritDetails;
