import './OfferRate.scss';
import React, { useEffect, useMemo, useState } from 'react';
import Button from '@atoms/Button';
import classNames from 'classnames';
import { MdClose } from 'react-icons/md';
import Modal from '@atoms/Modal';
import { IOffer } from '@models/winnerSelection/ITableDemand';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { FormProviderControl, HookFormProvider, SelectControl } from '@controls/index';
import { IOption } from '@/types';
import { WinnerSelectionService } from '@services/WinnerSelectionService';
import { sendNotification } from '@molecules/Notifications';
import Hint from '@atoms/Hint';

export interface IOfferRateProps {
    tableKey: string;
    isEdit: boolean;
    value: IOffer;
    demandId: string;
    onSubmit?: (value: IOfferRateForm) => void;
    onClose?: () => void;
}

export interface IOfferRateForm {
    result: string | null;
    rank: string | null;
}

const OfferRate = ({ tableKey, isEdit, value, demandId, onSubmit, onClose, ...props }: IOfferRateProps) => {
    const [error, setError] = useState<string>();
    const methods = useForm<IOfferRateForm>({
        mode: 'onBlur',
    });

    const { isSubmitting, isDirty } = methods.formState;

    const watchRank = useWatch({ name: 'rank', control: methods.control });

    useEffect(() => {
        const defaultValue: IOfferRateForm = {
            result: value.result,
            rank: value.rank,
        };
        methods.reset(defaultValue);
    }, [value]);

    useEffect(() => {
        if (isDirty) {
            methods.setValue('result', null, { shouldDirty: true });
        }
    }, [watchRank]);

    const onClickClose = (e: any) => {
        onClose && onClose();
    };

    const onFormSubmit = async (form: IOfferRateForm) => {
        setError(undefined);
        return WinnerSelectionService.saveTableDemandOfferRate(value.key, demandId, tableKey, form)
            .then((response) => {
                sendNotification({
                    message: 'Данные успешно сохранены',
                    variant: 'green',
                });
                onSubmit && onSubmit(form);
            })
            .catch((reason) => {
                setError(reason);
                console.log(reason);
            });
    };

    const ranks = useMemo(() => {
        let ranks = [{ label: '', value: null }];
        ranks = ranks.concat(
            [...Array(10).keys()].map((val) => {
                return { label: `${val + 1}`, value: `${val + 1}` } as IOption;
            }),
        );

        return ranks;
    }, []);

    const resultsRank1 = useMemo(() => {
        return [
            { label: '', value: null },
            { label: 'Основной', value: 'Основной' },
        ] as IOption[];
    }, []);

    const resultsRank2 = useMemo(() => {
        return [
            { label: '', value: null },
            { label: 'Резервный', value: 'Резервный' },
            { label: 'Одобренный после теста', value: 'Одобренный после теста' },
        ] as IOption[];
    }, []);

    const errorLabel = error && (
        <div className="offer-rate__error">
            <Hint title={error} variant="red" />
        </div>
    );

    return (
        <Modal
            size="m"
            header={
                <div className={classNames('offer-rate-modal__header')}>
                    <div className={classNames('offer-rate-modal__header-text')}>
                        <span>{`${value.name} (предложение №${value.number || ''})`}</span>
                    </div>
                    <div className={classNames('offer-rate-modal__header-close')}>
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="xs"
                            aria-label="Закрыть окно"
                            onClick={onClickClose}
                            startAdornment={<MdClose size="24" />}
                        />
                    </div>
                </div>
            }
        >
            {errorLabel}
            <FormProviderControl formMethods={methods} className="offer-rate__form" onSubmit={onFormSubmit}>
                <HookFormProvider controller={Controller}>
                    <div className="offer-rate__content">
                        <div className="offer-rate__row">
                            <SelectControl
                                label={'Место'}
                                name="rank"
                                readOnly={true}
                                disabled={!isEdit}
                                highlightMenuItems={false}
                                options={ranks}
                                formState={methods.formState}
                                control={methods.control}
                                setValue={(options) => {
                                    return options[0].value;
                                }}
                                getValue={(options: IOption[], value: any) => {
                                    return options.filter((x) => x.value === value);
                                }}
                            />
                            {watchRank === '1' || watchRank === '2' ? (
                                <SelectControl
                                    label={'Результат'}
                                    name="result"
                                    readOnly={true}
                                    disabled={!isEdit}
                                    highlightMenuItems={false}
                                    options={watchRank === '1' ? resultsRank1 : resultsRank2}
                                    formState={methods.formState}
                                    control={methods.control}
                                    setValue={(options) => {
                                        return options[0].value;
                                    }}
                                    getValue={(options: IOption[], value: any) => {
                                        return options.filter((x) => x.value === value);
                                    }}
                                />
                            ) : null}
                        </div>
                    </div>
                    {isEdit ? (
                        <div className="offer-rate__footer">
                            <div className={classNames('offer-rate__row', 'offer-rate__submit-row')}>
                                <Button
                                    type="submit"
                                    preloader={isSubmitting}
                                    className="offer-rate__button offer-rate__save-button"
                                    disabled={isSubmitting || !isDirty}
                                >
                                    Сохранить
                                </Button>
                            </div>
                        </div>
                    ) : null}
                </HookFormProvider>
            </FormProviderControl>
        </Modal>
    );
};

export default OfferRate;
