import React, { FC, useEffect, useState } from 'react';
import { useTypedSelector } from '@hooks/useTypedSelector';
import { useActions } from '@hooks/useActions';
import './Folders.scss';
import Button from '@atoms/Button';
import Tree from '@atoms/Tree';
import { MdAdd, MdDescription, MdHome, MdInsertChart, MdLibraryBooks, MdWork } from 'react-icons/md';
import { IListElement, ITreeOption } from '@/types';
import { useLocation, useNavigate, useSearchParams } from 'react-router';
import Preloader from '@atoms/Preloader';
import { DocumentActionsService } from '@services/actions/DocumentActionsService';
import { LocationTypes } from '@models/actions/LocationTypes';
import { IBaseAction } from '@models/actions/IBaseAction';
import { DocActionsExecFactory } from '@utils/actions/DocActionsExecFactory';
import { IActionExecutor } from '@utils/actions/IActionExecutor';
import Menu from '@atoms/Menu';
import { NoActionsItem } from '@models/actions/ActionConstants';
import PlanningMenuActionsControl from '@controls/PlanningMenuActionsControl';
import { FoldersService } from '@/services/FoldersService';
import logo from '@images/logo.svg';
import { ReactSVG } from 'react-svg';

export interface IFoldersProps {
    /** сворачивание */
    isOpen: boolean;
}

const Folders: FC<IFoldersProps> = ({ isOpen = true }: IFoldersProps) => {
    const mainLayout = useTypedSelector((state) => state.mainLayout);
    const auth = useTypedSelector((state) => state.auth);
    const { toggleMenu } = useActions();
    const [isCreateDocActionsLoading, setIsCreateDocActionsLoading] = useState<boolean>();
    const [createDocActions, setCreateDocActions] = useState<IListElement[]>([]);
    const [actionExecutor, setActionExecutor] = useState<IActionExecutor>();
    const [searchParams, setSearchParams] = useSearchParams();
    const [menuBarActions, setMenuBarActions] = useState<IBaseAction[]>([]);
    const [list, setList] = useState<ITreeOption[]>([]);

    //const folders = useTypedSelector((state) => state.folders);
    const location = useLocation();
    const navigate = useNavigate();
    // const { fetchTasks, fetchReports, fetchDocuments, fetchDictionaries } = useActions();

    const searchType = searchParams.get('searchType');
    const iconExtensions = ['png', 'svg', 'jpg'];

    const getIcon = (icon: any) => {
        let parts = icon.split('.');
        let path = logo;

        if (parts.length > 1 && iconExtensions.some((x) => x.trim().toLowerCase() == parts[1].trim().toLowerCase())) {
            let logoNAme = path.substring(path.indexOf('logo'));
            path = path.replace(logoNAme, icon);
            if (parts[1].trim().toLowerCase() === 'svg') {
                return <ReactSVG src={path} />;
            } else {
                return <img src={path} />;
            }
        } else {
            switch (icon.trim().toLowerCase()) {
                case 'home':
                    return <MdHome size="22" />;
                    break;
                case 'tasks':
                    return <MdWork size="20" />;
                    break;
                case 'docs':
                    return <MdDescription size="20" />;
                    break;
                case 'dicts':
                    return <MdLibraryBooks size="20" />;
                    break;
                case 'reports':
                    return <MdInsertChart size="20" />;
                    break;
                default:
                    return <></>;
                    break;
            }
        }
    };

    useEffect(() => {
        FoldersService.root().then((res) => {
            const data: ITreeOption[] = [...res.data];
            data.forEach((item) => {
                item.icon = item.icon && getIcon(item.icon);
            });

            setList(data);
        });
    }, []);

    const lazyLoadItems = async (o: ITreeOption) => {
        let result = [] as ITreeOption[];
        if (o.hasChildren && (o.children == undefined || o.children.length == 0) && o.value) {
            let parts = o.value.split('/');
            let response: any;
            switch (parts[0].toLowerCase().trim()) {
                case 'tasks':
                    response = await FoldersService.tasks(parts[1]);
                    break;
                case 'documents':
                    response = await FoldersService.documents(parts[1]);
                    break;
                case 'dictionaries':
                    response = await FoldersService.dictionaries(parts[1]);
                    break;
                case 'reports':
                    response = await FoldersService.reports(parts[1]);
            }
            result = response.data;
        }
        return result;
    };

    const openClass = isOpen ? 'rf-folders--open' : 'rf-folders--close';

    // -------------------------------------------------------------------------------------------------------------------

    useEffect(() => {
        DocumentActionsService.fetchActionsByLocation(undefined, LocationTypes.PlanningMenuBar, searchType).then(
            (res) => {
                setMenuBarActions(res.data);
            },
        );
    }, [searchType]);

    // -------------------------------------------------------------------------------------------------------------------

    const onCreateDocActionClick = (act: IBaseAction) => {
        let executor = DocActionsExecFactory.GetActionExecutor(act);
        if (!executor) throw new Error(`Не обнаружен executor для действия ${act.type}`);
        setActionExecutor(executor);
        executor.navigate = navigate;
        executor.location = location;
        executor.run(
            '',
            undefined,
            act,
            undefined,
            (succeed) => {
                setActionExecutor(undefined);
            },
            () => {
                setActionExecutor(undefined);
                setActionExecutor(executor);
            },
        );
    };

    const onCreateDocActionsOpening = () => {
        if (!isCreateDocActionsLoading) {
            setIsCreateDocActionsLoading(true);

            DocumentActionsService.fetchActionsByLocation(undefined, LocationTypes.PlanningMenu, searchType)
                .then((res) => {
                    let actionItems = res.data.map((act: IBaseAction, i: number) => {
                        return {
                            label: act.displayName,
                            value: i.toString(),
                            handler: () => onCreateDocActionClick(act),
                            showOpenInNewTabButton: act.options?.showOpenInNewTabButton ?? false,
                            newTabHandler: () => {
                                let newAction = { ...act };
                                let options = newAction.options ? { ...newAction.options } : {};
                                options.newTab = true;
                                newAction.options = options;
                                onCreateDocActionClick(newAction);
                            },
                        } as IListElement;
                    });
                    if (actionItems.length == 0) actionItems = [NoActionsItem];
                    setCreateDocActions(actionItems);
                })
                .finally(() => {
                    setIsCreateDocActionsLoading(false);
                });
        }
    };

    const onClickTree = (e: ITreeOption) => {
        if (!mainLayout.isMenuOpen) toggleMenu(true);
    };

    return auth.isAuth ? (
        <div className={`rf-folders  ${openClass}`}>
            {actionExecutor?.visualElement && actionExecutor?.visualElement()}
            <Menu list={createDocActions} position="bottom-start">
                <Button
                    size="s"
                    title={'Создать документ'}
                    startAdornment={
                        isCreateDocActionsLoading ? (
                            <div className="busy-loader">
                                <Preloader size="s2" variant="white" position="inline" />
                            </div>
                        ) : (
                            <MdAdd size="20" />
                        )
                    }
                    className="doc-button button-margin full-width-button"
                    onClick={() => {
                        onCreateDocActionsOpening();
                    }}
                >
                    Создать документ
                </Button>
            </Menu>
            <PlanningMenuActionsControl actions={menuBarActions} />
            <Tree lazyLoad={lazyLoadItems} id="folders" open={false} list={list} onClick={onClickTree} />
        </div>
    ) : (
        <></>
    );
};

export default Folders;
