import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { IUser } from '@models/IUser';
import { IUserAvatar } from '@models/IUserAvatar';
import { IChangePasswordData } from '@organisms/ProfileSecurity/ProfileSecurity';
import { IChangeUserProfileData } from '@organisms/UserProfile/UserProfile';
import { IAddressBookItem } from '@/models/addressbook/IAddressBookItem';
import { INewUser, IUsersManagementData } from '@organisms/UsersManagement/UsersManagement';
import {
    IApprovalAssistantsData,
    IChangeApprovalAssistantsData,
} from '@organisms/ApprovalAssistants/ApprovalAssistants';
import { IUserAssistants } from '@/models/IUserAssistants';

export class UserService {
    static async getCurrentUser(): Promise<AxiosResponse<IUser>> {
        return $api.get<IUser>(`/user/profile`);
    }

    static async getCurrentUserAvatar(): Promise<AxiosResponse<IUserAvatar>> {
        return $api.get<IUserAvatar>(`/user/avatar`);
    }

    static async changePassword(formData: IChangePasswordData): Promise<AxiosResponse> {
        return $api.post(`/user/ChangePassword`, formData);
    }

    static async changeProfile(formData: IChangeUserProfileData): Promise<AxiosResponse> {
        return $api.post(`/user/ChangeProfile`, formData);
    }

    static async getDepsToSelect(): Promise<AxiosResponse<IAddressBookItem[]>> {
        return $api.get<IAddressBookItem[]>(`/addressBook/getDepartments`);
    }

    static async setUserDepartment(depId: string): Promise<AxiosResponse> {
        let data = new FormData();
        data.append('depId', depId);
        return $api.post<IAddressBookItem[]>(`/addressBook/setUserDepartment`, data);
    }

    static async fetchUsersForActivation(controller: AbortController): Promise<AxiosResponse<INewUser[]>> {
        return $api.get<INewUser[]>(`/user/UsersForActivation`, { signal: controller.signal });
    }

    static async approveActivation(formData: IUsersManagementData): Promise<AxiosResponse> {
        return $api.post(`/user/ApproveActivation`, formData);
    }

    static async lockUser(formData: IUsersManagementData): Promise<AxiosResponse> {
        return $api.post(`/user/LockUser`, formData);
    }

    static async unlockUser(formData: IUsersManagementData): Promise<AxiosResponse> {
        return $api.post(`/user/UnlockUser`, formData);
    }

    static async setAssistant(formData: IUserAssistants): Promise<AxiosResponse> {
        return $api.post(`/user/Assistants`, formData);
    }

    static async getAssistant(): Promise<AxiosResponse<IUserAssistants>> {
        return $api.get<IUserAssistants>(`/user/Assistants`);
    }

    static async rejectActivation(formData: IUsersManagementData): Promise<AxiosResponse> {
        return $api.post(`/user/RejectActivation`, formData);
    }

    static async fetchApprovalAssistants(controller: AbortController): Promise<AxiosResponse<IApprovalAssistantsData>> {
        return $api.get<IApprovalAssistantsData>(`/user/ApprovalAssistants`, { signal: controller.signal });
    }

    static async saveApprovalAssistants(formData: IChangeApprovalAssistantsData): Promise<AxiosResponse> {
        return $api.post(`/user/ApprovalAssistants`, formData);
    }

    static async deleteAccount(): Promise<AxiosResponse> {
        return $api.post(`/user/DeleteAccount`);
    }
}
