import React, { KeyboardEvent, ReactNode, useCallback, useMemo, useRef } from 'react';
import InfiniteScroll, { Props as IInfiniteScrollProps } from 'react-infinite-scroll-component';
import { IOption } from '@/types';
import Checkbox from '../Checkbox/Checkbox';
import classNames from 'classnames';
import Preloader from '../Preloader';
import { MenuVariantSize } from './Select.types';
import useForkRef from '@hooks/useForkRef';
import MenuItem from './Select.MenuItem';

interface ISelectMenuProps {
    /**
     * Ссылка на элемент
     */
    ref?: React.Ref<HTMLUListElement>;
    options: IOption[];
    selected: IOption[];
    multiselect: boolean;
    selectId: number;
    menuVariantSize: MenuVariantSize;
    isAsync?: boolean;
    infinityScrollProps?: Omit<IInfiniteScrollProps, 'children' | 'next' | 'scrollableTarget' | 'loader'>;
    query: string;
    preloader: boolean;
    onOptionClick: (option: IOption) => void;
    onSearch?: (query: string, isPagination?: boolean) => void;
    onClose: () => void;
    onScroll?: (e: React.UIEvent) => void;
}

const SelectMenu: React.FC<ISelectMenuProps> = ({
    ref,
    options,
    selected,
    multiselect,
    selectId,
    menuVariantSize,
    isAsync,
    infinityScrollProps,
    query,
    preloader,
    onOptionClick,
    onSearch,
    onClose,
    onScroll,
}) => {
    const hasInfinityScroll = typeof onSearch === 'function';
    const optionRef = useRef<IOption | null>(null);

    const makeLazyFetch = useCallback(() => {
        if (onSearch && isAsync) {
            return () => onSearch(query, isAsync);
        }

        return () => undefined;
    }, [onSearch, isAsync, query]);

    const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
        const option = optionRef.current;

        if (event.code === 'Space') {
            event.preventDefault();

            if (option && multiselect) {
                onOptionClick(option);
            }
        }

        if (event.code === 'Tab' || event.key === 'Escape') {
            event.preventDefault();
            onClose();
        }

        if (event.code === 'Enter') {
            event.preventDefault();

            if (option) {
                onOptionClick(option);

                if (multiselect) {
                    onClose();
                }
            }
        }

        const list = getList();

        if (!list) {
            return;
        }

        if (event.code === 'ArrowDown') {
            event.preventDefault();
            moveFocus(list, nextItem);
        }

        if (event.code === 'ArrowUp') {
            event.preventDefault();
            moveFocus(list, previousItem);
        }
    };

    const loader = (
        <div className="rf-select__preloader">
            <Preloader size="m" />
        </div>
    );

    const selectedMap = useMemo((): Record<string, boolean> => {
        return selected.reduce((acc: Record<string, boolean>, o: IOption) => {
            acc[o.value] = true;
            return acc;
        }, {});
    }, [selected]);

    const firstIndex = options.findIndex((option) => !option.disabled);

    const listJSX = options.map((option, index) => {
        const active = selectedMap[option.value] || false;

        const handleChange = (event: React.MouseEvent | React.ChangeEvent) => {
            event.stopPropagation();
            onOptionClick(option);
        };

        const handleFocus = () => {
            optionRef.current = option;
        };

        let label: ReactNode = option.label;

        // TODO: думаю это можно вынести в отдельный компонент (Highlighter)
        if (query) {
            const indexStart = option.label.toLowerCase().indexOf(query.toLowerCase());

            if (indexStart > -1) {
                const indexEnd = indexStart + query.length - 1;
                let left = '';
                let highlighted = '';
                let right = '';

                for (let i = 0; i < option.label.length; i++) {
                    if (i < indexStart) {
                        left += option.label[i];
                        continue;
                    }

                    if (i >= indexStart && i <= indexEnd) {
                        highlighted += option.label[i];
                        continue;
                    }

                    right += option.label[i];
                }

                label = (
                    <>
                        {left}
                        <span title={highlighted} className="rf-select__list-element--query">
                            {highlighted}
                        </span>
                        {right}
                    </>
                );
            }
        }

        return (
            <MenuItem key={index} autoFocus={active} option={option} active={active} onFocus={handleFocus}>
                {multiselect && !option.disabled ? (
                    <Checkbox
                        titleAtt={option.label}
                        label={label}
                        checked={active}
                        onChange={handleChange}
                        fullWidth
                    />
                ) : (
                    <button
                        tabIndex={-1}
                        title={option.label}
                        className={
                            option.disabled ? 'rf-select__list-element-single-left' : 'rf-select__list-element-single'
                        }
                        onClick={handleChange}
                    >
                        {label}
                    </button>
                )}
            </MenuItem>
        );
    });

    const refCopy = useRef<HTMLElement>(null);
    const forkedRef = useForkRef(refCopy, ref);

    const getList = (): HTMLElement | null => {
        const ul = refCopy.current;

        if (!hasInfinityScroll) {
            return ul;
        }

        return (ul?.firstElementChild?.firstElementChild ?? null) as HTMLElement | null;
    };

    return (
        <ul
            ref={forkedRef}
            onKeyDown={handleKeyDown}
            data-testid="rf-select-list-scroll"
            className={classNames('rf-select__list', `rf-select__list--${menuVariantSize}`)}
            id={`Select-${selectId}-list-scroll`}
            onScroll={onScroll}
        >
            {hasInfinityScroll ? (
                <InfiniteScroll
                    dataLength={0}
                    hasMore={false}
                    {...infinityScrollProps}
                    next={makeLazyFetch()}
                    loader={loader}
                    scrollableTarget={`Select-${selectId}-list-scroll`}
                    className="rf-select__infinity-list"
                >
                    {listJSX}
                </InfiniteScroll>
            ) : (
                <>{preloader ? loader : listJSX}</>
            )}
        </ul>
    );
};

export default SelectMenu;

function moveFocus(list: HTMLElement, traversalFunction: (list: HTMLElement, item: Element | null) => Element | null) {
    const currentFocus = document.activeElement;

    let wrappedOnce = false;
    let nextFocus = traversalFunction(list, currentFocus) as HTMLElement | null;

    while (nextFocus) {
        if (nextFocus === list.firstChild) {
            if (wrappedOnce) {
                return;
            }

            wrappedOnce = true;
        }

        const nextFocusDisabled = nextFocus.getAttribute('aria-disabled') === 'true';

        if (!nextFocus.hasAttribute('tabindex') || nextFocusDisabled) {
            nextFocus = traversalFunction(list, nextFocus) as HTMLElement | null;
        } else {
            nextFocus.focus();
            return;
        }
    }
}

function nextItem(list: HTMLElement, item: Element | null): Element | null {
    if (list === item) {
        return list.firstElementChild;
    }

    if (item && item.nextElementSibling) {
        return item.nextElementSibling;
    }

    return list.firstElementChild;
}

function previousItem(list: HTMLElement, item: Element | null): Element | null {
    if (list === item) {
        return list.lastElementChild;
    }

    if (item && item.previousElementSibling) {
        return item.previousElementSibling;
    }

    return list.lastElementChild;
}
