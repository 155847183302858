import './LoadFromFileExecutor.scss';
import React, { useCallback, useRef, useState } from 'react';
import Button from '@atoms/Button';
import Modal from '@atoms/Modal';
import { MdClose } from 'react-icons/md';
import { IBaseAction } from '@models/actions/IBaseAction';
import { DocBaseActionExecutor } from '@utils/actions/DocBaseActionExecutor';
import Preloader from '@atoms/Preloader';
import InputFile from '@atoms/InputFile';
import { IFileData } from '@/types';
import DevExpressDataGrid from '@atoms/DevExpress/DataGrid/DevExpressDataGrid';
import { Column, DataGrid, Editing } from 'devextreme-react/data-grid';
import { Buffer } from 'buffer';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import Hint from '@atoms/Hint';
import { ModalSize } from '@atoms/Modal/Modal';
import { onCellHoverChanged } from '@utils/dataGridUtils';
import { MAX_FILE_SIZE } from '@utils/constants';
import { RowRemovedEvent } from 'devextreme/ui/data_grid';

export class LoadFromFileExecutor extends DocBaseActionExecutor {
    private _modalProps?: ILoadFromFileExecutorProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            docId: objId ?? '',
            actionKey: action.key ?? '',
            title: action.options?.title ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'xl',
            allowedFileTypes: action.options?.allowedFileTypes ?? '',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <LoadFromFileExecutorModal {...this._modalProps} /> : <div></div>;
    };
}

interface ILoadFromFileExecutorProps {
    docId: string;
    actionKey: string;
    title: string;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    allowedFileTypes: string;
    completeHandler?: (isSucceed: boolean) => void;
}

const LoadFromFileExecutorModal: React.FC<ILoadFromFileExecutorProps> = (props: ILoadFromFileExecutorProps) => {
    const [errorText, setErrorText] = useState<string>();
    const [isBusy, setIsBusy] = useState<boolean>();

    const [uploadingFiles, setUploadingFiles] = useState<any[]>([]);
    const [binaryFiles, setBinaryFiles] = useState<{ [fileId: string]: File }>({});

    const gridRef = useRef<DataGrid>(null);

    const setFile = (files: IFileData[]) => {
        if (files) {
            setErrorText('');
            setUploadingFiles([]);
            //setBinaryFiles({});
            for (let i = 0; i < files.length; i++) {
                let f = files[i].file;

                let fileGuid = uuidv4();

                //записываем в словарь
                if (binaryFiles && f) binaryFiles[fileGuid] = f;

                let fileObj: any = {
                    _fileId: fileGuid,
                    name: f.name,
                };

                setUploadingFiles((uploadingFiles) => [...uploadingFiles, fileObj]);
            }
        }

        gridRef.current?.instance.refresh();
    };

    const onRowRemoved = useCallback(
        (e: RowRemovedEvent) => {
            let removedFileId = e.key._fileId;
            if (removedFileId && binaryFiles[removedFileId]) {
                delete binaryFiles[removedFileId];
            }
            setUploadingFiles([...uploadingFiles]);
        },
        [binaryFiles, uploadingFiles],
    );

    return (
        <Modal
            className={classNames('modal-dlg-container')}
            size={props.modalSize}
            header={
                <>
                    <div className={classNames('box')}>
                        <div>{props.title}</div>
                        <div className={classNames('left')}>
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xs"
                                aria-label="Закрыть окно"
                                onClick={() => {
                                    if (props.completeHandler) props.completeHandler(false);
                                }}
                                startAdornment={<MdClose size="24" />}
                            />
                        </div>
                    </div>
                </>
            }
        >
            {isBusy ? (
                <Preloader size="m" position="static" />
            ) : (
                <div>
                    {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" maxWidth={'100%'} />}

                    <div className="LoadFromFile__AllowedExtensionsGroup">
                        <p className="LoadFromFile__AllowedExtensions">
                            <span>Список допустимых форматов: </span>
                            {props.allowedFileTypes ? (
                                props.allowedFileTypes
                                    ?.toString()
                                    .split(',')
                                    .map((extension, i) => {
                                        return <span key={i}>.{extension}</span>;
                                    })
                            ) : (
                                <span>ограничений нет</span>
                            )}
                        </p>
                    </div>
                    <InputFile
                        className="LoadFromFile__SelectFileInput"
                        setFile={setFile}
                        placeholder="Выбрать файл..."
                        maxSize={MAX_FILE_SIZE}
                        multiple={false}
                        showChips={false}
                        clearFilesAfterSet={true}
                        accept={props.allowedFileTypes ? props.allowedFileTypes.toString() : '*'}
                    />

                    <DevExpressDataGrid
                        dataSource={uploadingFiles}
                        height={70}
                        remoteOperations={true}
                        hoverStateEnabled={true}
                        columnHidingEnabled={false}
                        showColumnHeaders={true}
                        columnAutoWidth={true}
                        allowColumnReordering={false}
                        allowColumnResizing={true}
                        columnResizingMode="widget"
                        noDataText={'Файл не выбран'}
                        rowAlternationEnabled={true}
                        ref={gridRef}
                        onRowRemoved={onRowRemoved}
                        onCellHoverChanged={onCellHoverChanged}
                    >
                        <Editing
                            mode="cell"
                            allowUpdating={false}
                            allowAdding={false}
                            allowDeleting={true}
                            confirmDelete={false}
                        />
                        <Column
                            key={`col_name`}
                            allowFiltering={true}
                            caption={'Наименование'}
                            dataField={'name'}
                            dataType={'string'}
                            visible={true}
                            allowSorting={false}
                            allowHeaderFiltering={false}
                            filterOperations={[]}
                        />
                    </DevExpressDataGrid>

                    <div className="modal-dlg-buttons">
                        <div className={'left'}>
                            <Button
                                disabled={!uploadingFiles || uploadingFiles.length === 0}
                                size="s"
                                aria-label={props.okButtonText}
                                onClick={() => {
                                    setIsBusy(true);

                                    let service = new DocumentExecutionService(props.docId);
                                    service
                                        .loadFromFile(props.actionKey, binaryFiles[uploadingFiles[0]._fileId])
                                        .then((response) => {
                                            const loadData = response.data;
                                            if (loadData) {
                                                // Скачивание файла с ошибками
                                                const loadFileName = response.headers['loadfilename'];
                                                const loadFileNameDecoded = Buffer.from(
                                                    loadFileName,
                                                    'base64',
                                                ).toString();
                                                const loadDataDecoded = Buffer.from(response.data, 'base64');
                                                const byteArray = new Uint8Array(loadDataDecoded);

                                                let url = window.URL.createObjectURL(
                                                    new Blob([byteArray], {
                                                        type: response.headers['content-type'],
                                                    }),
                                                );
                                                let link = document.createElement('a');
                                                link.href = url;
                                                link.setAttribute('download', loadFileNameDecoded);
                                                document.body.appendChild(link);
                                                link.click();
                                                document.body.removeChild(link);
                                            }

                                            const loadSuccess = response.headers['loadsuccess'];
                                            if (loadSuccess.toLowerCase() == 'true') {
                                                if (props.completeHandler) props.completeHandler(true);
                                            } else {
                                                const loadMessage = response.headers['loadmessage'];
                                                const loadMessageDecoded = Buffer.from(
                                                    loadMessage,
                                                    'base64',
                                                ).toString();
                                                setErrorText(loadMessageDecoded);
                                            }

                                            setIsBusy(false);
                                        })
                                        .catch((error) => setErrorText(error))
                                        .finally(() => {
                                            setIsBusy(false);
                                        });
                                }}
                            >
                                {props.okButtonText}
                            </Button>

                            <Button
                                buttonType="light"
                                size="s"
                                aria-label={props.cancelButtonText}
                                onClick={() => {
                                    if (props.completeHandler) props.completeHandler(false);
                                }}
                            >
                                {props.cancelButtonText}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};
