import React, { ReactNode, RefObject, useContext, useEffect, useState } from 'react';
import './DropdownContent.scss';
import { DropdownPosition } from '@/types';
import { DropdownContainerContext } from '@/context/Contexts';
import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react';
import { FloatingContext } from '@floating-ui/react/dist/floating-ui.react';

export interface IDropdownContentProps<T extends HTMLElement = HTMLDivElement> {
    ref?: React.Ref<HTMLDivElement>;
    /** Контент */
    children: ReactNode | ReactNode[];
    /** Флаг отображения */
    show: boolean;
    /** Положение выпадающего меню */
    position?: DropdownPosition;
    /** Стили */
    style?: React.CSSProperties;
    /** Ссылка на контейнер портала */
    containerRef?: RefObject<T>;
    /**
     * Контекст FloatingUI
     */
    floatingContext: FloatingContext;
    /**
     * Пропсы FloatingUI
     */
    getFloatingProps?: (userProps?: React.HTMLProps<HTMLElement>) => Record<string, unknown>;
}

const DropdownContent: React.FC<IDropdownContentProps> = ({
    ref,
    children,
    show,
    position,
    style,
    containerRef,
    floatingContext,
    getFloatingProps,
}: IDropdownContentProps) => {
    const dropdownContainerRef = useContext(DropdownContainerContext);
    const [container, setContainer] = useState<HTMLElement | null>(null);

    useEffect(() => {
        if (containerRef?.current) {
            setContainer(containerRef.current);
        } else if (dropdownContainerRef?.current) {
            setContainer(dropdownContainerRef.current);
        } else {
            setContainer(document.body);
        }
    }, [containerRef?.current, dropdownContainerRef?.current]);

    if (!show) return null;

    return (
        <FloatingPortal root={container}>
            <FloatingFocusManager context={floatingContext} modal={false}>
                <div
                    ref={ref}
                    style={style}
                    className={`rf-dropdown__content dx-dropdowneditor-overlay`}
                    data-position={position}
                    {...getFloatingProps?.()} // Add Floating UI's event listeners and props
                >
                    {children}
                </div>
            </FloatingFocusManager>
        </FloatingPortal>
    );
};

export default DropdownContent;
