import './../actions.scss';
import Moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Button from '@atoms/Button';
import Modal from '@atoms/Modal';
import { MdClose } from 'react-icons/md';
import { IActivityAction, IBaseAction } from '@models/actions/IBaseAction';
import classNames from 'classnames';
import { ActivityBaseActionExecutor } from '@utils/actions/ActivityBaseActionExecutor';
import { ActFinishType, ActivityExecutionService } from '@services/actions/ActivityExecutionService';
import Preloader from '@atoms/Preloader';
import Hint from '@atoms/Hint';
import { CadespluginService } from '@services/CadespluginService';
import { ICertificate } from '@models/certificates/cert';
import Box from '@atoms/Box';
import { AttachActionsService } from '@services/actions/AttachActionsService';
import { IPartsSign } from '@models/attaches/IAttachViewSignModel';
import { getActivityIdFromCompositeId } from '@utils/helpers';
import Textarea from '@atoms/Textarea';
import { ModalSize } from '@atoms/Modal/Modal';
import {
    AttachFilesBlock,
    IAttachFilesBlockRefActions,
    ISelectedFiles,
} from '@/components/atoms/AttachFilesBlock/AttachFilesBlock';
import { IAttachesCreateModel } from '@models/attaches/IAttachesCreateModel';
import { IActivityActionModel } from '@models/document/activityExecutors/IActivityActionModel';
import { useTypedSelector } from '@/hooks/useTypedSelector';
import { DocumentService } from '@/services/DocumentService';
import { useActions } from '@/hooks/useActions';
import { IField, IFieldElem } from '@/models/IFormData';
import { IDocumentData } from '@/models/document/IDocumentData';
import { AutoTaskService } from '@/services/actions/AutoTaskService';
import { Buffer } from 'buffer';
import { FormulaManager } from '@/utils/FormulaManager';
import { IRequiredValidation } from '@/models/actions/IRequiredValidation';
import { checkAttachValidation } from '@/utils/documentUtils';
import FatalError from '@molecules/FatalError';
import FatalErrorIcon from '@molecules/FatalError/FatalErrorIcon';
import { IDocAttachesVersions } from '@/models/attaches/IDocAttachesVersions';
import { API_URL } from '@/http';
import Checkbox from '@/components/atoms/Checkbox';

export abstract class BaseWithSignExecutor extends ActivityBaseActionExecutor {
    private _model?: IActionDialogProps;

    protected approveModel?: IApproveActionModel;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        let act = action as IActivityAction;
        if (this.approveModel) {
            this._model = {
                actionKey: action.key,
                displayName: action.displayName,
                approveModel: this.approveModel,
                defaultAlg: action.options?.defaultAlg ?? 'rsa',
                gostCases: action.options?.gostCases ?? [],
                gostCN: action.options?.gostCN ?? [],
                certDataChecks: action.options?.certDataChecks,
                mchdDataChecks: action.options?.mchdDataChecks,
                okButtonText: action.options?.okButtonText ?? 'ОК',
                cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
                modalSize: modalSize ?? action.options?.modalSize ?? 'xxl',
                docFlow: act?.flowName,
                docId: act.docId,
                objId: objId,
                activityId: getActivityIdFromCompositeId(objId),
                actName: act?.actName ?? rowData?.fields?.ActivityName,
                attachFiles: action.options?.attachFiles ?? false,
                previewAttachFiles: action.options?.previewAttachFiles ?? false,
                attachRequired: action.options?.attachRequired ?? false,
                attachRequiredValidation: action.options?.attachRequiredValidation as IRequiredValidation[],
                completeHandler: completeHandler,
            };
        }
    };

    visualElementInner = () => {
        return this._model ? <ApproveWithSignModal {...this._model} /> : <></>;
    };
}

interface IApproveActionModel {
    commentIsRequired: boolean;
    actType: ActFinishType;
}

interface IActionDialogProps {
    actionKey: string;
    displayName: string;
    approveModel: IApproveActionModel;
    objId: string;
    activityId: string;
    defaultAlg: string;
    gostCases: IGostCase[];
    gostCN: string[];
    certDataChecks: ICertDataCheck;
    mchdDataChecks: IMchdDataCheck;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    docFlow?: string;
    docId: string;
    actName: string;
    attachFiles: boolean;
    previewAttachFiles: boolean;
    attachRequired: boolean;
    attachRequiredValidation?: IRequiredValidation[];
    completeHandler?: (isSucceed: boolean) => void;
}

interface IMchdDataCheck {
    resultTableSchema: ICheckTableSchema;
    conditionChecks: IMchdConditionCheck[];
}

interface IMchdConditionCheck {
    conditions: ICheckCondition[];
    servCheck: IMchdServCheck;
}

interface IMchdServCheck {
    docRequisite: string | string[];
    systemVal: string;
    resultOkVal: string;
    resultFailVal: string;
    runAutoTaskNameToCheck: string;
}

interface ICheckTableSchema {
    name: string;
    systemCol: string;
    resultCol: string;
    dateCol: string;
}

interface ICheckCondition {
    flowName: string;
    activityName: string;
    formula: string;
}

interface ICertDataCheck {
    resultTableSchema: ICheckTableSchema;
    conditionChecks: ICertConditionCheck[];
}

interface ICertConditionCheck {
    conditions: ICheckCondition[];
    resultTableSchema: ICheckTableSchema;
    frontCheck: ICertFrontCheck;
    servCheck: ICertServCheck;
}

interface ICertFrontCheck {
    errorText: string;
    certPartNames: string;
    docRequisite: string;
    systemVal: string;
    resultOkVal: string;
    resultFailVal: string;
}

interface ICertServCheck {
    systemVal: string;
    resultOkVal: string;
    resultFailVal: string;
    runAutoTaskNameToCheck: string;
}

interface IGostCase {
    flowName: string;
    activityName: string;
}

interface ICheckTableRow {
    system: string;
    result: string;
}

const ApproveWithSignModal: React.FC<IActionDialogProps> = (props: IActionDialogProps) => {
    const currentDocData = useTypedSelector((state) => state.document.data);

    const [filesSizeExceeded, setFilesSizeExceeded] = useState<boolean>(false);
    const childFilesBlock = useRef<IAttachFilesBlockRefActions | null>(null);
    const [attachesCreateModel, setAttachesCreateModel] = useState<IAttachesCreateModel>();
    const [actionModel, setActionModel] = useState<IActivityActionModel>();

    const [comment, setComment] = useState<string>();
    const [selectedCertThumb, setSelectedCertThumb] = useState<string>();

    const [errorText, setErrorText] = useState<string>();
    const [warningText, setWarningText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();
    const [loadingText, setLoadingText] = useState<string>();

    const [certList, setCertList] = useState<ICertificate[]>();

    const [attachesRead, setAttachesRead] = useState<boolean>(false);
    const [attaches, setAttaches] = useState<IDocAttachesVersions[]>();

    const { fetchDocumentData, saveDocumentData } = useActions();

    const loadCertificates = async () => {
        setLoadingText('Загрузка сертификатов...');
        setLoading(true);
        let serv = new CadespluginService();
        //Если имя активити и поток документа совпадают с нужными кейсами из конфига - ставим алгоритм ГОСТ
        let alg: string[] = [props.defaultAlg];
        let gostCN: string[] = [];
        if (
            props.docFlow &&
            props.gostCases.find(
                (x) =>
                    x.flowName == props.docFlow &&
                    x.activityName?.toLocaleLowerCase() == props.actName?.toLocaleLowerCase(),
            )
        ) {
            gostCN = props.gostCN;
            alg = ['гост', 'gost'];
        }

        let certs = await serv.getCertListByAlgSubstring(alg, gostCN);

        //если серификат один - сразу делаем его вбыранным
        if (certs && certs.length == 1) {
            setSelectedCertThumb(certs[0].thumbprint);
        }

        setCertList(certs);

        setLoading(false);
    };

    useEffect(() => {
        loadCertificates();

        setLoadingText('Загрузка настроек...');
        setLoading(true);
        let activityExecutionService = new ActivityExecutionService();
        activityExecutionService.getActivityActionModel(props.actionKey, props.activityId).then((res) => {
            setActionModel(res.data);
            setComment(res.data.prefilledComment);
            setLoading(false);
        });

        //обновление контекста документа, т.к. в поисковых папках нет контекста документа
        if (!currentDocData || props.docId !== currentDocData.id) {
            fetchDocumentData(props.docId, undefined);
        }

        //получение модели для аттачей
        if (props.attachFiles) {
            let attachServ = new AttachActionsService(props.docId, '-1');
            attachServ.getAttachInfo(props.actionKey, props.activityId).then((res) => {
                setAttachesCreateModel(res.data);
            });
            if (props.previewAttachFiles) {
                setLoading(true);
                let actId = getActivityIdFromCompositeId(props.objId);
                setLoadingText('Получение перечня файлов...');
                attachServ.getDocAttachKeysByActKey(actId, props.actionKey).then((res) => {
                    setAttaches(res.data);
                    setAttachesRead(false);
                    setLoading(false);
                });
            } else {
                setAttachesRead(true);
            }
        }
    }, []);

    const encodeBase64 = (str: string): string => Buffer.from(str, 'binary').toString('base64');

    const writeCheckResultToTable = async (
        schema: ICheckTableSchema,
        rowsToAdd: ICheckTableRow[],
        clonedDocData: IDocumentData,
    ) => {
        //let clonedDocData: IDocumentData = JSON.parse(JSON.stringify(currentDocData));
        let tbl: IField | undefined = clonedDocData.fields.find((x) => x.name == schema.name);
        if (tbl) {
            let arr: any[] = tbl.value as any[];
            if (arr) {
                let maxNum = Math.max(...arr.map((x) => x['|NUM'] as number));
                if (maxNum == Number.NEGATIVE_INFINITY) maxNum = 0;

                rowsToAdd.forEach((x) => {
                    maxNum = maxNum + 1;
                    let newCheckRow: any = {
                        '|NUM': maxNum,
                    };
                    newCheckRow[schema.systemCol] = x.system;
                    newCheckRow[schema.resultCol] = x.result;
                    newCheckRow[schema.dateCol] = new Date();
                    arr.push(newCheckRow);
                });
            }

            setLoadingText('Запись результатов проверок...');
            clonedDocData.ignoreHooks = true;
            await DocumentService.saveData(clonedDocData, undefined);
            //await saveDocumentData(clonedDocData, undefined);
        }
    };

    const checkConditionFormula = async (formula: string): Promise<boolean> => {
        let formulaMgr = new FormulaManager(formula);
        let formulaData: Record<string, IFieldElem> = {} as Record<string, IFieldElem>;
        currentDocData?.fields.forEach(
            (x) =>
                (formulaData[x.name] = {
                    index: 0,
                    name: '',
                    value: x.value,
                }),
        );
        formulaMgr.Init(formulaData);
        let result = await formulaMgr.EvalFormulaValues(true, false);
        return result;
    };

    const getFieldByName = (data: IDocumentData | undefined, value: string): IField | undefined => {
        return data?.fields.find((_) => _.name === value);
    };

    const getFieldsByNames = (data: IDocumentData | undefined, values: string | string[]): IField[] => {
        let fields: IField[] = [];

        if (Array.isArray(values)) {
            values.forEach((item) => {
                let field = getFieldByName(data, item);
                if (field) fields.push(field);
            });
        } else {
            let field = getFieldByName(data, values);
            if (field) fields.push(field);
        }

        return fields;
    };

    const onSubmit = async () => {
        setLoading(true);

        let attachServ = new AttachActionsService(props.docId, '-1');
        let cadesServ = new CadespluginService();

        let clonedDocData: IDocumentData = JSON.parse(JSON.stringify(currentDocData));

        let error: { flag: boolean; msg?: string } = {
            flag: false,
            msg: '',
        };

        //Проверка СНИЛС сертификата и дока прямо на фронте перед подписанием
        if (props.certDataChecks && selectedCertThumb) {
            let selectedCert = certList?.find((x) => x.thumbprint == selectedCertThumb);
            if (selectedCert) {
                setLoadingText('Проверка СНИЛС сертификата...');
                let resultTableSchema = props.certDataChecks.resultTableSchema;
                for (let i = 0; i < props.certDataChecks.conditionChecks.length; i++) {
                    let check = props.certDataChecks.conditionChecks[i];

                    //определить по усливию, нужна ли проверка
                    //let anyCoditionIsOk = check.conditions.forEach(async x=>
                    let checkIsOk: boolean = false;
                    for (let x of check.conditions) {
                        if (
                            x.flowName == props.docFlow &&
                            x.activityName?.toLocaleLowerCase() == props.actName?.toLocaleLowerCase() &&
                            (!x.formula || (await checkConditionFormula(x.formula))) //или формула не задачи или она "выполняется"
                        )
                            checkIsOk = true;
                    }
                    if (!checkIsOk) continue;

                    let partNames = check.frontCheck.certPartNames.split(',').map((x) => x.trim());
                    let docRequisite = currentDocData?.fields.find((x) => x.name == check.frontCheck.docRequisite);

                    if (docRequisite) {
                        let docRequisiteValue = docRequisite.value as string;
                        let valuesFromCert = partNames
                            .map((part) => cadesServ.getCnPart(selectedCert!.rawSubject, part))
                            .filter((x) => x);

                        let checkRowsToWrite: ICheckTableRow[] = [];

                        if (valuesFromCert.length > 0 && docRequisiteValue) {
                            //если вообще есть что сравнивать (непустые значения у дока и у сертификата)

                            let isSnilsCheckFailed: boolean =
                                valuesFromCert.filter((x) => x == docRequisiteValue).length == 0;

                            checkRowsToWrite.push({
                                system: check.frontCheck.systemVal,
                                result: isSnilsCheckFailed
                                    ? check.frontCheck.resultFailVal
                                    : check.frontCheck.resultOkVal,
                            });

                            if (isSnilsCheckFailed) {
                                await writeCheckResultToTable(resultTableSchema, checkRowsToWrite, clonedDocData);
                                setErrorText(check.frontCheck.errorText);
                                setLoading(false);
                                return;
                            }
                        }

                        if (check.servCheck?.runAutoTaskNameToCheck) {
                            let dataToSign = currentDocData?.id;
                            if (dataToSign) {
                                setLoadingText('Проверка в СС ЭП...');
                                //console.log('данные для подписания: ' + dataToSign);
                                let encodedDataToSign = encodeBase64(dataToSign);
                                //console.log('encodedDataToSign: ' + encodedDataToSign);

                                const signData = await cadesServ.signData(encodedDataToSign, selectedCertThumb!);

                                let serv = new AutoTaskService();

                                try {
                                    let res = await serv.runAutoImport({
                                        name: check.servCheck.runAutoTaskNameToCheck,
                                        docKey: currentDocData?.id,
                                        parameters: [encodedDataToSign, signData.sign],
                                    });

                                    checkRowsToWrite.push({
                                        system: check.servCheck.systemVal,
                                        result: res.data.isSucceded
                                            ? check.servCheck.resultOkVal
                                            : `${check.servCheck.resultFailVal}. ${res.data.message}`,
                                    });

                                    if (!res.data.isSucceded) {
                                        error.flag = true;
                                        error.msg = res.data.message;
                                    }
                                } catch (e) {
                                    error.flag = true;
                                    error.msg = e as string;
                                }
                            }
                        }

                        if (checkRowsToWrite.length > 0)
                            await writeCheckResultToTable(resultTableSchema, checkRowsToWrite, clonedDocData);

                        if (error.flag) {
                            setErrorText(error.msg);
                            setLoading(false);
                            return;
                        }
                    }
                }
            }
        }

        //Проверка МЧД по аналогии со СНИЛС
        if (props.mchdDataChecks) {
            let mchdDataChecks = props.mchdDataChecks;
            let resultTableSchema = mchdDataChecks.resultTableSchema;
            for (let i = 0; i < mchdDataChecks.conditionChecks.length; i++) {
                let check = mchdDataChecks.conditionChecks[i];

                //определить по условию, нужна ли проверка
                //let anyCoditionIsOk = check.conditions.forEach(async x=>
                let checkIsOk: boolean = false;
                for (let x of check.conditions) {
                    if (
                        x.flowName == props.docFlow &&
                        x.activityName?.toLocaleLowerCase() == props.actName?.toLocaleLowerCase() &&
                        (!x.formula || (await checkConditionFormula(x.formula))) //или формула не задачи или она "выполняется"
                    ) {
                        checkIsOk = true;
                    }
                }
                if (!checkIsOk) continue;

                if (check.servCheck.runAutoTaskNameToCheck) {
                    setLoadingText('Проверка в МЧД...');
                    let docRequisites = getFieldsByNames(currentDocData, check.servCheck.docRequisite);
                    if (docRequisites.length > 0) {
                        let serv = new AutoTaskService();
                        let parameters = docRequisites.map((item, index) => {
                            return item.value as string;
                        });

                        let checkRowsToWrite: ICheckTableRow[] = [];

                        try {
                            let res = await serv.runAutoImport({
                                name: check.servCheck.runAutoTaskNameToCheck,
                                docKey: currentDocData?.id,
                                parameters: parameters,
                            });

                            checkRowsToWrite.push({
                                system: check.servCheck.systemVal,
                                result: res.data.isSucceded
                                    ? check.servCheck.resultOkVal
                                    : `${check.servCheck.resultFailVal}. ${res.data.message}`,
                            });

                            if (!res.data.isSucceded) {
                                error.flag = true;
                                error.msg = res.data.message;
                            }
                        } catch (e) {
                            error.flag = true;
                            error.msg = e as string;
                        }

                        if (checkRowsToWrite.length > 0)
                            await writeCheckResultToTable(resultTableSchema, checkRowsToWrite, clonedDocData);

                        if (error.flag) {
                            setErrorText(error.msg);
                            setLoading(false);
                            return;
                        }
                    }
                }
            }
        }

        try {
            if (props.objId && selectedCertThumb) {
                let filesToSend: ISelectedFiles = { files: [], attachProperties: [] };

                if (attachesCreateModel && childFilesBlock) {
                    if (!childFilesBlock?.current?.isValid()) {
                        setLoading(false);
                        return;
                    }
                    let f = childFilesBlock?.current?.getData();
                    if (f) filesToSend = f;
                }

                // проверка на обязательность наличия аттачей
                if (
                    props.attachFiles &&
                    props.attachRequired &&
                    (filesToSend?.files === undefined || filesToSend?.files.length === 0)
                ) {
                    setWarningText('Необходимо приложить файл');
                    setLoading(false);
                    return;
                }

                if (props.attachRequiredValidation && props.attachRequiredValidation.length > 0) {
                    let checkResult = checkAttachValidation(props.attachRequiredValidation, filesToSend);
                    if (checkResult.length > 0) {
                        let text = checkResult.join('\n');
                        setWarningText(text);
                        setLoading(false);
                        return;
                    }
                }

                if (filesToSend?.files?.length > 0) {
                    await attachServ.createAttaches(filesToSend);
                }

                let actId = getActivityIdFromCompositeId(props.objId);

                setLoadingText('Получение перечня файлов...');
                let attachesData = attaches
                    ? attaches
                    : await (
                          await attachServ.getDocAttachKeysByActKey(actId, props.actionKey)
                      ).data;

                for (let index = 0; index < attachesData.length; index++) {
                    const attachData = attachesData[index];

                    let docId = attachData.docId;
                    let attachesIds = attachData.attaches;

                    let signsForAttaches: IPartsSign[] = []; //: {[attachId : string] : [sign: string, algorithm: string]}={};
                    //по всем аттачам
                    for (let i = 0; i < attachesIds.length; i++) {
                        let attachId = attachesIds[i];

                        setLoadingText(`Получение файла №${i + 1}...`);
                        let base64content = await attachServ.getAttachContentInBase64(docId, attachId.attachId);

                        setLoadingText(`Подпись файла №${i + 1}...`);
                        const signWithAlg = await cadesServ.signData(base64content, selectedCertThumb!);

                        signsForAttaches.push({
                            algorithm: signWithAlg.algorithm,
                            sign: signWithAlg.sign,
                            attachId: attachId.attachId,
                            docId: docId,
                        });
                    }

                    setLoadingText(`Отправка на сервер...`);

                    let activityExecutionService = new ActivityExecutionService();
                    await activityExecutionService.activityActionWithSign(
                        props.actionKey,
                        actId,
                        signsForAttaches,
                        props.approveModel.actType,
                        comment,
                    );
                }
                setLoading(false);
                if (props.completeHandler) props.completeHandler(true);
                return;
            }
        } catch (e: any) {
            if (typeof e === 'string') {
                setErrorText(e);
            } else if (Array.isArray(e)) {
                const errorText = e.join('\n');
                setErrorText(errorText);
            } else {
                const errorText =
                    'Ошибка при подписании. Убедитесь, что в браузере установлен и настроен плагин КриптоПро ЭЦП. ' +
                    'Проверить работу плагина: https://cryptopro.ru/sites/default/files/products/cades/demopage/cades_bes_sample.html';
                setErrorText(errorText);
            }
            console.error(e);
        }

        setLoading(false);

        // attaches.data.attaches.forEach((attachId,i) => {
        //     console.log(`attach = ${attachId}`);
        // });

        // let serv = new CadespluginService();
        // if(selectedCertThumb){
        //     let sign = await serv.signData('0KLQtdGB0YLQvtCy0LDRjyDRgdGC0YDQvtC60LAgMTIz', selectedCertThumb);
        //     console.log(sign);
        // }

        //setTimeout(() => { setLoading(false) }, 1000);
    };

    const onCertSelect = (e: any) => {
        if (e.target.value) setSelectedCertThumb(e.target.value);
    };

    return (
        <Modal
            className={classNames('modal-dlg-container')}
            size={props.modalSize}
            header={
                <>
                    <div className={classNames('box')}>
                        <div>
                            <span>{props.displayName}</span>
                        </div>
                        <div className={classNames('left')}>
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xs"
                                aria-label="Закрыть окно"
                                onClick={() => {
                                    if (props?.completeHandler) props?.completeHandler(false);
                                }}
                                startAdornment={<MdClose size="24" />}
                            />
                        </div>
                    </div>
                </>
            }
        >
            {loading ? (
                <div>
                    <Preloader size="m" position="static" />
                    {loadingText}
                </div>
            ) : (
                <>
                    {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" maxWidth={'100%'} />}
                    {warningText && (
                        <Hint icon="info" title={`Внимание: ${warningText}`} variant="yellow" maxWidth="100%" />
                    )}
                    <div className="certList">
                        {certList && certList.length > 0 ? (
                            certList.map((cert, i) => (
                                <Box key={i.toString()} className="certificateBox">
                                    <input
                                        type="radio"
                                        name="certSelection"
                                        value={cert.thumbprint}
                                        onChange={onCertSelect}
                                        checked={cert.thumbprint == selectedCertThumb}
                                    ></input>
                                    <div>
                                        <div>
                                            <b>Владелец сертификата </b>
                                            {cert.subjectName}
                                        </div>
                                        <div>
                                            <b>Центр выдачи сертификата </b>
                                            {cert.issuerName}
                                        </div>
                                        <div>
                                            <b>Действителен с </b>
                                            {Moment(cert.validFrom).format('DD.MM.YYYY')}
                                        </div>
                                        <div>
                                            <b>Действителен по </b>
                                            {Moment(cert.validTo).format('DD.MM.YYYY')}
                                        </div>
                                    </div>
                                </Box>
                            ))
                        ) : (
                            <div className="certList--noCertsAvailable">
                                <FatalError
                                    icon={<FatalErrorIcon variant="default" size={{ width: 100, height: 100 }} />}
                                    title="Нет доступных сертификатов"
                                    description=""
                                />
                            </div>
                        )}

                        {attaches && props.previewAttachFiles ? (
                            <div className="attachesList">
                                {attaches.map((docAttaches) => {
                                    return docAttaches.attaches?.map((attach, i) => {
                                        let link = `${API_URL}/attachment/download/{documentKey}/{key}?access_token=${localStorage.getItem(
                                            'frontAccessToken',
                                        )}`;

                                        link = link.replace('{key}', attach.attachId.split('_')[0]);
                                        link = link.replace('{documentKey}', docAttaches.docId.toString());
                                        return (
                                            <Box key={i.toString()} className="attachesBox">
                                                <div>
                                                    <div>
                                                        <b>
                                                            <a target="_blank" href={link}>
                                                                {attach.title}
                                                            </a>
                                                        </b>
                                                    </div>
                                                </div>
                                            </Box>
                                        );
                                    });
                                })}
                                <div>
                                    <Checkbox
                                        defaultChecked={false}
                                        onChange={() => {
                                            setAttachesRead(!attachesRead);
                                        }}
                                        label={'С файлами ознакомлен'}
                                    />
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>

                    <div>
                        <Textarea
                            className={classNames(
                                props.approveModel.commentIsRequired &&
                                    (comment == undefined || comment.length == 0) &&
                                    'invalid',
                            )}
                            initialRowCount={8}
                            placeholder={'Комментарий' + (props.approveModel.commentIsRequired ? ' (обязательно)' : '')}
                            defaultValue={actionModel?.prefilledComment}
                            required={props.approveModel.commentIsRequired}
                            onChange={(e: any) => {
                                setComment(e.target.value);
                            }}
                        />
                    </div>
                    <div>
                        {attachesCreateModel && <AttachFilesBlock ref={childFilesBlock} model={attachesCreateModel} />}
                    </div>
                    <div className="modal-dlg-buttons">
                        <div className={'left'}>
                            <Button
                                disabled={
                                    (props.previewAttachFiles && !attachesRead) ||
                                    (props.approveModel.commentIsRequired &&
                                        (comment == undefined || comment?.length == 0)) ||
                                    selectedCertThumb == undefined
                                }
                                onClick={async () => {
                                    await onSubmit();
                                }}
                                size="s"
                                aria-label={props.okButtonText}
                            >
                                {props.okButtonText}
                            </Button>
                            <Button
                                onClick={() => {
                                    if (props?.completeHandler) props?.completeHandler(false);
                                }}
                                buttonType="light"
                                size="s"
                                aria-label={props.cancelButtonText}
                            >
                                {props.cancelButtonText}
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </Modal>
    );
};
