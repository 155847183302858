import './CopyDocumentExecutor.scss';
import React, { useState } from 'react';
import { IBaseAction } from '@models/actions/IBaseAction';
import { DocBaseActionExecutor } from '@utils/actions/DocBaseActionExecutor';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import MessageDialog, { IMessageDialogProps } from '@molecules/MessageDialog/MessageDialog';
import { useNavigate } from 'react-router';
import { ModalSize } from '@atoms/Modal/Modal';

export class CopyDocumentExecutor extends DocBaseActionExecutor {
    private _modalProps?: ICopyDocumentExecutorProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            docId: objId ?? '',
            actionKey: action.key ?? '',
            title: action.options?.title ?? '',
            message: action.options?.message ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'l',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <CopyDocumentExecutorModal {...this._modalProps} /> : <div></div>;
    };
}

interface ICopyDocumentExecutorProps {
    docId: string;
    actionKey: string;
    title: string;
    message: string;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    completeHandler?: (isSucceed: boolean) => void;
}

const CopyDocumentExecutorModal: React.FC<ICopyDocumentExecutorProps> = (props: ICopyDocumentExecutorProps) => {
    const [errorText, setErrorText] = useState<string>();
    const [isBusy, setIsBusy] = useState<boolean>();
    const navigate = useNavigate();

    const messageBoxProp: IMessageDialogProps = {
        header: props.title,
        message: props.message,
        showOkButton: true,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        size: props.modalSize,
        okClick: () => {
            setIsBusy(true);
            let service = new DocumentExecutionService(props.docId);
            return service
                .copyDocument(props.actionKey)
                .then((result) => {
                    if (result.data) {
                        navigate('/document/' + result.data);
                        props.completeHandler && props.completeHandler(true);
                    }
                })
                .catch((error) => setErrorText(error))
                .finally(() => {
                    setIsBusy(false);
                });
        },
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
    };

    return <MessageDialog {...messageBoxProp} errorText={errorText} isBusy={isBusy} />;
};
