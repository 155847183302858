import React, { useState } from 'react';
import { MdMediation, MdOutlineExpandMore } from 'react-icons/md';
import Button from '@atoms/Button';
import { IField } from '@models/IFormData';
import './MergeRowsButton.scss';
import DataSource from 'devextreme/data/data_source';
import { IDocumentTable, IMergeRows } from '../../../models/Forms/IForms';
import MergeRowsForm from './ModalForms/MergeRowsForm';

export interface IMergeRowsButtonProps {
    table: IDocumentTable;
    settings: IMergeRows;
    ds: DataSource;
    onMerge: (rowData: any) => Promise<void>;
    docId?: string;
    cellRenderSwitcher: (p: any, column: any, rowParent?: any) => Promise<React.ReactNode>;
    visibilityColumn: (rules: string, rowParent?: any) => Promise<boolean>;
    getParentFields: () => IField[];
}

const MergeRowsButton: React.FC<IMergeRowsButtonProps> = (props: IMergeRowsButtonProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);

    const onClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setShowModal(!showModal);
    };

    return (
        <>
            <Button
                buttonType="secondary"
                textColor="neutral"
                size="s"
                aria-label="Объединение строк"
                startAdornment={<MdMediation size="20" />}
                onClick={onClick}
            >
                Объединение строк
            </Button>
            {showModal && (
                <MergeRowsForm
                    table={props.table}
                    onClose={onClick}
                    onSubmit={props.onMerge}
                    title={'Объединение строк'}
                    cellRenderSwitcher={props.cellRenderSwitcher}
                    visibilityColumn={props.visibilityColumn}
                    getParentFields={props.getParentFields}
                    settings={props.settings}
                    ds={props.ds}
                />
            )}
        </>
    );
};

export default MergeRowsButton;
