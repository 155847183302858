import './../actions.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { IForms } from '@models/Forms/IForms';
import { IFieldElem } from '@models/IFormData';
import { IDocumentData } from '@models/document/IDocumentData';
import { IActivityAction, IBaseAction } from '@models/actions/IBaseAction';
import { ActivityBaseActionExecutor } from '@utils/actions/ActivityBaseActionExecutor';
import ModalFormBuilder, { IModalFormBuilderProps } from '@molecules/ModalFormBuilder/ModalFormBuilder';
import { DocumentService } from '@services/DocumentService';
import Preloader from '@atoms/Preloader';
import {
    checkAttachValidation,
    convertListToMap,
    getEmptyFormDataByScheme,
    getFormDataByScheme,
} from '@utils/documentUtils';

import { AttachActionsService } from '@services/actions/AttachActionsService';
import { IAttachesCreateModel } from '@models/attaches/IAttachesCreateModel';
import { ISelectedFiles } from '@/components/atoms/AttachFilesBlock/AttachFilesBlock';
import { getActivityIdFromCompositeId } from '@utils/helpers';
import { ModalSize } from '@atoms/Modal/Modal';
import { RemindersService } from '@services/RemindersService';
import { IMailRemindersBlockModel } from '@models/mailRemindersBlock/IMailRemindersBlockModel';
import { IMailRemindersOptions } from '@molecules/AddMailReminderBlock/AddMailReminderBlock';
import { IRequiredValidation } from '@/models/actions/IRequiredValidation';
import { sendErrorMsg } from '@/components/molecules/Errors';
import { ValidatorManager } from '@/utils/validatorManager';
import { ActivityExecutionService } from '@services/actions/ActivityExecutionService';
import { VariablesManager } from '@utils/VariablesManager';
import { useTypedSelector } from '@hooks/useTypedSelector';

export class ActivityBatchEditingExecutor extends ActivityBaseActionExecutor {
    private _model?: IActivityBatchEditingProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        let act = action as IActivityAction;
        this._model = {
            displayName: action.displayName,
            actionKey: action.key ?? '',
            docFlow: act?.flowName,
            actId: getActivityIdFromCompositeId(objId),
            docId: act.docId,
            attachFiles: action.options?.attachFiles == true,
            attachRequired: action.options?.attachRequired == true,
            attachRequiredValidation: action.options?.attachRequiredValidation as IRequiredValidation[],
            addMailReminders: action.options?.addMailReminders == true,
            virtualFields: action.options?.virtualFields as string[],
            showCurrentValues: action.options?.showCurrentValues ?? true,
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'xl',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._model ? <ActivityBatchEditingModal {...this._model} /> : <></>;
    };
}

interface IActivityBatchEditingProps {
    displayName: string;
    actId: string;
    actionKey: string;
    docFlow?: string;
    docId: string;
    attachFiles: boolean;
    attachRequired: boolean;
    attachRequiredValidation?: IRequiredValidation[];
    addMailReminders: boolean;
    virtualFields?: string[];
    showCurrentValues: boolean;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    //    showComment: boolean;
    completeHandler?: (isSucceed: boolean) => void;
}

const ActivityBatchEditingModal: React.FC<IActivityBatchEditingProps> = (props: IActivityBatchEditingProps) => {
    const [errorText, setErrorText] = useState<string>();
    const [warningText, setWarningText] = useState<string>();
    const [showOkButton, setShowOkButton] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>();
    const [form, setForm] = useState<IForms>();
    const [fieldsData, setFieldsData] = useState<Record<string, IFieldElem>>();
    const [attachesCreateModel, setAttachesCreateModel] = useState<IAttachesCreateModel>();
    const [mailRemindersBlockModel, setMailRemindersBlockModel] = useState<IMailRemindersBlockModel>();
    const user = useTypedSelector((state) => state.auth.user);

    const methods = useForm<IDocumentData>({
        mode: 'onBlur', // "onChange"
    });
    const service = new ActivityExecutionService();

    useEffect(() => {
        // Данные документа
        // Получение данных
    }, []);

    useEffect(() => {
        // Функция для получения модели аттачей
        async function fetchAttachModel() {
            if (props.attachFiles) {
                let activityId = props.actId;
                let attachService = new AttachActionsService(props.docId, '-1');
                attachService.getAttachInfo(props.actionKey, activityId).then((res) => {
                    setAttachesCreateModel(res.data);
                });
            }
        }

        // Функция для получения модели блока напоминаний
        async function fetchMailRemindersBlockModel() {
            if (props.addMailReminders) {
                const res = await RemindersService.getMailRemindersBlockModel(
                    props.docId,
                    props.actionKey,
                    props.actId,
                );
                setMailRemindersBlockModel(res.data);
            }
        }

        // Функция для получения схемы
        async function fetchFormBuilderScheme() {
            try {
                const dto = await service.getFormBuilderScheme(props.actionKey, props.docId);
                let form = dto.data;
                setForm(form);

                if (!props.showCurrentValues) {
                    let formData = getEmptyFormDataByScheme(form?.view?.rows?.row!);
                    let fields = convertListToMap(formData.fields);

                    return { fields, formData, form };
                }
            } catch (error) {
                setErrorText((error as Error)?.message);
            }
        }

        // Функция для получения данных
        async function fetchDataByAction() {
            if (props.showCurrentValues) {
                try {
                    const result = await DocumentService.getDataByAction(props.actionKey, props.docId, undefined, true);
                    const formData = result.data;

                    if (formData) {
                        let f = result.data.fields;

                        // Добавление виртуальных полей
                        if (props.virtualFields) {
                            props.virtualFields.forEach((x) => {
                                f.push({
                                    name: x,
                                    value: undefined,
                                });
                            });
                        }

                        let fields = convertListToMap(f);

                        return { fields, formData };
                    }
                } catch (error) {
                    setErrorText((error as Error)?.message);
                }
            }
        }

        async function fetchData() {
            await fetchAttachModel();
            await fetchMailRemindersBlockModel();
            const formResult = await fetchFormBuilderScheme();
            const dataResult = await fetchDataByAction();

            const fields = dataResult?.fields ?? formResult?.fields;
            const formData = dataResult?.formData ?? formResult?.formData;
            const form = formResult?.form;

            // добавляем Variables
            if (fields) {
                let mng = new VariablesManager(
                    form?.view.variables ?? { variable: [], dictVariable: [] },
                    props.docId,
                    user,
                );
                let variables = await mng.GetData(fields, true, false);
                console.log(variables);
                fields['variables'] = {
                    name: 'variables',
                    value: variables,
                    index: -1,
                };
            }

            setFieldsData(fields);
            methods.reset(formData);
        }

        fetchData().catch((error) => setErrorText(error.message));
    }, []);

    const onSubmit = useCallback(
        async (data: FieldValues, files?: ISelectedFiles, mailReminderOptions?: IMailRemindersOptions) => {
            setLoading(true);

            // проверка на обязательность наличия аттачей
            if (
                props.attachFiles &&
                props.attachRequired &&
                (files?.files === undefined || files?.files.length === 0)
            ) {
                setWarningText('Необходимо приложить файл');
                setLoading(false);
                return;
            }
            if (props.attachRequiredValidation && props.attachRequiredValidation.length > 0) {
                let checkResult = checkAttachValidation(props.attachRequiredValidation, files);
                if (checkResult.length > 0) {
                    let text = checkResult.join('\n');
                    setWarningText(text);
                }
            }
            let errors: string[] = [];

            if (form?.view?.validators && form?.view?.validators != null && fieldsData) {
                let validatorManager = new ValidatorManager(fieldsData, methods);
                await validatorManager.validateAllAsync(form?.view.validators?.validator, errors);

                if (errors.length > 0) {
                    sendErrorMsg({
                        message: errors,
                    });
                    setLoading(false);
                    return;
                }
            }

            let val = getFormDataByScheme(form?.view?.rows?.row!, data);

            //тут сохранение
            // Сохранение данных
            //let serv = new DocumentExecutionService(props.docId);
            service
                .saveBatchEditingData(props.docId, props.actionKey, props.actId, val, files)
                .then((result) => {
                    if (result.data.warnings && result.data.warnings.length > 0) {
                        setWarningText(result.data.warnings.join(', '));
                        setShowOkButton(false);
                    } else {
                        props.completeHandler && props.completeHandler(true);
                    }
                })
                .catch((error) => setErrorText(error))
                .finally(() => {
                    setLoading(false);
                });

            if (mailReminderOptions && mailRemindersBlockModel) {
                let remIds =
                    val.fields.find((item) => item.name === mailRemindersBlockModel.reminderUserIdsField)?.value ?? '';
                let remTargetDate = new Date(
                    val.fields.find((item) => item.name === mailRemindersBlockModel.reminderTargetDateField)
                        ?.value as string,
                );
                let remText =
                    val.fields.find((item) => item.name === mailRemindersBlockModel.reminderTextField)?.value ?? '';

                let dayOfWeek =
                    mailReminderOptions.periodical.dayOfWeek !== undefined
                        ? mailReminderOptions.periodical.dayOfWeek[0]?.value
                        : '';

                //Создание напоминаний
                RemindersService.createBlockMailReminders(
                    props.docId,
                    props.actionKey,
                    remText?.toString(),
                    remTargetDate.toISOString(),
                    remIds?.toString(),
                    mailReminderOptions.daysBefore,
                    mailReminderOptions.periodical.weekly === 1 ? 'true' : 'false',
                    mailReminderOptions.periodical.monthly === 1 ? 'true' : 'false',
                    dayOfWeek,
                );
            }
        },
        [form, fieldsData, methods],
    );

    const messageBoxProp: IModalFormBuilderProps = {
        header: props.displayName,
        showOkButton: showOkButton,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        size: props.modalSize,
        isBusy: loading,
        onSubmit: onSubmit,
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
        errorText: errorText,
        warningText: warningText,
        formMethods: methods,
        fields: fieldsData!,
        rows: form?.view,
        createAttachModel: attachesCreateModel,
        mailRemindersBlockModel: mailRemindersBlockModel,
        showContent: showOkButton,
        docId: props.docId,
    };

    return fieldsData ? <ModalFormBuilder {...messageBoxProp}></ModalFormBuilder> : <Preloader size="l" />;
};
