import React, { useEffect, useState } from 'react';
import { IDocumentTable, IMergeRows, IStyle, IStyles, ITableColumn } from '@models/Forms/IForms';
import './MergeRowsForm.scss';
import { MdClose } from 'react-icons/md';
import Modal from '../../Modal';
import Button from '@atoms/Button';
import { IField } from '@models/IFormData';
import TableView from '../TableView';
import DataSource from 'devextreme/data/data_source';
import { IMergeRowsAction } from '../../../../models/Forms/IForms';

export interface IGroupResult {
    result: any;
    items: any[];
}

export interface MergeRowsFormProps {
    table: IDocumentTable;
    onClose: (e: any) => void;
    onSubmit: (data: any[]) => Promise<void>;
    title: string;
    cellRenderSwitcher: (p: any, column: any, rowParent?: any) => Promise<React.ReactNode>;
    visibilityColumn: (rules: string, rowParent?: any) => Promise<boolean>;
    getParentFields: () => IField[];
    settings: IMergeRows;
    ds: DataSource;
}

const MergeRowsForm: React.FC<MergeRowsFormProps> = ({
    table,
    onClose,
    onSubmit,
    title,
    cellRenderSwitcher,
    visibilityColumn,
    getParentFields,
    settings,
    ds,
}: MergeRowsFormProps) => {
    const [mergedRows, setMergedRows] = useState<Record<string, IGroupResult>>();
    const [scheme, setScheme] = useState<IDocumentTable>();
    const [data, setData] = useState<any[]>();
    const activated = React.useRef(false);

    const InitData = () => {
        let scheme = JSON.parse(JSON.stringify(table)) as IDocumentTable;
        let columns = JSON.parse(JSON.stringify(settings.columns.tableColumn)) as ITableColumn[];
        let subScheme = { ...table };
        subScheme.tables = [];
        subScheme.key = '|Merged';
        subScheme.tableColumn = columns;
        subScheme.tableColumnAbook =
            subScheme.tableColumnAutoComplete =
            subScheme.tableColumnCalc =
            subScheme.tableColumnDict =
            subScheme.tableColumnExDataSource =
                [];
        scheme.tables = [subScheme];
        scheme.tableColumn = columns;
        scheme.tableColumnAbook =
            scheme.tableColumnAutoComplete =
            scheme.tableColumnCalc =
            scheme.tableColumnDict =
            scheme.tableColumnExDataSource =
                [];
        settings.actions.actions.forEach((action: IMergeRowsAction) => {
            let index = scheme.tableColumn.findIndex((x) => x.key == action.column);
            if (index > -1) {
                setStyle(scheme.tableColumn[index]);
            }
        });
        setScheme(scheme);
        let store = ds.store();
        let keyStore = store.key() as string;
        let items = [...(store as any)._array];
        const map = {} as Record<string, IGroupResult>;

        if (items && settings.keys) {
            for (const elem of items) {
                let keyElem = getKeys(settings.keys.keys, elem);

                if (map[keyElem]) {
                    map[keyElem].items.push(elem);
                    settings.actions.actions.forEach((action: IMergeRowsAction) => {
                        switch (action.type) {
                            case 'sum':
                                map[keyElem].result[action.column] += elem[action.column];
                                break;
                            case 'concat':
                                map[keyElem].result[action.column] =
                                    map[keyElem].result[action.column] +
                                    (map[keyElem].result[action.column] &&
                                    map[keyElem].result[action.column] !== null &&
                                    map[keyElem].result[action.column] !== ''
                                        ? '|'
                                        : '') +
                                    elem[action.column];
                                break;
                            default:
                                break;
                        }
                    });
                } else {
                    let copy = { ...elem };
                    let arr = [] as any[];
                    arr.push({ ...elem });
                    map[keyElem] = { result: copy, items: arr } as IGroupResult;
                }
            }
        }
        let result = [] as any[];
        let index = 1;
        for (const key in map) {
            let item = { ...map[key].result };
            item[keyStore] = index;
            if (map[key].items.length > 1) {
                item['|Merged'] = map[key].items;
            } else {
                item['|Merged'] = null;
            }
            index++;
            result.push(item);
        }
        setData(result);
        setMergedRows(map);
    };

    const getKeys = (keys: string[], item: any) => {
        let result = '';
        for (let index = 0; index < keys.length; index++) {
            const x = keys[index];
            result += item[x] ? item[x].toString() : '';
        }
        return result;
    };

    const setStyle = (column: any) => {
        if (column) {
            let style = { condition: 'true', cellStyle: 'calc-column' } as IStyle;
            column.styles = { style: [style] } as IStyles;
        }
    };

    useEffect(() => {
        activated.current = true;
        InitData();
        return () => {
            activated.current = false;
        };
    }, []);

    const onApply = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        let result = [] as any[];
        let index = 1;
        for (const key in mergedRows) {
            let item = { ...mergedRows[key].result };
            item['|NUM'] = index;
            index++;
            result.push(item);
        }
        await onSubmit(result);
        onClose(e);
    };

    const modalFooter = (
        <div className={'dictModal_footer'}>
            <div className={'left'}>
                <Button onClick={onApply} buttonType="primary" size="s">
                    Применить
                </Button>

                <Button onClick={onClose} buttonType="light" size="s">
                    Отменить
                </Button>
            </div>
        </div>
    );

    return (
        <Modal size="content" custom={true}>
            <div className="rf-modal__wrapper">
                <div className="rf-modal__header">
                    <div className="box">
                        {title}
                        <div className="left">
                            <Button
                                startAdornment={<MdClose size="24" />}
                                buttonType="text"
                                size="xs"
                                onClick={onClose}
                            />
                        </div>
                    </div>
                </div>

                <div className="rf-modal__content">
                    <div className={'merge-form-modal-body'}>
                        <div className="merge-form-content">
                            {data && scheme && (
                                <TableView
                                    table={scheme}
                                    value={data}
                                    getParentFields={getParentFields}
                                    cellRenderSwitcher={cellRenderSwitcher}
                                    visibilityColumn={visibilityColumn}
                                />
                            )}
                        </div>
                    </div>
                </div>

                <div className={'rf-modal__footer'}>{modalFooter}</div>
            </div>
        </Modal>
    );
};

export default MergeRowsForm;
