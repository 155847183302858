import React, { HTMLProps, useState } from 'react';
import 'focus-visible';
import './Pagination.scss';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import classNames from 'classnames';
import Input from '../../Input';
import { IDebounceResult } from '@/types/projects.types';

export interface IPaginationProps extends HTMLProps<HTMLInputElement> {
    /** Количество страниц */
    count: number;
    /** Функция получения текущей страницы */
    getCurrentPage?: (page: number) => void;
    /** Недоступные страницы */
    disabledPages?: number[];
    /** Disable пагинацию  */
    disabled?: boolean;
    /** Простая пагинацию  */
    isSimple?: boolean;
    startingPage?: number;
    /** CSS класс */
    className?: string;
    hidePageInput?: boolean;
}

const Pagination: React.FC<IPaginationProps> = ({
    count,
    disabled = false,
    isSimple = false,
    hidePageInput = false,
    getCurrentPage = () => {},
    disabledPages = [],
    startingPage = 1,
    className,
}: IPaginationProps) => {
    const FIRST_PAGE_LABEL = 1;
    const MIN_PAGE_NUMBER = 1;
    const STEP = 1;
    const STEP_BEFORE_SHOWING_DOTS = 4;
    const PAGES_WITHOUT_DOTS = 7;
    const PAGES_WITHOUT_NUMBER_SELECT = 20;

    const [currentPage, setCurrentPage] = useState(() => {
        let initialPage = startingPage;
        while (disabledPages.includes(initialPage) && initialPage <= count) {
            initialPage += STEP;
        }
        return initialPage;
    });

    const firstEllipsisCondition = currentPage > STEP_BEFORE_SHOWING_DOTS;
    const secondEllipsisCondition = currentPage <= count - STEP_BEFORE_SHOWING_DOTS;

    /** клик по номеру страницы */
    const handleClickByPage = (newPage: number) => () => {
        if (!disabledPages.includes(newPage) && !disabled) {
            setCurrentPage(newPage);
            getCurrentPage(newPage);
        }
    };

    /** клик по левому шеврону */
    const handleClickByLeftChevron = () => {
        let newPage = currentPage - STEP;
        while (disabledPages.includes(newPage) && newPage >= MIN_PAGE_NUMBER) {
            newPage -= STEP;
        }

        if (newPage >= MIN_PAGE_NUMBER && !disabled) {
            setCurrentPage(newPage);
            getCurrentPage(newPage);
        }
    };

    /** клик по правому шеврону */
    const handleClickByRightChevron = () => {
        let newPage = currentPage + STEP;
        while (disabledPages.includes(newPage) && newPage <= count) {
            newPage += STEP;
        }

        if (newPage <= count && !disabled) {
            setCurrentPage(newPage);
            getCurrentPage(newPage);
        }
    };

    /** ввод страницы вручную */
    const handleInsertPage = (result: IDebounceResult) => {
        const page = Number(result.debounceString);

        if (!page && !disabled) {
            setCurrentPage(MIN_PAGE_NUMBER);
            getCurrentPage(MIN_PAGE_NUMBER);
        }

        if (page >= MIN_PAGE_NUMBER && page <= count) {
            setCurrentPage(page);
            getCurrentPage(page);
        }
    };

    /** отрисовать номер странички */
    const getPageLabel = (label: number) => {
        const pageDisabled = disabled || disabledPages.includes(label);

        return (
            <button
                disabled={pageDisabled}
                className={classNames(
                    isSimple ? 'rf-pagination__label-simple' : 'rf-pagination__label',
                    label === currentPage && `rf-pagination__label${isSimple ? '-simple' : ''}--selected`,
                    pageDisabled && 'rf-pagination__label--disabled',
                )}
                key={label}
                onClick={handleClickByPage(label)}
            >
                {isSimple ? null : <span className="rf-pagination__label-text">{label}</span>}
            </button>
        );
    };

    /** отрисовать троеточие */
    const getEllipsis = () => (
        <div className="rf-pagination__ellipsis">
            <span> ... </span>
        </div>
    );

    /** отрисовать три номера страничек */
    const getThreePageLabel = (firstLabel: number, secondLabel: number, thirdLabel: number) => (
        <>
            {getPageLabel(firstLabel)}
            {getPageLabel(secondLabel)}
            {getPageLabel(thirdLabel)}
        </>
    );

    /** отрисовать средние лейблы когда с двух сторон троеточие */
    const getMiddleNumbers = () => {
        if (firstEllipsisCondition && secondEllipsisCondition) {
            return getThreePageLabel(currentPage - STEP, currentPage, currentPage + STEP);
        }

        const doubleStep = STEP * 2;
        const tripleStep = STEP * 3;
        const quadrupleStep = STEP * 4;

        if (currentPage <= STEP_BEFORE_SHOWING_DOTS) {
            return getThreePageLabel(
                FIRST_PAGE_LABEL + doubleStep,
                FIRST_PAGE_LABEL + tripleStep,
                FIRST_PAGE_LABEL + quadrupleStep,
            );
        }

        if (currentPage > count - STEP_BEFORE_SHOWING_DOTS) {
            return getThreePageLabel(count - quadrupleStep, count - tripleStep, count - doubleStep);
        }
    };

    const leftChevronDisabled = disabled || currentPage === FIRST_PAGE_LABEL;
    const rightChevronDisabled = disabled || currentPage === count;

    //setCurrentPage(initialPage);

    return (
        <div
            className={classNames(
                'rf-pagination',
                disabled && 'rf-pagination--disabled',
                isSimple && 'rf-pagination__simple',
                className,
            )}
        >
            {!isSimple && (
                <button
                    disabled={leftChevronDisabled}
                    className={classNames(
                        'rf-pagination__chevron-wrapper',
                        'rf-pagination__chevron-left',
                        leftChevronDisabled && 'rf-pagination__chevron-wrapper--disabled',
                    )}
                    onClick={handleClickByLeftChevron}
                >
                    <MdChevronLeft size={'20px'} />
                </button>
            )}
            {count <= PAGES_WITHOUT_DOTS ? (
                Array.from({ length: count }, (item, index: number) => getPageLabel(index + 1))
            ) : (
                <>
                    {getPageLabel(FIRST_PAGE_LABEL)}
                    {firstEllipsisCondition ? getEllipsis() : getPageLabel(FIRST_PAGE_LABEL + STEP)}
                    {getMiddleNumbers()}
                    {secondEllipsisCondition ? getEllipsis() : getPageLabel(count - STEP)}
                    {getPageLabel(count)}
                </>
            )}
            {!isSimple && (
                <button
                    disabled={rightChevronDisabled}
                    className={classNames(
                        'rf-pagination__chevron-wrapper',
                        'rf-pagination__chevron-right',
                        rightChevronDisabled && 'rf-pagination__chevron-wrapper--disabled',
                    )}
                    onClick={handleClickByRightChevron}
                >
                    <MdChevronRight size={'20px'} />
                </button>
            )}
            {count > PAGES_WITHOUT_NUMBER_SELECT && !hidePageInput && (
                <Input className={'rf-pagination__input'} placeholder="№ страницы" onDebounce={handleInsertPage} />
            )}
        </div>
    );
};

export default Pagination;
