import React, { FC, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import './TreeModeControl.scss';
import { DictionariesService } from '@services/DictionariesService';
import { IDictionaryData } from '@models/dictionary/IDictionaryData';
import { IDictFilter } from '@models/Forms/IForms';
import DevExpressTreeList from '@atoms/DevExpress/TreeList/DevExpressTreeList';
import TreeList, {
    Column,
    ColumnFixing,
    FilterRow,
    Paging,
    RemoteOperations,
    Scrolling,
    Selection,
} from 'devextreme-react/tree-list';
import { simulateMouseClick } from '@utils/helpers';
import { IDictSettings } from '@atoms/Dictpicker/IDictSettings';

import DataSource from 'devextreme/data/data_source';
import {
    CellPreparedEvent,
    ContentReadyEvent,
    EditorPreparingEvent,
    InitializedEvent,
    Node,
    NodesInitializedEvent,
    RowPreparedEvent,
} from 'devextreme/ui/tree_list';
import {
    filterResponseVoid,
    getColumnDataTypeByFieldDataType,
    getColumnFilterOperationsByColumnDataType,
    getLoadOptionsQuery,
    onCellHoverChanged,
    setDataValueAttr,
} from '@utils/dataGridUtils';
import { IDictpickerRefActions } from '@atoms/Dictpicker/Dictpicker';
import { ValueType } from '@/types/ValueType';
import { ConfigService } from '@/configuration/services/configService';
import { validateLoadedData } from '@utils/helpersDictPicker';
import { useUserScripts } from '@hooks/useUserScripts';

export interface ITreeModeControlProp extends IDictSettings {
    onSelectedRowItems: (value: IDictionaryData[]) => void;
    onSelectedKeys: (value: string[]) => void;
    dictAttributes: string[];
    filterResponse: IDictFilter;
    maxStructLevel: number;
    controlRef: React.Ref<IDictpickerRefActions>;
}

const TreeModeControl: FC<ITreeModeControlProp> = (p: ITreeModeControlProp) => {
    const config = ConfigService.get();
    const applyFilterOnClick = config.application.applyGridFilterOnClick ?? false;

    const tree = () => {
        return treeRef?.current?.instance;
    };

    useImperativeHandle(p.controlRef, () => ({
        reset: () => {
            treeRef?.current?.instance.refresh();
        },
        setSelected: (items: string[]) => {
            //setSelectedItems(items);
        },
    }));

    /**
     * TODO Удалить после правки бэкенда
     * TODO Костыль для раскрытия узлов при откртыии словаря с учётом предиката (fix перед релизом)
     */
    const [isFirstLoadReady, setIsFirstLoadReady] = useState<boolean>(false);

    useEffect(() => {
        /**
         * TODO Удалить после правки бэкенда
         */
        if (isFirstLoadReady) {
            setIsFirstLoadReady(false);
        }

        // const expandAndSelectItems = async (selected: IDictionaryData[]) => {
        //     selected.forEach((item) => {
        //         let ids = item.fullId.split('/');
        //         if (ids.length >= 2) {
        //             ids.pop();
        //             ids.forEach((id) => {
        //                 tree()
        //                     ?.expandRow(id)
        //                     .then((_) => {
        //                         tree()?.selectRows(
        //                             selected.map((item) => item.id),
        //                             true
        //                         );
        //                     });
        //             });
        //         }
        //     });
        // };

        // const getTreeItemsByCode = async () => {
        //     DictionariesService.getTreeItemsByCode(p.dictName, p.selected).then((response) => {
        //         let selected = response.data;
        //         expandAndSelectItems(selected);
        //     });
        // };

        // if (p.dictAttributes) {
        //     if (p.maxStructLevel !== undefined && p.selected && p.selected.length > 0) {
        //         getTreeItemsByCode().catch(console.error);
        //     }
        // }
    }, [p.dictName]);

    const getUrlParam = (loadOptions: any) => {
        let params = '?';
        params += getLoadOptionsQuery(loadOptions);
        return params;
    };

    const treeSource = useMemo(() => {
        return new DataSource<IDictionaryData, string>({
            /**
             * TODO Перенести фильтрацию из метода load в пост-обработку (корректно работают внутренние счётчики)
             * */
            //postProcess: treePostProcessFunction,

            /**
             * TODO Для TreeList перестаёт работать раскрытие узлов при применении фильтра, починить
             * */
            //filter: filterRemoteQuery.length > 0 ? filterRemoteQuery : null,

            requireTotalCount: false,
            key: 'id',
            async load(loadOptions: any) {
                let params = getUrlParam(loadOptions);
                params += '&formValues=' + (await p.getFormValuesAsync());
                if (p.predicatesCache) {
                    params += '&predicates=' + p.predicatesCache;
                }
                if (p.loadMode) {
                    params += '&loadMode=' + p.loadMode;
                }
                const parentIdsParam = loadOptions.parentIds ? loadOptions.parentIds : '';
                return DictionariesService.getTreeItems(p.dictName, parentIdsParam, params).then((response) => {
                    validateLoadedData(response.data.data, p.loadedDataValidators);
                    if (p.filterResponse) {
                        let _data = filterResponseVoid(response.data.data, p.filterResponse);
                        response.data.totalCount = _data.length;
                        response.data.data = _data;
                    }

                    return response.data;
                });
            },
        });
    }, [p.dictName, p.filterResponse]);

    /**
     * Фильтрация элементов и управление видимостью элементов
     * */
    const filterNodesByScript = (nodes: Node[], script: string) => {
        nodes?.forEach((child, index) => {
            child.visible = filterNodeByScript(child, script)!;
            setNodesVisibility(child.children, child.visible);
        });
    };

    /**
     *
     * */
    const filterNodeByScript = (node: Node<IDictionaryData, string>, script: string) => {
        /**
         * WARNING! Begin sections of functions for templates, do not rename
         * */
        let subFunc = ` 
        function code (n = node){
            return n.data?.code;
        };
    
        function field (name, n = node) {
            return getFieldValue(n, name);
        };
        
        function childrens (n = node) {
            return n.children;
        };
    
        function intersect (array1, array2){
            return array1 && array2 && array1.some((item) => array2.includes(item));
        };
        `;
        /**
         * WARNING! End sections of functions for templates
         * */

        return eval(subFunc + script);
    };

    const getFieldValue = (node: Node<IDictionaryData, string>, name: string) => {
        return node.data?.fields.find((field) => field.name === name)?.value;
    };

    const checkIsRowSelectable = useCallback(
        (data?: IDictionaryData) => {
            if (p.selectableLevels) {
                if (p.selectableLevels === 'last') {
                    return !data?.hasChild;
                }
                if (p.selectableLevels === 'first') {
                    return data?.parent === '';
                }
            }
            return true;
        },
        [p.selectableLevels],
    );

    const onTreeInitialized = useCallback((e: InitializedEvent<IDictionaryData, string>) => {}, []);

    const setNodesVisibility = (nodes?: Node[], visibility?: boolean) => {
        nodes?.forEach((node) => {
            setNodesVisibility(node.children, visibility);
            node.visible = visibility ?? false;
        });
    };

    const onTreeNodesInitialized = useCallback(
        (e: NodesInitializedEvent<IDictionaryData, string>) => {
            if (p.filterResponse?.script) {
                filterNodesByScript(e.root.children!, p.filterResponse?.script);
            }

            if (p.visibleLevels && p.visibleLevels !== '') {
                if (p.visibleLevels === 'first') {
                    setNodesHasChildrenFlag(e.root, 1);
                } else {
                    let maxLevel = parseInt(p.visibleLevels);
                    if (!Number.isNaN(maxLevel) && maxLevel >= 1) {
                        setNodesHasChildrenFlag(e.root, maxLevel);
                    } else {
                        console.error(`Некорректно указана настройка 'visibleLevels' (${p.visibleLevels})`);
                    }
                }
            }
        },
        [p.filterResponse, p.visibleLevels],
    );

    /**
     * Рекурсивно проходит по дочерним элементам узла и отключает раскрытие узла при превышении максимального уровня вложенности (который нумеруется с 1)
     * @param node
     * @param maxLevel
     */
    const setNodesHasChildrenFlag = (node: Node<IDictionaryData, string>, maxLevel: number) => {
        node.children?.forEach((child) => {
            if (child.level + 1 >= maxLevel) {
                child.hasChildren = false;
                // Снимаем флаг наличия дочерних элементов
                if (child.data) {
                    child.data.hasChild = false;
                }
            } else {
                setNodesHasChildrenFlag(child, maxLevel);
            }
        });
    };

    const onTreeContentReady = useCallback((e: ContentReadyEvent<IDictionaryData, string>) => {
        /**
         * TODO Удалить после правки бэкенда
         */
        setIsFirstLoadReady(true);
    }, []);

    /**
     * TODO Удалить после правки бэкенда
     */
    useEffect(() => {
        const expandAndSelectItems = async (selected: IDictionaryData[]) => {
            selected.forEach((item) => {
                let ids = item.fullId.split('/');
                if (ids.length >= 2) {
                    ids.pop();
                    ids.forEach((id) => {
                        tree()
                            ?.expandRow(id)
                            .then((_) => {
                                tree()
                                    ?.selectRows(
                                        selected.map((item) => item.id),
                                        true,
                                    )
                                    .then(() => {
                                        // перерисовываем грид, т.к. не проставляются чекбоксы
                                        tree()?.repaint();
                                    });
                            });
                    });
                } else {
                    tree()
                        ?.selectRows([item.fullId], true)
                        .then(() => {
                            // перерисовываем грид, т.к. не проставляются чекбоксы
                            tree()?.repaint();
                        });
                }
            });
        };

        const getTreeItemsByCode = async () => {
            DictionariesService.getTreeItemsByCode(p.dictName, p.selected).then((response) => {
                let selected = response.data;
                expandAndSelectItems(selected);
            });
        };

        if (isFirstLoadReady) {
            if (p.dictAttributes) {
                if (p.maxStructLevel !== undefined && p.selected && p.selected.length > 0) {
                    getTreeItemsByCode().catch(console.error);
                }
            }
        }
    }, [isFirstLoadReady]);

    const onTreeEditorPreparing = useCallback(
        (e: EditorPreparingEvent<IDictionaryData, string>) => {
            if (applyFilterOnClick && e.parentType === 'filterRow') {
                e.editorOptions.onEnterKey = function () {
                    // применение фильтра по нажатию Enter
                    simulateMouseClick(e.element.querySelector('.dx-apply-button')!);
                };
            }
            if (e.parentType === 'dataRow') {
                e.editorOptions.disabled = !checkIsRowSelectable(e.row?.data);
            }
        },
        [applyFilterOnClick, checkIsRowSelectable],
    );

    const onSelectionChanged = useCallback(
        (e: any) => {
            let property = 'id';
            if (!p.isMultiple) {
                if (e.currentSelectedRowKeys.length > 0) {
                    let item = e.selectedRowsData.pop();
                    e.component.selectRows([item[property]], false);
                    p.onSelectedKeys([item[property]]);
                    p.onSelectedRowItems([item!]);
                } else if (e.selectedRowKeys.length == 0) {
                    p.onSelectedKeys([]);
                    p.onSelectedRowItems([]);
                }
            } else {
                p.onSelectedKeys(e.selectedRowKeys);
                p.onSelectedRowItems(e.selectedRowsData);
            }
        },
        [p],
    );

    const treeRef = useRef<TreeList>(null);

    const codeColumn = useMemo(() => {
        let attr = p.gridAttribute?.attrs.find((a) => {
            return a.key === 'code';
        });
        if (attr) {
            return (
                <Column
                    key={'code'}
                    dataField={'code'}
                    caption={attr.name}
                    width={attr.width}
                    defaultSortOrder={attr.sortOrder}
                    cssClass={attr.cssClasses?.join(' ')}
                />
            );
        }
        if (p.gridAttribute == undefined) {
            return <Column key={'code'} dataField={'code'} caption="Код" width="auto" />;
        }
        return <></>;
    }, [p.gridAttribute]);

    const cellUrlRender = useCallback(
        (param: any) => {
            if (p.isFormData && param.data.url && param.data.urlAttributes.indexOf(param.column.caption) !== -1) {
                return (
                    <a target="_blank" href={param.data.url}>
                        {param.value}
                    </a>
                );
            } else {
                return <>{param.value}</>;
            }
        },
        [p.isFormData],
    );

    // сортируем столбцы в порядке как они заданы в шаблоне
    const gridAttributesKeys = useMemo(
        () => p.gridAttribute?.attrs.map((val) => val.key) ?? [],
        [p.gridAttribute?.attrs],
    );
    const sortedDictAttributes = useMemo(
        () =>
            [...p.dictAttributes]?.sort(
                (a, b) =>
                    (gridAttributesKeys.indexOf(a) === -1 ? Infinity : gridAttributesKeys.indexOf(a)) -
                    (gridAttributesKeys.indexOf(b) === -1 ? Infinity : gridAttributesKeys.indexOf(b)),
            ),
        [gridAttributesKeys, p.dictAttributes],
    );

    const getSchemeColumns = useMemo(() => {
        return sortedDictAttributes.map((schemeColumn, _) => {
            const index = p.dictAttributes.indexOf(schemeColumn);
            const attr = p.gridAttribute?.attrs.find((a) => !a.joined && a.key === schemeColumn);
            if (attr !== undefined) {
                return (
                    <Column
                        key={`col_${index}`}
                        allowFiltering={true}
                        caption={attr?.name}
                        dataField={`fields[${index}].value`}
                        dataType={'string'}
                        width={attr?.width}
                        visible={true}
                        allowSorting={true}
                        filterOperations={getColumnFilterOperationsByColumnDataType(
                            getColumnDataTypeByFieldDataType((attr?.type ? attr?.type : 'string') as ValueType),
                        )}
                        encodeHtml={true}
                        cellRender={cellUrlRender}
                        cssClass={attr.cssClasses?.join(' ')}
                        defaultSortOrder={attr.sortOrder}
                    />
                );
            }
            if (p.gridAttribute === undefined) {
                return (
                    <Column
                        key={`col_${index}`}
                        allowFiltering={true}
                        caption={schemeColumn}
                        dataField={`fields[${index}].value`}
                        dataType={'string'}
                        visible={true}
                        allowSorting={true}
                        filterOperations={['contains']}
                        encodeHtml={true}
                    />
                );
            }
            return <React.Fragment key={index}></React.Fragment>;
        });
    }, [cellUrlRender, p.dictAttributes, p.gridAttribute, sortedDictAttributes]);

    const joinedColumns = useMemo(() => {
        const attrs = p.gridAttribute?.attrs.filter((a) => a.joined);
        return attrs?.map((attr, i) => {
            return (
                <Column
                    key={`col_${i}`}
                    allowFiltering={true}
                    caption={attr?.name}
                    dataField={`joined[${attr.collection}][${attr.name}]`}
                    dataType={'string'}
                    width={attr?.width}
                    visible={true}
                    allowSorting={true}
                    filterOperations={['contains']}
                    encodeHtml={true}
                    cellRender={cellUrlRender}
                    cssClass={attr.cssClasses?.join(' ')}
                    defaultSortOrder={attr.sortOrder}
                />
            );
        });
    }, [cellUrlRender, p.gridAttribute?.attrs]);

    const getWidth = useCallback(() => {
        let w = 100;
        p.gridAttribute?.attrs.forEach((x) => {
            w = w + x.width;
        });
        return w;
    }, [p.gridAttribute?.attrs]);

    const onRowClick = useCallback(
        (e: any) => {
            if (checkIsRowSelectable(e.data)) {
                if (e.component.getSelectedRowKeys().indexOf(e.key) > -1) {
                    e.component.deselectRows([e.key]);
                } else {
                    e.component.selectRows([e.key], true);
                }
            }
        },
        [checkIsRowSelectable],
    );

    // -------------------------------------------------------------------------------------------------------------------
    // Пользовательские скрипты
    // -------------------------------------------------------------------------------------------------------------------

    const { onRowPreparedScript, onCellPreparedScript } = useUserScripts(
        p.userScripts?.onRowPrepared,
        p.userScripts?.onCellPrepared,
    );

    const onRowPrepared = useCallback(
        async (row: RowPreparedEvent) => {
            if (row.rowType === 'data' && onRowPreparedScript) {
                try {
                    eval(onRowPreparedScript);
                } catch (error) {
                    console.log('onRowPrepared. Ошибка выполнения скрипта. Подробности ниже.');
                    console.error(error);
                }
            }
        },
        [onRowPreparedScript],
    );

    const onCellPrepared = useCallback(
        async (cell: CellPreparedEvent) => {
            if (cell.rowType === 'data') {
                setDataValueAttr(cell);
            }

            if (cell.rowType === 'data' && onCellPreparedScript) {
                try {
                    eval(onCellPreparedScript);
                } catch (error) {
                    console.log('onCellPrepared. Ошибка выполнения скрипта. Подробности ниже.');
                    console.error(error);
                }
            }
        },
        [onCellPreparedScript],
    );

    // -------------------------------------------------------------------------------------------------------------------

    if (sortedDictAttributes && p.maxStructLevel !== undefined) {
        return (
            <DevExpressTreeList
                key={'tree'}
                width={getWidth}
                dataSource={treeSource}
                hoverStateEnabled={true}
                columnHidingEnabled={false}
                showColumnHeaders={true}
                columnAutoWidth={true}
                columnMinWidth={30}
                allowColumnReordering={false}
                allowColumnResizing={true}
                columnResizingMode="widget"
                noDataText={'Нет строк'}
                ref={treeRef}
                onEditorPreparing={onTreeEditorPreparing}
                onInitialized={onTreeInitialized}
                onSelectionChanged={onSelectionChanged}
                onNodesInitialized={onTreeNodesInitialized}
                onContentReady={onTreeContentReady}
                onRowPrepared={onRowPrepared}
                onCellPrepared={onCellPrepared}
                // defaultSelectedRowKeys={selectedDefTreeKeys}
                parentIdExpr="parent"
                hasItemsExpr="hasChild"
                keyExpr="id"
                rootValue=""
                onRowClick={onRowClick}
                onCellHoverChanged={onCellHoverChanged}
            >
                <Selection mode="multiple" allowSelectAll={false} />
                {codeColumn}
                {getSchemeColumns}
                {joinedColumns}
                <ColumnFixing enabled={true} />
                <FilterRow
                    showOperationChooser={true}
                    visible={true}
                    applyFilter={applyFilterOnClick ? 'onClick' : 'auto'}
                />
                <RemoteOperations filtering={true} sorting={false} />
                <Scrolling mode="virtual" />
                <Paging enabled={true} defaultPageSize={10} />
            </DevExpressTreeList>
        );
    }

    return <></>;
};

export default TreeModeControl;
