import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { IVoteWithSignResult, IVotingWithSignModel } from '@models/document/actionsExecution/IVotingWithSignModel';
import { IRunTaskModel } from '@models/document/actionsExecution/IRunTaskModel';
import { IForms } from '@models/Forms/IForms';
import { IFormData } from '@models/IFormData';
import { IDocumentData } from '@models/document/IDocumentData';
import { IOpenDocumentModel } from '@models/document/actionsExecution/IOpenDocumentModel';
import { ISendToEtpModel } from '@models/document/actionsExecution/ISendToEtpModel';
import { ICopyAttachmentsModel, ICopyAttachmentsResult } from '@models/document/actionsExecution/ICopyAttachmentsModel';
import { ICreateDocByTemplateModel } from '@models/document/actionsExecution/ICreateDocByTemplateModel';
import { ISelectedFiles } from '@/components/atoms/AttachFilesBlock/AttachFilesBlock';
import { ICreateDocByTemplateDialogModel } from '@models/document/actionsExecution/ICreateDocByTemplateDialogModel';
import { IMailReminderModel } from '@models/document/actionsExecution/IMailReminderModel';
import { ICreateDocumentLinkModel } from '@models/document/actionsExecution/ICreateDocumentLinkModel';
import { ISendToEisModel } from '@models/document/actionsExecution/ISendToEisModel';
import { IDocumentResult } from '@models/document/IDocumentResult';
import { IOpenUrlModel } from '@models/document/actionsExecution/IOpenUrlModel';
import { ICreateReportModel } from '@models/document/actionsExecution/ICreateReportModel';
import { IVoteResult, IVotingModel } from '@models/document/actionsExecution/IVotingModel';
import { ICreateDocumentLinkData } from '@/models/document/actionsExecution/ICreateDocumentLinkData';
import { IFillDocFromTemplateModel } from '@models/fillDocFromTemplate/IFillDocFromTemplateModel';
import { ISmartBusinessResult } from '@/models/smartBusiness/ISmartBusinessResult';
import { IActionResult } from '@/models/actions/IActionResult';

export class DocumentExecutionService {
    documentId: string;

    constructor(docId: string) {
        this.documentId = docId;
    }

    //Отправить голос с подписью по вопросу
    async sendSignedVote(actKey: string, voteData: Record<string, IVoteWithSignResult>): Promise<AxiosResponse> {
        return $api.post(`/documentExecution/sendSignedVote/${this.documentId}/${actKey}`, voteData);
    }

    //Отправить голос по вопросу
    async sendVote(actKey: string, voteData: Record<string, IVoteResult>): Promise<AxiosResponse> {
        return $api.post(`/documentExecution/sendVote/${this.documentId}/${actKey}`, voteData);
    }

    //сохранение результата анализа в Умном Бизнесе
    async saveAnalysisSmartBusiness(
        actKey: string,
        data: ISmartBusinessResult,
        protocol: File,
        report: File,
    ): Promise<AxiosResponse> {
        let formData = new FormData();
        formData.append('data', JSON.stringify(data));
        formData.append('protocol', protocol as File);
        formData.append('report', report as File);
        return $api.post(`/documentExecution/saveAnalysisSmartBusiness/${actKey}/${this.documentId}`, formData);
    }

    //Запустить документ по маршруту
    async runRoute(actionKey: string): Promise<AxiosResponse> {
        return $api.post(`/documentExecution/runRoute/${this.documentId}/${actionKey}`);
    }

    //Получить модель для голосования/подписания по вопросу (инфа для модальки + json для подписания)
    async getVotingWithSignModel(actionKey: string): Promise<AxiosResponse<Record<string, IVotingWithSignModel>>> {
        return $api.get<Record<string, IVotingWithSignModel>>(
            `/documentExecution/getVotingWithSignModel/${this.documentId}/${actionKey}`,
        );
    }

    //Получить модель для голосования по вопросу
    async getVotingModel(actionKey: string): Promise<AxiosResponse<Record<string, IVotingModel>>> {
        return $api.get<Record<string, IVotingModel>>(
            `/documentExecution/getVotingModel/${this.documentId}/${actionKey}`,
        );
    }

    //Удаляет текущий документ из базы
    async deleteDocument(actionKey: string): Promise<AxiosResponse> {
        return $api.delete(`documentExecution/deleteDocument/${this.documentId}/${actionKey}`);
    }

    //Создает новый файл для дока
    async attachFiles(actionKey: string, attachProperties: any[], files: File[]): Promise<AxiosResponse> {
        let formData = new FormData();
        attachProperties.map((prop) => formData.append('attachProps', JSON.stringify(prop)));
        files.map((f) => formData.append('attachFiles', f));

        return $api.post(`documentExecution/attachFiles/${this.documentId}/${actionKey}`, formData);
    }

    //Копирует документ
    async copyDocumentById(docId: string, actionKey: string): Promise<AxiosResponse<string>> {
        return $api.post(`documentExecution/copyDocument/${docId}/${actionKey}`);
    }

    //Копирует текущий документ
    async copyDocument(actionKey: string): Promise<AxiosResponse<string>> {
        return $api.post(`documentExecution/copyDocument/${this.documentId}/${actionKey}`);
    }

    // Запустить задачу автоимпорта/автоэкспорта
    async runAutoTask(runTaskData: IRunTaskModel): Promise<AxiosResponse<string>> {
        return $api.post(`/documentExecution/runAutoTask/${this.documentId}`, runTaskData);
    }

    //Создает новый файл для дока
    async loadFromFile(actionKey: string, file: File): Promise<AxiosResponse> {
        let formData = new FormData();
        formData.append('actionKey', actionKey as string);
        formData.append('file', file as File);
        return $api.post(`documentExecution/loadFromFile/${this.documentId}`, formData);
    }

    // Возвращает модель для формы
    async getFormBuilderScheme(actionKey: string): Promise<AxiosResponse<IForms>> {
        return $api.get<IForms>(`/documentExecution/getFormBuilderScheme/${actionKey}`);
    }

    // Сохранить набор полей в документах
    async saveDocumentsBatchEditingData(
        documentIds: string,
        actionKey: string,
        data: IFormData,
        files?: ISelectedFiles,
    ): Promise<AxiosResponse<IDocumentResult>> {
        const formData = new FormData();
        formData.append('documentIds', documentIds);
        formData.append('actionKey', actionKey);
        formData.append('data', JSON.stringify(data));

        if (files) {
            files.attachProperties.map((prop) => formData.append('attachProps', JSON.stringify(prop)));
            files.files.map((f) => formData.append('attachFiles', f));
        }

        return $api.post(`/documentExecution/saveDocumentsBatchEditingData`, formData);
    }

    //Получить модель для открытия документа (ИД документа)
    async getOpenDocumentModel(actionKey: string): Promise<AxiosResponse<IOpenDocumentModel>> {
        return $api.get<IOpenDocumentModel>(`/documentExecution/getOpenDocumentModel/${this.documentId}/${actionKey}`);
    }

    //Получить модель для открытия URL (ИД документа)
    async getOpenUrlModel(actionKey: string): Promise<AxiosResponse<IOpenUrlModel>> {
        return $api.get<IOpenUrlModel>(`/documentExecution/getOpenUrlModel/${this.documentId}/${actionKey}`);
    }

    // Возвращает модель данных для отправки на ЭТП
    async getSendToEtpModel(actionKey: string): Promise<AxiosResponse<ISendToEtpModel>> {
        return $api.get<ISendToEtpModel>(`/documentExecution/getSendToEtpModel/${this.documentId}/${actionKey}`);
    }

    // Отправка данных на ЭТП
    async sendToEtp(actionKey: string, sendToEtpData: ISendToEtpModel): Promise<AxiosResponse<string>> {
        return $api.post(`/documentExecution/sendToEtp/${this.documentId}/${actionKey}`, sendToEtpData);
    }

    // Возвращает модель данных для отправки в ЕИС
    async getSendToEisModel(actionKey: string): Promise<AxiosResponse<ISendToEisModel>> {
        return $api.get<ISendToEisModel>(`/documentExecution/getSendToEisModel/${this.documentId}/${actionKey}`);
    }

    // Отправка данных в ЕИС
    async sendToEis(actionKey: string, sendToEisData: ISendToEisModel): Promise<AxiosResponse<IActionResult>> {
        return $api.post(`/documentExecution/sendToEis/${this.documentId}/${actionKey}`, sendToEisData);
    }

    //Получить модель для копирования вложений
    async getCopyAttachmentsModel(actionKey: string): Promise<AxiosResponse<ICopyAttachmentsModel>> {
        return $api.get<ICopyAttachmentsModel>(
            `/documentExecution/getCopyAttachmentsModel/${this.documentId}/${actionKey}`,
        );
    }

    //Скопировать вложения в документ
    async copyAttachments(actionKey: string, selectedAttachments: ICopyAttachmentsResult): Promise<AxiosResponse> {
        return $api.post(`/documentExecution/copyAttachments/${this.documentId}/${actionKey}`, selectedAttachments);
    }

    // Отправка электронной почты
    async sendCustomEmails(actionKey: string): Promise<AxiosResponse> {
        return $api.post(`/documentExecution/sendCustomEmails/${this.documentId}/${actionKey}`);
    }

    //Удаляет лин документа
    async removeLink(linkDocIdToRemove: string, actionKey: string): Promise<AxiosResponse> {
        return $api.delete(`documentExecution/removeLink/${this.documentId}/${linkDocIdToRemove}/${actionKey}`);
    }

    //Выполнить переход
    async redirect(actionKey: string): Promise<AxiosResponse> {
        return $api.post(`/documentExecution/redirect/${this.documentId}/${actionKey}`);
    }

    //Получить модель для создания документа из шаблона
    async getCreateDocByTemplateModel(actionKey: string): Promise<AxiosResponse<ICreateDocByTemplateModel>> {
        return $api.get<ICreateDocByTemplateModel>(
            `/documentExecution/getCreateDocByTemplateModel/${this.documentId}/${actionKey}`,
        );
    }

    //Создает документ из шаблона
    async createDocByTemplate(actionKey: string): Promise<AxiosResponse> {
        return $api.post(`documentExecution/createDocByTemplate/${this.documentId}/${actionKey}`);
    }

    //Получить модель для создания документа из шаблона (диалоговая версия)
    async getCreateDocByTemplateDialogModel(
        actionKey: string,
    ): Promise<AxiosResponse<ICreateDocByTemplateDialogModel>> {
        return $api.get<ICreateDocByTemplateDialogModel>(
            `/documentExecution/getCreateDocByTemplateDialogModel/${this.documentId}/${actionKey}`,
        );
    }

    //Создает документ из шаблона (диалоговая версия)
    async createDocByTemplateDialog(actionKey: string, templateIds: string[]): Promise<AxiosResponse> {
        let formData = new FormData();
        formData.append('templateIds', JSON.stringify(templateIds));
        return $api.post(`documentExecution/createDocByTemplateDialog/${this.documentId}/${actionKey}`, formData);
    }

    //Получить модель для создания напоминания
    async getMailReminderModel(actionKey: string): Promise<AxiosResponse<IMailReminderModel>> {
        return $api.get<IMailReminderModel>(`/documentExecution/getMailReminderModel/${this.documentId}/${actionKey}`);
    }

    //Создает напоминание по документу
    async createMailReminder(actionKey: string, deliveryDateTime: Date, message?: string): Promise<AxiosResponse> {
        const formData = new FormData();
        formData.append('message', message ?? '');
        formData.append('deliveryDateTimeUtc', deliveryDateTime.toUTCString());
        return $api.post(`documentExecution/createMailReminder/${this.documentId}/${actionKey}`, formData);
    }

    //Получить модель для связывания документов
    async getCreateDocumentLinkModel(actionKey: string): Promise<AxiosResponse<ICreateDocumentLinkModel>> {
        return $api.get<ICreateDocumentLinkModel>(
            `/documentExecution/getCreateDocumentLinkModel/${this.documentId}/${actionKey}`,
        );
    }

    //Получить данные для связывания документов
    async getCreateDocumentLinkData(
        actionKey: string,
        parameters: string[],
    ): Promise<AxiosResponse<ICreateDocumentLinkData>> {
        return $api.get<ICreateDocumentLinkData>(
            `/documentExecution/getCreateDocumentLinkData/${this.documentId}/${actionKey}${
                parameters.length > 0 ? `?${parameters.join('&')}` : ''
            }`,
        );
    }

    //Создает связи с документами
    async createDocumentLink(
        actionKey: string,
        docIds: string[],
        partsVersionToAttach?: string[],
    ): Promise<AxiosResponse> {
        let formData = new FormData();
        formData.append('docIds', JSON.stringify(docIds));
        formData.append('partsVersionToAttach', JSON.stringify(partsVersionToAttach));
        return $api.post(`documentExecution/createDocumentLink/${this.documentId}/${actionKey}`, formData);
    }

    async batchActivitiesComplete(actionKey: string, comment?: string): Promise<AxiosResponse> {
        const formData = new FormData();
        formData.append('comment', comment ?? '');

        return $api.post(`documentExecution/batchActivitiesComplete/${this.documentId}/${actionKey}`, formData);
    }

    //Получить модель для создания отчета
    async getCreateReportModel(actionKey: string): Promise<AxiosResponse<ICreateReportModel>> {
        return $api.get<ICreateReportModel>(`/documentExecution/getCreateReportModel/${this.documentId}/${actionKey}`);
    }

    //Создает шаблон документа на основе текущего документа
    async createDocumentTemplate(actionKey: string, templateName: string): Promise<AxiosResponse<string>> {
        let formData = new FormData();
        formData.append('templateName', templateName);
        return $api.post(`documentExecution/createDocumentTemplate/${this.documentId}/${actionKey}`, formData);
    }

    //Получить модель для заполнения документа из шаблона
    async getFillDocFromTemplateModel(
        actionKey: string,
        documentData: IDocumentData,
    ): Promise<AxiosResponse<IFillDocFromTemplateModel>> {
        const formData = new FormData();
        formData.append('actionKey', actionKey);
        formData.append('documentData', JSON.stringify(documentData));

        return $api.post(`documentExecution/getFillDocFromTemplateModel/${actionKey}`, formData);
    }
}
