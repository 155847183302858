import React, { FC, ReactNode, useMemo, useState } from 'react';
import { flip, offset, shift, useFloating, useHover, useInteractions } from '@floating-ui/react';
import './Tooltip.scss';
import { extractTextFromHTML } from '@utils/helpers';
import { renderToStaticMarkup } from 'react-dom/server';
import TooltipContent, { ITooltipContentProps } from './TooltipContent';

export interface ITooltipProps extends Omit<ITooltipContentProps, 'ref' | 'children' | 'getFloatingProps'> {
    /** [1] Элемент, на который наводим, [2] Элемент с подсказкой */
    children: [ReactNode, ReactNode];
    /** Отключить показ самого тултипа
     * @default true
     */
    isVisible?: boolean;
    /** Задержка перед открытием тултипа
     * @default 0
     */
    openDelay?: number;
    /** Класс */
    className?: string;
    /** Расстояние по оси X и Y
     * @default { mainAxis: 8, crossAxis: 0 }
     */
    offsetValue?: { mainAxis?: number; crossAxis?: number };
}

const Tooltip: FC<ITooltipProps> = ({
    children,
    isVisible = true,
    background = 'white',
    openDelay = 0,
    className = '',
    offsetValue = { mainAxis: 8, crossAxis: 0 },
    ...props
}: ITooltipProps) => {
    const [content, contentTooltip] = children;

    // Extract tooltip text
    const text: string = useMemo(() => {
        try {
            return extractTextFromHTML(renderToStaticMarkup(contentTooltip));
        } catch {
            return '';
        }
    }, [contentTooltip]);

    // Floating UI setup
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement: props.position || 'top',
        middleware: [offset(offsetValue), flip(), shift({ padding: 2 })],
    });

    const hover = useHover(context, { delay: { open: openDelay, close: 0 } });

    const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

    return (
        <div
            title="" // Prevent default browser tooltip
            data-testid="rf-tooltip"
            className={`rf-tooltip rf-tooltip--${background} ${className}`}
            ref={refs.setReference}
            {...getReferenceProps()}
        >
            <div className={`rf-tooltip__reference ${className}`}>{content}</div>

            {isOpen && isVisible && text.length > 0 && (
                <TooltipContent
                    ref={refs.setFloating}
                    background={background}
                    style={floatingStyles}
                    getFloatingProps={getFloatingProps}
                    {...props}
                >
                    {contentTooltip}
                </TooltipContent>
            )}
        </div>
    );
};

export default Tooltip;
