import { AxiosResponse } from 'axios';
import $api from '@/http/index';
import { IForms } from '@models/Forms/IForms';
import { IFormData } from '@models/IFormData';
import { ISelectedFiles } from '@/components/atoms/AttachFilesBlock/AttachFilesBlock';
import { IDocumentResult } from '@models/document/IDocumentResult';
import {
    IRequestAdditionalApprovalModel,
    IRequestAdditionalApprovalSend,
} from '@models/document/activityExecutors/IRequestAdditionalApprovalModel';
import { IActivityActionModel } from '@models/document/activityExecutors/IActivityActionModel';
import { IPartsSign } from '@models/attaches/IAttachViewSignModel';
import { IActionResultModel } from '@models/document/actionsExecution/IActionResultModel';
import { IAbookOptions } from '@models/Forms/IAbookOptions';
import { IResolveExceptionModel } from '@models/document/activityExecutors/IResolveExceptionModel';

export enum ActFinishType {
    //Согласовать
    Approve = 0,
    //Согласовать с замечаниями
    Rework = 1,
    //Отклонить
    Refuse = 2,
}

export class ActivityExecutionService {
    getRequestAdditionalApprovalModel(
        actionKey: string,
        activityId: string,
    ): Promise<AxiosResponse<IRequestAdditionalApprovalModel>> {
        return $api.get<IRequestAdditionalApprovalModel>(
            `activityActions/requestAdditionalApproval/${activityId}/${actionKey}`,
        );
    }

    sendRequestAdditionalApproval(
        actionKey: string,
        activityId: string,
        data: IRequestAdditionalApprovalSend,
    ): Promise<AxiosResponse> {
        return $api.post(`activityActions/requestAdditionalApproval/${activityId}/${actionKey}`, data);
    }

    takeToWork(actionKey: string, actId: string, comment?: string): Promise<AxiosResponse> {
        const formData = new FormData();
        formData.append('comment', comment as string);
        return $api.post(`activityActions/takeToWork/${actId}/${actionKey}`, formData);
    }

    getActivityActionModel(actionKey: string, activityId: string): Promise<AxiosResponse<IActivityActionModel>> {
        return $api.get<IActivityActionModel>(`activityActions/activityAction/${activityId}/${actionKey}`);
    }

    //согласовать, согласовать с замечаниями или отклонить
    activityActionWithSign(
        actionKey: string,
        actId: string,
        signs: IPartsSign[],
        actFinishType: ActFinishType,
        comment?: string,
    ): Promise<AxiosResponse<IActionResultModel>> {
        let data = {
            comment: comment,
            signs: signs,
            actFinishType: actFinishType,
        };
        return $api.post(`activityActions/activityActionWithSign/${actId}/${actionKey}`, data);
    }

    activityAction(
        actionKey: string,
        actId: string,
        actFinishType: ActFinishType,
        comment?: string,
    ): Promise<AxiosResponse<IActionResultModel>> {
        let data = {
            comment: comment,
            actFinishType: actFinishType,
        };
        return $api.post(`activityActions/activityAction/${actId}/${actionKey}`, data);
    }

    // Сохранить набор полей в документах
    async saveBatchEditingData(
        documentIds: string,
        actionKey: string,
        activityIds: string,
        data: IFormData,
        files?: ISelectedFiles,
    ): Promise<AxiosResponse<IDocumentResult>> {
        const formData = new FormData();
        formData.append('documentIds', documentIds);
        formData.append('actionKey', actionKey);
        formData.append('activityIds', activityIds);
        formData.append('data', JSON.stringify(data));

        if (files) {
            files.attachProperties.map((prop) => formData.append('attachProps', JSON.stringify(prop)));
            files.files.map((f) => formData.append('attachFiles', f));
        }

        return $api.post(`/activityActions/saveBatchEditingData`, formData);
    }

    activityComplete(actionKey: string, actId: string, comment?: string): Promise<AxiosResponse> {
        const formData = new FormData();
        formData.append('comment', comment ?? '');

        return $api.post(`activityActions/activityComplete/${actionKey}/${actId}`, formData);
    }

    // Возвращает модель для формы
    async getFormBuilderScheme(actionKey: string, docIds :string): Promise<AxiosResponse<IForms>> {
        return $api.get<IForms>(`/activityActions/getFormBuilderScheme/${actionKey}?docIds=${docIds}`);
    }

    async getReplacePerformerModel(actionKey: string, activityId: string): Promise<AxiosResponse<IAbookOptions>> {
        return $api.get<IAbookOptions>(`activityActions/replacePerformer/${activityId}/${actionKey}`);
    }

    async saveReplacePerformer(actionKey: string, activityId: string, performerKey: string): Promise<AxiosResponse> {
        const formData = new FormData();
        formData.append('performerKey', performerKey);
        return $api.post(`activityActions/replacePerformer/${activityId}/${actionKey}`, formData);
    }

    async saveRollbackPerformer(actionKey: string, activityId: string): Promise<AxiosResponse> {
        return $api.post(`activityActions/rollbackPerformer/${activityId}/${actionKey}`);
    }

    async getDelegateModel(actionKey: string, activityId: string): Promise<AxiosResponse<IAbookOptions>> {
        return $api.get<IAbookOptions>(`activityActions/delegate/${activityId}/${actionKey}`);
    }

    async saveDelegate(
        actionKey: string,
        activityId: string,
        performerKey: string,
        comment?: string,
    ): Promise<AxiosResponse> {
        const formData = new FormData();
        formData.append('performerKey', performerKey);
        formData.append('comment', comment ?? '');
        return $api.post(`activityActions/delegate/${activityId}/${actionKey}`, formData);
    }

    async saveRollbackDelegate(actionKey: string, activityId: string, comment?: string): Promise<AxiosResponse> {
        const formData = new FormData();
        formData.append('comment', comment ?? '');
        return $api.post(`activityActions/rollbackDelegate/${activityId}/${actionKey}`, formData);
    }

    async getChangePerformerModel(actionKey: string, activityId: string): Promise<AxiosResponse<IAbookOptions>> {
        return $api.get<IAbookOptions>(`activityActions/changePerformer/${activityId}/${actionKey}`);
    }

    async saveChangePerformer(
        actionKey: string,
        activityId: string,
        performerKey: string,
        reason?: string,
    ): Promise<AxiosResponse> {
        const formData = new FormData();
        formData.append('performerKey', performerKey);
        formData.append('reason', reason ?? '');
        return $api.post(`activityActions/changePerformer/${activityId}/${actionKey}`, formData);
    }

    async getResolveExceptionModel(
        actionKey: string,
        activityId: string,
    ): Promise<AxiosResponse<IResolveExceptionModel>> {
        return $api.get<IResolveExceptionModel>(`activityActions/resolveException/${activityId}/${actionKey}`);
    }

    async saveResolveException(actionKey: string, activityId: string, templateId: string): Promise<AxiosResponse> {
        return $api.post(`activityActions/resolveException/${activityId}/${actionKey}/${templateId}`);
    }
}
