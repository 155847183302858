import React, { useEffect } from 'react';
import { TableTab } from '@models/document/IDocumentScheme';
import { LinkedDocsActionsService } from '@services/actions/LinkedDocsActionsService';
import { DataGridStateService } from '@services/DataGridStateService';
import { DocumentLinksService } from '@services/DocumentLinksService';
import { FilesService } from '@services/FilesService';
import DevExpressDataGridDynamic from '@atoms/DevExpress/DataGrid/DevExpressDataGridDynamic';
import './DocumentLinksTable.scss';
import { useParams } from 'react-router';

export interface IDocumentLinksTableProps<TFieldValues extends object = object> {
    id?: string;
    linkTable: TableTab;
    docId?: string;
}

const DocumentLinksTable = <TFieldValues extends object = object>({
    id = undefined,
    linkTable,
    docId,
}: IDocumentLinksTableProps<TFieldValues>) => {
    const { actId } = useParams();
    const serviceGrid = new DocumentLinksService(docId, actId);
    const stateService = new DataGridStateService();
    const filesService = new FilesService();
    const actionService = new LinkedDocsActionsService();
    const activated = React.useRef(false);

    useEffect(() => {
        activated.current = true;
        return () => {
            activated.current = false;
        };
    }, []);

    return (
        <div className="links-table" data-testid={id ? `link-table-${id}` : undefined}>
            <DevExpressDataGridDynamic
                key={`grid_${linkTable.key}`}
                stateService={stateService}
                dataKey={linkTable.key}
                gridKey={linkTable.key}
                linkPrefix="/document"
                service={serviceGrid}
                filesService={filesService}
                actionService={actionService}
                showBorders={false}
            />
        </div>
    );
};

export default DocumentLinksTable;
