import React, { FC, useEffect } from 'react';
import AppRouter from '@/components/AppRouter';
import LayoutMain from '@organisms/layouts/LayoutMain';
import LayoutEmpty from '@organisms/layouts/LayoutEmpty';
import { useTypedSelector } from '@hooks/useTypedSelector';
import { useActions } from '@hooks/useActions';
import Navbar from '@molecules/Navbar/Navbar';
import axios from 'axios';
import { ConfigService } from '@/configuration/services/configService';

export const TITLE_PREFIX = ConfigService.get().application.titlePrefix;

const App: FC = () => {
    const { getCurrentUser } = useActions();
    useEffect(() => {
        getCurrentUser(localStorage.getItem('frontAccessToken') as string);
    }, []);
    const isAuth = useTypedSelector((state) => state.auth.isAuth);

    useEffect(() => {
        axios
            .get('colors.json')
            .then((res) => {
                const root = document.documentElement;
                let keyColl = Object.keys(res.data);
                for (let index = 0; index < keyColl.length; index++) {
                    const propKey = keyColl[index];
                    let val = (res.data as any)[propKey];
                    root?.style.setProperty(propKey, val);
                }
            })
            .catch((err) => console.log('default theme'));
    }, []);

    return (
        <>
            <title>{`${TITLE_PREFIX}`}</title>
            {isAuth ? (
                <LayoutMain header={<Navbar />}>
                    <AppRouter />
                </LayoutMain>
            ) : (
                <LayoutEmpty>
                    <AppRouter />
                </LayoutEmpty>
            )}
        </>
    );
};

export default App;
