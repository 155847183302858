import React, { useEffect, useState } from 'react';
import Button from '@atoms/Button';
import Modal from '@atoms/Modal';
import { MdClose } from 'react-icons/md';
import { IBaseAction } from '@models/actions/IBaseAction';
import classNames from 'classnames';
import { IActionExecutor } from '@utils/actions/IActionExecutor';
import Preloader from '@atoms/Preloader';
import Hint from '@atoms/Hint';
import { AttachActionsService } from '@services/actions/AttachActionsService';
import DevExpressDataGrid from '@atoms/DevExpress/DataGrid/DevExpressDataGrid';
import { IAttachSign, IAttachViewSignModel, ISignVerificationResult } from '@models/attaches/IAttachViewSignModel';
import { Button as GridButton, Column } from 'devextreme-react/data-grid';
import { CadespluginService } from '@services/CadespluginService';
import Moment from 'moment';
import { ModalSize } from '@atoms/Modal/Modal';
import { onCellHoverChanged } from '@utils/dataGridUtils';

export class ViewSignExecutor implements IActionExecutor {
    private _modalProps?: IViewSignExecutorProps;

    run = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        raiseVisualElement?: () => void,
        modalSize?: ModalSize | null,
    ) => {
        let attachId: number = Number(objId);

        this._modalProps = {
            docId: parentId ?? '',
            attachId: attachId,
            rowData: rowData,
            options: action.options,
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'xl',
            completeHandler: completeHandler,
        };
    };

    visualElement = () => {
        return this._modalProps ? <ViewSignExecutorPropsModal {...this._modalProps} /> : <div></div>;
    };
}

interface IViewSignExecutorProps {
    docId: string;
    attachId: number;
    rowData: any;
    options: any;
    cancelButtonText: string;
    modalSize: ModalSize;
    completeHandler?: (isSucceed: boolean) => void;
}

const ViewSignExecutorPropsModal: React.FC<IViewSignExecutorProps> = (props: IViewSignExecutorProps) => {
    const [isBusy, setIsBusy] = useState<boolean>();
    const [signsModel, setSignsModel] = useState<IAttachViewSignModel>();

    const [verificationResult, setVerificationResult] = useState<ISignVerificationResult>();
    const [signUnderVerification, setSignUnderVerification] = useState<IAttachSign>();

    const service = new AttachActionsService(props.docId, '-1');

    const verifyCheck = async (signId: string) => {
        setIsBusy(true);
        try {
            let serv = new CadespluginService();
            let fileWithContent = (await service.getAttachSignsWithContentBase64(props.docId, props.attachId, signId))
                .data;
            let verifyResult = await serv.verifyData(
                fileWithContent.attachContent,
                fileWithContent.signContent,
                fileWithContent.algorithm,
            );

            setVerificationResult(verifyResult);
        } finally {
            setIsBusy(false);
        }

        // if(verifyResult?.suceeded){
        //     alert('Подпись проверена успешно');

        // }else{
        //     alert('Ошибка проверки подписи: ' + errorString);
        // }
    };

    useEffect(() => {
        setIsBusy(true);

        service.getAttachSigns(props.attachId).then((res) => {
            setSignsModel(res.data);
            setIsBusy(false);
        });
    }, []);

    return (
        <Modal
            className={classNames('modal-dlg-container')}
            size={props.modalSize}
            header={
                <>
                    <div className={classNames('box')}>
                        <div>Информация о подписях файла "{signsModel?.fileName}"</div>
                        <div className={classNames('left')}>
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xs"
                                aria-label="Закрыть окно"
                                onClick={() => {
                                    if (props.completeHandler) props.completeHandler(false);
                                }}
                                startAdornment={<MdClose size="24" />}
                            />
                        </div>
                    </div>
                </>
            }
        >
            {isBusy ? (
                <Preloader size="m" position="static" />
            ) : (
                <div>
                    {verificationResult && (
                        <Hint
                            icon="info"
                            variant={verificationResult.suceeded ? 'green' : 'red'}
                            title="Проверка подписи"
                            onClose={() => {
                                setVerificationResult(undefined);
                            }}
                            maxWidth=""
                        >
                            <div>Владелец сертификата: {verificationResult.certOwner}</div>
                            <div>Серийный номер: {verificationResult.serialNumber}</div>
                            <div>
                                Период действия: {Moment(verificationResult.validFrom).format('DD.MM.YYYY HH:mm')} -{' '}
                                {Moment(verificationResult.validTo).format('DD.MM.YYYY HH:mm')}
                            </div>
                            <div>
                                Результат:{' '}
                                {verificationResult.suceeded
                                    ? 'Подпись успешно проверена'
                                    : 'Ошибка: ' + verificationResult.errorText}
                            </div>
                            <div>Документ подписал: {signUnderVerification?.signAuthor}</div>
                            <div>
                                Дата подписи:{' '}
                                {signUnderVerification?.date &&
                                    Moment(signUnderVerification?.date).format('DD.MM.YYYY HH:mm')}
                            </div>
                        </Hint>
                    )}

                    <DevExpressDataGrid
                        dataSource={signsModel?.signs}
                        showColumnHeaders={true}
                        columnAutoWidth={true}
                        allowColumnReordering={false}
                        allowColumnResizing={true}
                        noDataText={'Нет подписей'}
                        rowAlternationEnabled={true}
                        onCellHoverChanged={onCellHoverChanged}
                    >
                        <Column
                            key={`col_author`}
                            allowFiltering={false}
                            caption={'Автор подписи'}
                            dataField={'signAuthor'}
                            dataType={'string'}
                            visible={true}
                            allowSorting={false}
                        />

                        <Column
                            key={`col_date`}
                            allowFiltering={false}
                            caption={'Дата подписи'}
                            dataField={'date'}
                            dataType={'date'}
                            format={'dd.MM.yyyy HH:mm'}
                            visible={true}
                            allowSorting={false}
                        />

                        <Column type={'buttons'}>
                            <GridButton
                                text="Проверить"
                                onClick={(e: any) => {
                                    setSignUnderVerification(e.row.data);
                                    verifyCheck(e.row.data.key);
                                }}
                            />
                            <GridButton
                                text="Скачать"
                                onClick={(e: any) => {
                                    service.downloadAttachSigns(props.attachId, e.row.data.key);
                                }}
                            />
                        </Column>
                    </DevExpressDataGrid>

                    <div className="modal-dlg-buttons">
                        <div className={'left'}>
                            <Button
                                size="s"
                                aria-label={props.cancelButtonText}
                                onClick={() => {
                                    if (props.completeHandler) props.completeHandler(false);
                                }}
                            >
                                {props.cancelButtonText}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};
