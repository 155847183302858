import React, { useMemo } from 'react';

import './ApproveListItems.scss';
import { IDocumentTable } from '@models/Forms/IForms';
import DevExpressTreeList from '../DevExpress/TreeList/DevExpressTreeList';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { Column } from 'devextreme-react/tree-list';
import { onCellHoverChanged } from '@utils/dataGridUtils';
import RowDetailsButton from '../TableData/RowDetailsButton';
import { IField } from '@models/IFormData';
import { useLocation } from 'react-router';
import ViewCellRenderSwitcher from '../TableData/ViewCellRenderSwitcher';
import { Scrolling } from 'devextreme-react/data-grid';

export interface IApproveListItemsProps {
    items: any[];
    table: IDocumentTable;
    cellRenderSwitcher: (p: any, column: any, rowParent?: any) => Promise<React.ReactNode>;
    getParentFields: () => IField[];
    docId?: string;
}

const ApproveListItems: React.FC<IApproveListItemsProps> = ({
    items,
    table,
    cellRenderSwitcher,
    getParentFields,
    docId,
}: IApproveListItemsProps) => {
    const location = useLocation();

    const dataSource = useMemo(() => {
        return new DataSource({
            store: new ArrayStore({
                key: '|Код_активити',
                data: items,
            }),
        });
    }, [items]);

    const renderViewInFormActColumn = (table: IDocumentTable, items: any[]) => {
        return (
            <Column
                key={`viewInForm`}
                width="auto"
                fixed={false}
                visibleIndex={0}
                encodeHtml={true}
                allowResizing={false}
                allowHiding={false}
                allowReordering={false}
                cssClass="dx-command-edit dx-command-edit-with-icons dx-cell-focus-disabled"
                cellRender={(p) => {
                    return (
                        <RowDetailsButton
                            getParentFields={getParentFields}
                            table={table}
                            rowData={p.data}
                            docId={docId}
                            rowArray={items}
                        />
                    );
                }}
            />
        );
    };

    return location.hash === '#page-tlcAcceptionViewNew' && items ? (
        <DevExpressTreeList
            id="apl"
            dataSource={dataSource}
            parentIdExpr="|Родитель"
            columnAutoWidth={false}
            keyExpr="|Код_активити"
            rootValue="-1"
            wordWrapEnabled={true}
            columnMinWidth={30}
            onCellHoverChanged={onCellHoverChanged}
        >
            <Scrolling mode="standard" />
            {table.viewInForm && renderViewInFormActColumn(table, items)}
            {table.tableColumn.map((column, index) => {
                return (
                    <Column
                        key={index}
                        dataField={column.key}
                        caption={column.name}
                        width={column.width}
                        visibleIndex={column.order}
                        encodeHtml={true}
                        cellRender={(p) => {
                            return (
                                <ViewCellRenderSwitcher
                                    data={p}
                                    column={column}
                                    cellRenderSwitcher={cellRenderSwitcher}
                                />
                            );
                        }}
                    />
                );
            })}
        </DevExpressTreeList>
    ) : (
        <></>
    );
};

export default ApproveListItems;
