import React, { FC, ReactNode } from 'react';
import './PageWrapper.scss';

export interface IPageWrapperProps {
    /** Элемент */
    children: ReactNode;
    /** Элемент кнопок */
    actions?: ReactNode;
    /** Заголовок */
    title?: string | ReactNode;
    /** Класс */
    className?: string;
}

const PageWrapper: FC<IPageWrapperProps> = ({
    children,
    title = undefined,
    actions = undefined,
    className = '',
    ...props
}: IPageWrapperProps) => {
    return (
        <div className={`pw-container ${className}`} {...props}>
            <div className={`pw-container-row pw-header`}>{title}</div>
            <div className="pw-container-row pw-action">{actions}</div>
            <div className=" pw-container-row pw-content">{children}</div>
        </div>
    );
};

export default PageWrapper;
