import React from 'react';
import './Preloader.scss';
import classNames from 'classnames';

export interface IPreloaderProps {
    className?: string;
    size?: 's' | 's2' | 'm' | 'l' | 'xl';
    variant?: 'default' | 'white' | 'inherit';
    position?: 'default' | 'inline' | 'static';
}

const Preloader: React.FC<IPreloaderProps> = ({
    className = '',
    size = 'm',
    variant = 'default',
    position = 'default',
}: IPreloaderProps) => (
    <div
        className={classNames(
            'preloader',
            `preloader--${size}`,
            `preloader--${variant}`,
            `preloader--position-${position}`,
            className,
        )}
    >
        <svg className="preloader__circle" viewBox="0 0 50 50">
            <circle className="preloader__path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
        </svg>
    </div>
);

export default Preloader;
