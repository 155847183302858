import React, { FC, ReactNode } from 'react';
import ToolTipTitle from '@atoms/ToolTipTitle/ToolTipTitle';
import './FieldWrapper.scss';

export interface IFieldWrapper {
    label?: ReactNode;
    inLineFormat: boolean;
    children: ReactNode;
    title?: string;
    wrapNewLines?: boolean;
}

const FieldWrapper: FC<IFieldWrapper> = ({ label, inLineFormat, children, title, wrapNewLines }: IFieldWrapper) => {
    const className = inLineFormat ? ' field-wrapper-row' : ' field-wrapper-column';
    const classNameField = inLineFormat ? ' field-wrapper-field-row' : ' field-wrapper-field-column';
    const classNameWrapNewLines = wrapNewLines ? 'field-wrapper-field-multiline' : '';
    return (
        <div className={`field-wrapper ${className}`}>
            {label && (
                <div className="field-wrapper-label">
                    {label}
                    <ToolTipTitle title={title} />
                </div>
            )}
            <div className={`field-wrapper-field ${classNameField} ${classNameWrapNewLines}`}>{children}</div>
        </div>
    );
};

export default FieldWrapper;
