import React, { useEffect, useRef } from 'react';

export const useCombinedRefs = (
    ...refs: Array<React.Ref<HTMLLabelElement> | React.MutableRefObject<null> | undefined>
): React.RefObject<HTMLInputElement | null> => {
    const targetRef = useRef(null);

    useEffect(() => {
        refs.forEach((ref: React.Ref<HTMLLabelElement> | React.MutableRefObject<null> | undefined) => {
            if (!ref) return;

            if (typeof ref === 'function') {
                ref(targetRef.current);
            }
            //else {
            //    ref.current = targetRef.current;
            //}
        });
    }, [refs]);

    return targetRef;
};
