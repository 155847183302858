import './ColumnChooser.scss';
import React, { FC, RefObject, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Popup, { Position, ToolbarItem } from 'devextreme-react/popup';
import List from 'devextreme-react/list';
import DataGrid from 'devextreme-react/data-grid';
import TreeList from 'devextreme-react/tree-list';

type ColumnsOwner = DataGrid | TreeList;

export interface IColumnChooser {
    open: (columnsOwnerRef: RefObject<ColumnsOwner | null>) => void;
}

interface ColumnChooserProps {
    columnChooserRef?: React.Ref<IColumnChooser>;
    sortOrder?: 'asc' | 'desc';
    selectedOnTop?: boolean; // разместить выбранные элементы выше остальных без сортировки
    applyButton?: boolean;
}

interface Column {
    name: string;
    selected: boolean;
}

const ColumnChooser: FC<ColumnChooserProps> = (p) => {
    const [columnsOwnerRef, setColumnsOwnerRef] = useState<RefObject<ColumnsOwner | null> | null>(null);
    const [visible, setVisible] = useState<boolean>(false);
    const [columns, setColumns] = useState<Column[]>([]);
    const [selectionTimeoutId, setSelectionTimeoutId] = useState<any>();
    const [lastSelectionEvent, setLastSelectionEvent] = useState<any>();

    const listRef = useRef<List>(null);

    useImperativeHandle(p.columnChooserRef, () => ({
        open: open,
    }));

    const open = useCallback((columnsOwnerRef: RefObject<ColumnsOwner | null>) => {
        setColumnsOwnerRef(columnsOwnerRef);
        setVisible(true);
    }, []);

    const close = useCallback(() => {
        setVisible(false);
        setColumnsOwnerRef(null);
    }, []);

    useEffect(() => {
        let columns = getColumns(columnsOwnerRef);
        columns = sortColumns(columns);
        setColumns(columns);
    }, [columnsOwnerRef]);

    useEffect(() => {
        columns.forEach((column) => {
            if (column.selected) {
                listRef.current?.instance.selectItem(column);
            }
        });
    }, [columns]);

    const getColumns = useCallback((columnsOwnerRef: RefObject<ColumnsOwner | null> | null): Column[] => {
        const columnsOwner = columnsOwnerRef?.current?.instance;
        if (columnsOwner) {
            const columns = [];
            for (let i = 0; i < columnsOwner.columnCount(); i++) {
                const column = columnsOwner.columnOption(i);
                if (column.showInColumnChooser) {
                    columns.push(column);
                }
            }

            // сортировка в порядке отображения столбцов в таблице
            columns.sort((a, b) => a.visibleIndex - b.visibleIndex);

            return columns.map((c) => ({
                name: c.caption,
                selected: c.visible,
            }));
        }
        return [];
    }, []);

    const sortColumns = useCallback((columns: Column[]): Column[] => {
        const sortFactor = p.sortOrder === 'asc' ? 1 : p.sortOrder === 'desc' ? -1 : null;
        columns.sort((a, b) => {
            if (p.selectedOnTop) {
                if (a.selected || b.selected) {
                    return Number(b.selected) - Number(a.selected);
                }
            }
            if (sortFactor) {
                return sortFactor * a.name.localeCompare(b.name, 'ru', { sensitivity: 'base' });
            }
            return 0;
        });
        return columns;
    }, []);

    const onSelectionChanged = useCallback(
        (event: any) => {
            if (p.applyButton) return;

            // При двойном клике на чекбоксы, если при этом все столбцы становятся невидимыми, то возникает ошибка:
            // "E4021 - The server response does not provide the totalCount value".
            // При этом запросы на сервер таблицей не отправляются.
            // Такая же ошибка возникает при двойном клике на чекбоксе "Выбрать все" родного ColumnChooser.
            // Похоже на внутреннюю ошибку таблицы.
            // Решение:
            // При помощи таймера откладываем применение выбора чекбокса.
            // При повторном клике на тот-же чекбокс до истечения таймера отменяем предыдущий таймер.

            if (lastSelectionEvent) {
                // event не позволяет идентифицировать чекбокс.
                // Определяем его через добавленные и удаленные элементы.
                for (let i = 0; i < event.removedItems.length; i++) {
                    const column = event.removedItems[i];
                    if (lastSelectionEvent.addedItems.find((a: Column) => a.name === column.name)) {
                        clearTimeout(selectionTimeoutId);
                        break;
                    }
                }
            }

            setLastSelectionEvent(event);

            const id = setTimeout(() => {
                event.addedItems.forEach((column: Column) => {
                    setColumnVisibility(columnsOwnerRef, column.name, true);
                });
                event.removedItems.forEach((column: Column) => {
                    setColumnVisibility(columnsOwnerRef, column.name, false);
                });
                if (event === lastSelectionEvent) {
                    setLastSelectionEvent(null);
                }
            }, 100);
            setSelectionTimeoutId(id);
        },
        [columnsOwnerRef, selectionTimeoutId, lastSelectionEvent],
    );

    const setColumnVisibility = useCallback(
        (columnsOwnerRef: RefObject<ColumnsOwner | null> | null, columnName: string, visible: boolean) => {
            const columnsOwner = columnsOwnerRef?.current?.instance;
            if (columnsOwner) {
                if (columnsOwner.columnOption(columnName, 'visible') != visible) {
                    columnsOwner.columnOption(columnName, 'visible', visible);
                }
            }
        },
        [],
    );

    const apply = useCallback(() => {
        const selectedItems = listRef.current?.instance.option('selectedItems') ?? [];
        columns.forEach((column) => {
            const selectedOld = column.selected;
            const selectedNew = selectedItems.includes(column);
            if (selectedOld !== selectedNew) {
                setColumnVisibility(columnsOwnerRef, column.name, selectedNew);
            }
        });
        close();
    }, [columnsOwnerRef, columns]);

    return (
        <Popup
            title="Выбор столбцов"
            visible={visible}
            maxHeight={600}
            width={250}
            showCloseButton={true}
            shading={true}
            resizeEnabled={true}
            dragEnabled={true}
            onHidden={close}
        >
            <Position my="right" at="right" of={columnsOwnerRef?.current?.instance.element()} />
            <List
                className="column-chooser__column-list"
                ref={listRef}
                dataSource={columns}
                displayExpr="name"
                searchExpr="name"
                selectionMode="all"
                pageLoadMode="scrollBottom"
                searchEnabled={true}
                showSelectionControls={true}
                onSelectionChanged={onSelectionChanged}
            />
            {p.applyButton && (
                <ToolbarItem
                    widget="dxButton"
                    location="after"
                    toolbar="bottom"
                    options={{
                        text: 'Выбрать',
                        onClick: apply,
                    }}
                />
            )}
            {p.applyButton && (
                <ToolbarItem
                    widget="dxButton"
                    location="after"
                    toolbar="bottom"
                    options={{
                        text: 'Закрыть',
                        onClick: close,
                    }}
                />
            )}
        </Popup>
    );
};

export default ColumnChooser;
