import React, { JSX, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import './TableData.scss';
import { IDocumentTable } from '@models/Forms/IForms';
import DataGrid, {
    Column,
    ColumnChooser,
    Export,
    FilterRow,
    MasterDetail,
    Pager,
    Paging,
    Scrolling,
    Selection,
    Sorting,
} from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import DevExpressDataGrid from '../DevExpress/DataGrid/DevExpressDataGrid';
import { Template } from 'devextreme-react/core/template';
import { Toolbar } from 'devextreme-react/toolbar';
import RowDetailsButton from './RowDetailsButton';
import { IField } from '@models/IFormData';
import { IActionService } from '@services/actions/IActionService';
import { IBaseAction } from '@models/actions/IBaseAction';
import { useParams } from 'react-router';
import classNames from 'classnames';
import { getColumnDataTypeByFieldDataType, onCellHoverChanged } from '@utils/dataGridUtils';
import ViewCellRenderSwitcher from './ViewCellRenderSwitcher';
import ActionButton from '@atoms/DevExpress/Buttons/ActionButton/ActionButton';
import OpenDocButton from './OpenDocButton';
import Tooltip from '@atoms/Tooltip';
import { MdHelpOutline } from 'react-icons/md';
import { exportGridToExcel } from '@utils/devextreme-react/dataGridUtils';
import { CellPreparedEvent } from 'devextreme/ui/data_grid';
import { ValueType } from '@/types/ValueType';
import { ConfigService } from '@/configuration/services/configService';
import { TabsContext } from '@/context/Contexts';

export interface ITableViewProps {
    value?: any[];
    table: IDocumentTable;
    cellRenderSwitcher: (p: any, column: any, rowParent?: any) => Promise<React.ReactNode>;
    visibilityColumn: (rules: string, rowParent?: any) => Promise<boolean>;
    getParentFields: () => IField[];
    actionService?: IActionService<IBaseAction>;
    onClickAction?: () => void;
    onRefreshExternalDS?: () => Promise<void>;
    docId?: string;
}

const TableView: React.FC<ITableViewProps> = ({
    value,
    table,
    cellRenderSwitcher,
    visibilityColumn,
    getParentFields,
    onClickAction,
    onRefreshExternalDS,
    actionService,
    docId,
}: ITableViewProps) => {
    const config = ConfigService.get();
    const scrollByContent = config.application._experimental_scrollByContentInTables ?? false;

    const activated = React.useRef(false);
    const { id } = useParams();
    const gridRef = useRef<DataGrid>(null);

    const [columns, setColumns] = useState<JSX.Element[]>();

    useEffect(() => {
        activated.current = true;
        InitColumns();
        return () => {
            activated.current = false;
        };
    }, []);

    // Принудительно обновляем размеры таблиц при смене вкладок
    const tabsContext = useContext(TabsContext);
    useEffect(() => {
        gridRef.current?.instance.updateDimensions();
    }, [tabsContext?.activeTab]);

    const renderDetailTable = useCallback(
        (param: any) => {
            return table.tables.map((table, i) => {
                let key = table.key;

                let val = param.data[key] ?? [];
                if (val.length === 0) return <></>;

                return (
                    <TableView
                        key={i}
                        table={table}
                        value={val}
                        getParentFields={getParentFields}
                        cellRenderSwitcher={cellRenderSwitcher}
                        visibilityColumn={async (rules: string, rowParent?: any) => {
                            return await visibilityColumn(rules, param.data);
                        }}
                        docId={docId}
                    />
                );
            });
        },
        [cellRenderSwitcher, docId, getParentFields, table, table.tables, visibilityColumn],
    );

    const renderMasterDetail = useCallback(() => {
        return <MasterDetail enabled={true} render={renderDetailTable} />;
    }, [renderDetailTable]);

    const renderViewInFormActColumn = useCallback(
        (table: IDocumentTable, rows: any[]) => {
            return (
                <Column
                    key={`viewInForm`}
                    width="36px"
                    fixed={false}
                    visibleIndex={0}
                    encodeHtml={true}
                    allowResizing={false}
                    allowHiding={false}
                    allowReordering={false}
                    cssClass="dx-command-edit dx-command-edit-with-icons dx-cell-focus-disabled"
                    cellRender={(p) => {
                        return (
                            <RowDetailsButton
                                getParentFields={getParentFields}
                                table={table}
                                rowData={p.data}
                                docId={docId}
                                rowArray={rows}
                            />
                        );
                    }}
                />
            );
        },
        [docId, getParentFields],
    );

    const renderViewActionColumn = useCallback((table: IDocumentTable) => {
        return (
            <Column
                key={`viewActions`}
                caption="Действия"
                visibleIndex={100}
                width="88px"
                allowResizing={false}
                allowHiding={false}
                alignment="center"
                allowReordering={false}
                cellTemplate="dxGridRowMenuCellTemplateAjax"
            />
        );
    }, []);

    const renderOpenDocActColumn = useCallback(
        (table: IDocumentTable) => {
            return (
                <Column
                    key={`openDoc`}
                    width="36px"
                    fixed={false}
                    visibleIndex={0}
                    encodeHtml={true}
                    allowResizing={false}
                    allowHiding={false}
                    allowReordering={false}
                    cssClass="dx-command-edit dx-command-edit-with-icons dx-cell-focus-disabled"
                    cellRender={(p) => {
                        return <OpenDocButton table={table} rowData={p.data} docId={docId} />;
                    }}
                />
            );
        },
        [docId],
    );

    const renderColumnGrid = useCallback(
        async (column: any, path: string, dataType: ValueType) => {
            return (
                <Column
                    key={`column${path}${column.key}`}
                    dataField={column.key}
                    caption={column.name}
                    dataType={getColumnDataTypeByFieldDataType(dataType)}
                    width={column.width}
                    minWidth={
                        // Если ширина в vw, то задаем мин ширину 5px
                        // У грида есть баг, он сравнивает width и minWidth только по цифре, без учета единиц
                        column.width ? (column.width.toString().endsWith('vw') ? 5 : undefined) : undefined
                    }
                    sortIndex={column.sortIndex}
                    sortOrder={column.sortOrder}
                    visible={!column.hidden}
                    visibleIndex={column.order}
                    encodeHtml={true}
                    alignment={column.alignment}
                    //TODO попросили убрать
                    headerCellRender={(p) => {
                        return (
                            <div className="title-column-box">
                                <div
                                    className={classNames(
                                        'title-column-caption',
                                        column.headerNoEllipsis && 'title-column-caption-noEllipsis',
                                    )}
                                >
                                    {p.column.caption}
                                </div>
                                {column.viewTitle && (
                                    <div className="title-column-title">
                                        <Tooltip openDelay={100} background="black" position="bottom">
                                            <MdHelpOutline size="16" />
                                            {column.viewTitle}
                                        </Tooltip>
                                    </div>
                                )}
                            </div>
                        );
                    }}
                    cellRender={(p) => {
                        return (
                            <ViewCellRenderSwitcher data={p} column={column} cellRenderSwitcher={cellRenderSwitcher} />
                        );
                    }}
                ></Column>
            );
        },
        [cellRenderSwitcher],
    );

    const gridDataSource = useMemo(() => {
        return new DataSource({
            store: new ArrayStore({
                key: '|NUM',
                data: value!,
            }),
        });
    }, [value]);

    const checkVisRules = useCallback(
        async (column: any) => {
            return (
                column.visibilityRules === undefined ||
                column.visibilityRules === null ||
                (column.visibilityRules && (await visibilityColumn(column.visibilityRules)))
            );
        },
        [visibilityColumn],
    );

    const InitColumns = useCallback(
        async () => {
            let result: JSX.Element[] = [];
            let w = 50;
            for (let index = 0; index < table.tableColumn.length; index++) {
                const column = table.tableColumn[index];
                let vis = column.hidden || (await checkVisRules(column));
                if (vis) {
                    w = w + +column.width;
                    result.push(await renderColumnGrid(column, `table_grid_${table.key}`, column.valueType));
                }
            }
            for (let index = 0; index < table.tableColumnDict.length; index++) {
                const column = table.tableColumnDict[index];
                let vis = await checkVisRules(column);
                if (vis) {
                    w = w + +column.width;
                    result.push(await renderColumnGrid(column, `table_dictgrid_${table.key}`, ValueType.Text));
                }
            }
            for (let index = 0; index < table.tableColumnAbook.length; index++) {
                const column = table.tableColumnAbook[index];
                let vis = await checkVisRules(column);
                if (vis) {
                    w = w + +column.width;
                    result.push(await renderColumnGrid(column, `table_abookgrid_${table.key}`, ValueType.Text));
                }
            }
            for (let index = 0; index < table.tableColumnCalc.length; index++) {
                const column = table.tableColumnCalc[index];
                let vis = await checkVisRules(column);
                if (vis) {
                    result.push(await renderColumnGrid(column, `table_calcgrid_${table.key}`, ValueType.Double));
                }
            }
            for (let index = 0; index < table.tableColumnAutoComplete.length; index++) {
                const column = table.tableColumnAutoComplete[index];
                let vis = await checkVisRules(column);
                if (vis) {
                    result.push(await renderColumnGrid(column, `table_autocompletegrid_${table.key}`, ValueType.Text));
                }
            }
            for (let index = 0; index < table.tableColumnExDataSource.length; index++) {
                const column = table.tableColumnExDataSource[index];
                let vis = await checkVisRules(column);
                if (vis) {
                    w = w + +column.width;
                    result.push(await renderColumnGrid(column, `table_calcgrid_${table.key}`, column.valueType));
                }
            }

            if (activated.current) {
                setColumns(result);
            }
            return null;
        },
        // prettier-ignore
        [
        checkVisRules, renderColumnGrid, table.key, table.tableColumn, table.tableColumnAbook, table.tableColumnAutoComplete,
        table.tableColumnCalc, table.tableColumnDict, table.tableColumnExDataSource,
    ],
    );

    const onExporting = useCallback(() => {
        exportGridToExcel(gridRef, table.name || 'Экспорт таблицы');
    }, [table.name]);

    const onCellPrepared = useCallback(
        (e: CellPreparedEvent<any, any>) => {
            // Задача EUPDEV-8613
            // Компонент MasterDetail не умеет скрывать себя для отдельных строк master-таблицы, когда detail-часть пустая
            // Скрываем обходным путем

            // Находим ячейку, содержащую стрелку раскрытия detail-части
            if (e.column.type === 'detailExpand' && e.rowType === 'data') {
                // Определяем пустая detail-часть или нет
                const detailIsEmpty = table.tables.every((t) => {
                    let items = e.data[t.key];
                    return !items || items.length === 0;
                });
                if (detailIsEmpty) {
                    e.cellElement.firstChild?.remove(); // удаляем элемент со стрелкой из ячейки
                    e.cellElement.style.pointerEvents = 'none'; // запрещаем ячейке реагировать на нажатия
                }
            }
        },
        [table.tables],
    );

    return columns ? (
        <div className="form-table" data-testid={table.id ? `table-view-${table.id}` : undefined}>
            <DevExpressDataGrid
                ref={gridRef}
                columnMinWidth={30}
                allowColumnResizing={true}
                wordWrapEnabled={table.wordWrapEnabled ?? true}
                columnResizingMode="widget"
                dataSource={gridDataSource}
                onCellHoverChanged={onCellHoverChanged}
                onExporting={onExporting}
                onCellPrepared={onCellPrepared}
            >
                <Scrolling useNative={!scrollByContent} scrollByContent={scrollByContent} />
                <ColumnChooser enabled={false} />
                <Sorting mode="multiple" />
                <Toolbar visible={false} />
                <Pager showInfo={true} visible={'auto'} showNavigationButtons={true} displayMode={'full'} />
                <Paging defaultPageSize={table.pageSize && table.pageSize > 0 ? table.pageSize : 20} />
                <FilterRow showOperationChooser={true} visible={table.allowFiltersRow} />
                {table.viewInForm && renderViewInFormActColumn(table, value!)}
                {table.previewDocByKey && renderOpenDocActColumn(table)}
                {columns}
                {table.tableColumnAction && table.tableColumnAction.linkDocumentAction && renderViewActionColumn(table)}
                {table.tables?.length > 0 && renderMasterDetail()}

                <Export
                    enabled={table.allowExport}
                    allowExportSelectedData={table.allowExportSelectedRows}
                    formats={['xlsx']}
                    texts={{
                        exportAll: table.exportAllText || undefined,
                        exportSelectedRows: table.exportSelectedRowsText || undefined,
                        exportTo: table.exportToText || undefined,
                    }}
                />
                {table.allowExport && table.allowExportSelectedRows ? (
                    <Selection
                        allowSelectAll={true}
                        mode={'multiple'}
                        selectAllMode={'allPages'}
                        showCheckBoxesMode={'always'}
                    />
                ) : null}

                <Template
                    name="dxGridRowMenuCellTemplateAjax"
                    render={function (data: any) {
                        let key = id + '/' + data.data[table.tableColumnAction.linkDocumentAction.linkKey];

                        return (
                            <ActionButton
                                objId={key}
                                service={actionService}
                                objData={data.data}
                                onActionClick={() => {
                                    onClickAction && onClickAction();
                                }}
                                onModifyData={() => {
                                    onRefreshExternalDS && onRefreshExternalDS();
                                    data.component.refresh();
                                }}
                            />
                        );
                    }}
                />
            </DevExpressDataGrid>
        </div>
    ) : (
        <></>
    );
};

export default TableView;
